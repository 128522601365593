import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS
} from 'react-admin';
import
    serverConfig
from '../custom/serverConfig';
import Cookies from "js-cookie";
import {getActiveHospitalId, setActiveHospitalId} from "../hospitals/ActiveHospital";

export const authorization = () => (Cookies.get('token') ? 'Bearer ' + Cookies.get('token') : null);

export default (type, params) => {
    // called when the user attempts to log in
    if (type === AUTH_LOGIN) {
        const {username, password} = params;

        const request = new Request(serverConfig.endpoint + '/rest/v1/auth/login', {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(({token}) => {
                Cookies.set('token', token, {expires: 30});
                const whoRequest = new Request(serverConfig.endpoint + '/rest/v1/auth/whoami', {
                    method: 'GET',
                    headers: new Headers(
                        {
                            'Content-Type': 'application/json',
                            'Authorization': authorization()
                        }
                    )
                });
                return fetch(whoRequest)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(({user}) => {
                        localStorage.setItem('isAdmin', 'false');
                        localStorage.setItem('isSysAdmin', 'false');
                        localStorage.setItem('isClinician', 'false');
                        localStorage.setItem('termsAck', user.termsAckDate);
                        localStorage.setItem('pwChangeDate', user.pwChangeDate);
                        localStorage.setItem('username', user.username);

                        //Default to first tenant in their list of roles
                        setActiveHospitalId(user.roles[0].tenantId);

                        user.roles.forEach((role) => {
                            if (role.tenantId.toString() === getActiveHospitalId()) {
                                if (role.role === "TENANT_ADMIN") {
                                    localStorage.setItem("isAdmin", "true");
                                }
                                if (role.role === "SYSADMIN") {
                                    localStorage.setItem("isSysAdmin", "true");
                                }
                                if (role.role === "CLINICIAN") {
                                    localStorage.setItem("isClinician", "true");
                                }
                            }
                        });

                        // If they more than one tenant role then make them go to hospitals and select the one they want to activate
                        // If they have one tenant role but do not have patient permissions then send them to hospitals as well
                        if (user.roles.length > 1 || (localStorage.getItem("isSysAdmin") === "false" && localStorage.getItem("isClinician") === "false")) {
                            localStorage.setItem('mustSelectActiveTenant', 'true');
                        }

                        return Promise.resolve();
                    });
            })
    }

    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        if (authorization() == null) {
            Cookies.remove('token');
            localStorage.clear();
            sessionStorage.clear();
            return Promise.resolve();
        } else {
            const logoutRequest = new Request(serverConfig.endpoint + '/rest/v1/auth/logout', {
                method: 'GET',
                headers: new Headers(
                    {
                        'Content-Type': 'application/json',
                        'Authorization': authorization()
                    }
                )
            });
            return fetch(logoutRequest)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    Cookies.remove('token');
                    localStorage.clear();
                    sessionStorage.clear();
                    return Promise.resolve();
                })
        }
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const {status} = params;
        if (status === 401 || status === 403) {
            Cookies.remove('token');
            localStorage.clear();
            sessionStorage.clear();
            return Promise.reject();
        }
        return Promise.resolve();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const roles = localStorage.getItem('roles');
        return roles ? Promise.resolve(roles) : Promise.reject();
    }
    // called when the user navigates to a new location
    if (type === AUTH_CHECK) {
        if (window.location.href.indexOf('resetPassword?token') > 0) {
            return Promise.resolve();
        }
        return Cookies.get('token') ? Promise.resolve() : Promise.reject();
    }
    return Promise.reject('Unknown method');
};