export const curSortByVal = () => localStorage.getItem('monitorSortBy') || 'patchStatus';
export const curSortDirVal = () => localStorage.getItem('monitorSortDir') || 'DESC';
export const curTempSetting = () =>localStorage.getItem('temp') || 'F';
export const curDisplayAlertSetting = () => localStorage.getItem('displayAlertTemps') || false;
export const curDisplayNRDTimer = () => localStorage.getItem('displayNRDTimer') || false;

export const setSortByVal = (val) => localStorage.setItem('monitorSortBy', val);
export const setSortDirVal = (val) => localStorage.setItem('monitorSortDir', val);
export const setTempSetting = (val) =>localStorage.setItem('temp', val);
export const setDisplayAlertSetting = (val) => val ? localStorage.setItem('displayAlertTemps', true) : localStorage.removeItem('displayAlertTemps');
export const setDisplayNRDTimer = (val) => val ? localStorage.setItem('displayNRDTimer', true) : localStorage.removeItem('displayNRDTimer');
