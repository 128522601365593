import {getActiveHospitalId} from './ActiveHospital';
import {CHANGE_CONFIG, CHANGE_HOSPITAL} from './hospitalActions';

//TODO changing from hosptial to hospital code here
export const hospital =(previousState = getActiveHospitalId(), { type, payload }) => {
  if (type === CHANGE_HOSPITAL) {
    return payload || {};
  }
  return previousState || {};
};

export const config =(previousState = localStorage.getItem('config') && localStorage.getItem('config') !== 'undefined' && JSON.parse(localStorage.getItem('config')) , { type, payload }) => {
  if (type === CHANGE_CONFIG) {
    return payload || {};
  }
  return previousState || {};
};
