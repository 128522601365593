import { connect } from 'react-redux';
import React from 'react';
import {
    BooleanInput,
    Create,
    Labeled,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRefresh,
    useRedirect,
    useTranslate,
    PasswordInput
} from 'react-admin';
import BackButton from '../ui/BackButton';
import { validatePassword } from "../util/validatePassword";
import SaveIcon from '@mui/icons-material/Save';

const EntityCreateToolbar = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();
    return (
        <Toolbar {...props}>
            <SaveButton
                variant="contained"
                id="userCreateSave"
                alwaysEnable
                submitonenter="true"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
                mutationOptions={{
                    onSuccess: (response) => {
                        refresh();
                        redirect('show', 'users', response.id);
                    }
                }}
                type="button"
            />
            <BackButton
                id="userCreateBack"
                goBack={() => redirect(`list`, 'users')}
            />
        </Toolbar>
    )
};

const UserCreate = (props) => {
    const { config, onCancel } = props;
    const translate = useTranslate();

    const validateUserCreation = (values) => {
        const errors = {};
        const passVal = values.password ? validatePassword('cpw', config, translate)(values.password) : null

        if (!values.username) {
            errors.username = 'ra.validation.required';
        };
        if (!values.password) {
            errors.password = 'ra.validation.required';
        } else if (passVal) {
            errors.password = passVal;
        };
        if ((!values.tenantadminids || !values.tenantadminids.length) && (!values.clinicianids || !values.clinicianids.length)) {
            errors.tenantadminids = 'user.create.role';
            errors.clinicianids = 'user.create.role';
        };
        return errors;
    };

    return (
        <Create sx={{ mt: { sm: '-1em' }, mb: { xs: '80px', sm: 1 } }} title="user.create.add" {...props}>
            <SimpleForm id="userCreateForm" validate={validateUserCreation} toolbar={<EntityCreateToolbar onCancel={onCancel} />}  >
                <TextInput id="fname" source="fName" label="user.create.fname" />
                <br />
                <TextInput id="lname" source="lName" label="user.create.lname" />
                <br />
                <TextInput id="username" source="username" validate={required()} label="ra.auth.username" />
                <br />
                <PasswordInput
                    id="password"
                    validate={required()}
                    source="password"
                    label="ra.auth.password"
                    sx={{ maxWidth: '195px' }}
                />
                <br />
                <TextInput id="email" source="email" label="user.create.email" />
                <br />
                <Labeled id="adminLabel" label="user.create.admin">
                    <ReferenceArrayInput
                        perPage={0}
                        sort={{ field: 'shortname', order: 'ASC' }}
                        source="tenantadminids"
                        reference="tenantassignments"
                    >
                        <SelectArrayInput id="TENANT_ADMIN" source="tenantadminids" optionText="shortName" label={false} />
                    </ReferenceArrayInput>
                </Labeled>
                <br />
                <Labeled id="clinicianLabel" label="user.create.clinician">
                    <ReferenceArrayInput
                        perPage={0}
                        sort={{ field: 'shortname', order: 'ASC' }}
                        source="clinicianids"
                        reference="tenantassignments"
                    >
                        <SelectArrayInput id="CLINICIAN" source="clinicianids" optionText="shortName" label={false} />
                    </ReferenceArrayInput>
                </Labeled>
                <br />
                <BooleanInput id="active" label="user.create.active" source="active" defaultValue />
            </SimpleForm>
        </Create>
    );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(UserCreate);
