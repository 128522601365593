import { Tune } from "@mui/icons-material";
import DoubleArrow from "@mui/icons-material/DoubleArrow";
import { IconButton, MenuItem, Select, Stack, SwipeableDrawer } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import React, { useState } from 'react';
import {
    BooleanInput,
    CreateButton,
    // FilterButton,
    FilterForm, ListBase,
    TextInput,
    Title,
    useRedirect,
    useRefresh,
    useTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import { changeSort, localSearch } from '../custom/settings/action';
import { curSortByVal, curSortDirVal, setSortByVal, setSortDirVal } from '../custom/settings/displayOptions';
import Responsive from "../ui/Responsive";
import GridList from './GridList';

const SortOptions = () => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const [sort, setSort] = useState(curSortByVal());
    const [dir, setDir] = useState(curSortDirVal());

    return (
        <div key="sortToolbar">
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            >
                <FormControl
                    key="sortBy"
                    sx={{ minWidth: 120 }}
                >
                    <Select
                        id="sortBy_select"
                        value={sort}
                        displayEmpty
                        onChange={(e) => {
                            const val = e.target.value === 'patchStatus' ? 'DESC' : 'ASC';
                            setDir(val);
                            setSort(e.target.value);
                            setSortByVal(e.target.value);
                            setSortDirVal(val);
                            changeSort(val);
                            refresh();
                        }}
                    >
                        <MenuItem id="sortBy_patientid" value="patientCode">
                            {translate('resources.patient.patientidlabel')}
                        </MenuItem>
                        <MenuItem id="sortBy_patientid2" value="patientName">
                            {translate('resources.patient.patientid2label')}
                        </MenuItem>
                        <MenuItem id="sortBy_temp" value="patchStatus">
                            {translate('resources.patient.temperature')}
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    key="sortOrder"
                    sx={{ minWidth: 120 }}
                    id="patientListForm"
                >
                    <Select
                        id="sortBy_dir"
                        value={dir}
                        displayEmpty
                        onChange={(e) => {
                            setDir(e.target.value);
                            setSortDirVal(e.target.value);
                            changeSort(curSortByVal(), e.target.value);
                            refresh();
                        }}
                    >
                        <MenuItem id="sortBy_asc" value="ASC">
                            {translate('resources.misc.ascending')}
                        </MenuItem>
                        <MenuItem id="sortBy_desc" value="DESC">
                            {translate('resources.misc.descending')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Stack>
        </div>
    );
}

const ListButtons = (props) => {
    const translate = useTranslate();
    const [config] = useState(props.config);
    const redirect = useRedirect();
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
        >
            {(localStorage.getItem('isSysAdmin') === 'true' || config?.parameters?.enablePatientTransfer) &&
                <Responsive
                    xsmall={
                        <Button
                            id="transferKit"
                            sx={{
                                position: 'fixed',
                                zIndex: 9999,
                                right: '20px',
                                bottom: '130px',
                                borderRadius: '50%',
                                height: '56px',
                                width: '56px',
                                minWidth: '36px',
                                p: 0,
                                m: 0
                            }}
                            variant="contained"
                            onClick={() => redirect('/patientTransfer')}
                        >
                            <DoubleArrow id="transferKit" />
                        </Button>
                    }
                    medium={
                        <Button
                            id="transferKit"
                            sx={{ whiteSpace: 'nowrap' }}
                            variant="contained"
                            onClick={() => redirect('/patientTransfer')}
                        >
                            {translate('resources.patient.transferkit')}
                            <DoubleArrow id="transferKit" />
                        </Button>
                    }
                />
            }
            <CreateButton
                id="add_patient_button"
                sx={{ whiteSpace: 'nowrap' }}
                variant="contained"
                label={'resources.patient.addpatient'}
            />
        </Stack>
    )
}

const patientFilters = [
    <TextInput
        key="searchText"
        label="ra.action.search"
        source="q"
        resettable
        alwaysOn
    />,
    <BooleanInput
        alwaysOn
        label="resources.patient.activepatientsonly"
        source="ACTIVEONLY"
    />
];

const ListToolbar = (props) => {
    const [open, setOpen] = useState(false);

    return (
        <Stack
            sx={{
                backgroundColor: 'white',
                borderRadius: '4px',
                p: 1
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
        >
            <div style={{ maxWidth: '420px' }}>
                <FilterForm sx={{ mt: -2 }} filters={patientFilters} />
            </div>
            {/* <FilterButton filters={patientFilters} /> */}
            <Responsive
                xsmall={
                    <>
                        <IconButton onClick={() => setOpen(!open)} sx={{ height: '48px', width: '48px' }}>
                            <Tune />
                        </IconButton>

                        <SwipeableDrawer
                            anchor="top"
                            PaperProps={{ id: "filters" }}
                            open={open}
                            onOpen={() => setOpen(!open)}
                            onClose={() => setOpen(!open)}
                            disableBackdropTransition
                            disableSwipeToOpen
                            disableDiscovery
                        >
                            <div style={{ margin: '20px' }}>
                                <SortOptions key="filterSort" alwaysOn />
                            </div>
                        </SwipeableDrawer>
                        <ListButtons {...props} />
                        <Title title="resources.patient.patientlist" />
                    </>
                }
                small={
                    <Stack
                        key="sortToolbar"
                        direction="row"
                        justifyContent="space-between"
                        gap={1}
                        flexWrap="wrap"
                        alignItems="center"
                    >
                        <SortOptions key="filterSort" alwaysOn />
                        <ListButtons {...props} />
                        <Title title="resources.patient.patientlist" />
                    </Stack>

                }
            />
        </Stack>
    )
};

const PatientSummaryGrid = (props) => (
    <ListBase filterDefaultValues={{ ACTIVEONLY: false }}>
        <ListToolbar {...props} />
        <GridList />
    </ListBase>
);

const mapStateToProps = state => ({ monitorSortBy: state.monitorSortBy, hospital: state.hospital, config: state.config, searchValues: state.localSearch });
export default connect(mapStateToProps, { changeSort, localSearch })(PatientSummaryGrid);