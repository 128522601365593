import BusinessIcon from '@mui/icons-material/Business';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ComputerIcon from '@mui/icons-material/Computer';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import ListIcon from '@mui/icons-material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { AUTH_LOGOUT, MenuItemLink, Notification, useRedirect, useSidebarState, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import AlertDrawer from '../alerts/WebAlerts';
import authProvider, { authorization } from '../dataProvider/authProvider';
import { Context } from '../dataProvider/context';
import customDataProvider from "../dataProvider/customDataProvider";
import {
    getActiveHospitalId, setActiveGroupCode, setActiveHospitalHierarchy,
    setActiveHospitalId,
    setActiveHospitalShortName
} from '../hospitals/ActiveHospital';
import { changeConfig, changeHospital } from '../hospitals/hospitalActions';
import ClinicianAppBar from './ClinicianAppBar';
import Sidebar from './Sidebar';

const styles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            minHeight: '100vh',
            position: 'relative',
            backgroundColor: "#182F46"
        },
        appFrame: {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto',
        },
        contentWithSidebar: {
            display: 'flex',
            flexGrow: 1,
            color: "#000",
            marginTop: '15px'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            padding: '0px',
            margin: '8px',
            marginBottom: '0px',
            zIndex: "10",
            height: 'fit-contents',
            overflowX: 'clip'
        },
        menuItem: {
            color: 'white'
        },
        menuItemSub: {
            color: 'white',
            paddingLeft: '36px'
        },
        svgIcon: {
            color: 'white',
            width: '24px',
            height: '24px'
        },
        svgIconClosed: {
            color: '#182F46',
            width: '24px',
            height: '24px'
        },
        header: {
            backgroundColor: '#182F46',
        },
        actions: {
            backgroundColor: '#FFFFFF',
        },
        MuiFormControl: {
            marginDense: {
                marginTop: "16px",
                marginBottom: "8px"
            },
            defaultProps: {
                variant: 'outlined',
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
            },
        },
    })
);

const networkTimeout = 10 * 60 * 1000; //10 min in ms

const MyLayout = (props) => {
    const { context } = React.useContext(Context);
    const [show, setShow] = useState(false);
    const timerRef = useRef();
    const { children, logout, title } = props;
    const classes = styles();
    const translate = useTranslate();
    const [isLoading, setIsLoading] = useState(false);
    const redirect = useRedirect();
    const [hospital, setHospital] = useState(null);
    const [config, setConfig] = useState(null);
    const termsAck = localStorage.getItem('termsAck');
    const pwChangeDate = localStorage.getItem('pwChangeDate');
    const isAdmin = localStorage.getItem('isAdmin');
    const isSysadmin = localStorage.getItem('isSysAdmin');
    const isClinician = localStorage.getItem('isClinician');
    const [notify, changeNotify] = useState([]);
    const [sidebarOpen] = useSidebarState();
    const resetPwd = 'resetPassword?';
    const noUI = !window.location.href.match(/(acknowledgement)|(changepassword)|(resetPassword\?)/i);
    const [alertOpen, setAlertOpen] = useState(JSON.parse(sessionStorage.getItem('alertOpen'), (key, value) =>
        typeof value !== 'boolean'
            ? false
            : value
    ));

    useEffect(() => {
        setShow(false);
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => setShow(true), networkTimeout);
        return () => { clearTimeout(timerRef.current); };
    }, [context]);

    let isRedirect = (config?.parameters?.enableTerms && config?.strings?.termsContent && (!termsAck || termsAck === '0')) || (config?.parameters?.requireFirstLoginPasswordChange && (!pwChangeDate || pwChangeDate === '0')) ? true : false;
    useEffect(() => {
        setIsLoading(true);

        if (window.location.href.endsWith("/login")) {
            redirect(undefined);
            return;
        }

        if (window.location && window.location.search) {
            const params = new URLSearchParams(window.location.search);
            if (params.get('hospitalCode') != null) {
                setActiveHospitalId(params.get('hospitalCode'));
                window.location.replace('/');
                return;
            }
        }

        //Redirect if user has roles with multiple hospitals
        if (localStorage.getItem('mustSelectActiveTenant') === 'true') {
            localStorage.setItem('mustSelectActiveTenant', 'false');
            redirect('/HospitalSummary');
        }

        if (window.location.href.indexOf(resetPwd) < 0) {
            customDataProvider.getHospitalSummary(getActiveHospitalId())
                .then(hospital => {
                    const { changeHospital } = props;
                    delete hospital._acl;
                    delete hospital._kmd;
                    setHospital(hospital.data);
                    changeHospital(hospital.data);
                    setActiveHospitalShortName(hospital.data.shortName);
                    setActiveHospitalHierarchy(hospital.fullHierarchy);
                    setActiveGroupCode(hospital.data.groupCode);

                    if (!localStorage.getItem("highTempThreshold")) {
                        localStorage.setItem("adjustForOral", hospital.data.adjustForOral);
                        localStorage.setItem("highTempThreshold", hospital.data.highTempThreshold);
                        localStorage.setItem("lowTempThreshold", hospital.data.lowTempThreshold);
                    }

                    return hospital;
                })
                .then(hospital => {
                    return customDataProvider.getHospitalConfig(hospital.data.id);
                })
                .then(config => {
                    const { changeConfig } = props;
                    setConfig(config.data);
                    changeConfig(config.data);
                    localStorage.setItem('config', JSON.stringify(config.data));

                    if (config.data?.parameters?.enableTerms && config.data?.strings?.termsContent && (!termsAck || termsAck === '0')) { // userInfo.ack
                        redirect('/acknowledgement');
                    }
                    if (config.data?.parameters?.requireFirstLoginPasswordChange && (!pwChangeDate || pwChangeDate === '0')) {
                        redirect('/changePassword');
                    }

                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                    authProvider(AUTH_LOGOUT);
                    redirect('/login');
                });
        } else {
            setIsLoading(false);
        }
        return () => {
            setIsLoading(false);
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (authorization() === null && window.location.href.indexOf(resetPwd) < 0) {
            redirect('/login');
            return;
        };
    }, [window.location.href]); // eslint-disable-line

    // if(redirect) {
    //     if(!window.location.href.endsWith(redirect)) {
    //         window.location = redirect;
    //     }
    // }
    if (isLoading) {
        return <span className={classes.root} />;
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    {!isRedirect && window.location.href.indexOf(resetPwd) < 0 && noUI &&
                        <ClinicianAppBar id="mylayout_appbar" hospital={hospital} title={title} logout={logout} alerts={{ notify, setAlertOpen }} />
                    }
                    <main className={classes.contentWithSidebar} >
                        {!isRedirect && window.location.href.indexOf(resetPwd) < 0 && noUI &&
                            <Sidebar
                                id="mylayout_sidebar"
                                sx={{
                                    "& .RaMenuItemLink-active:not(:hover)": {
                                        backgroundColor: "#29657a",
                                        color: "#FFFFFF"
                                    },
                                    "& .RaMenuItemLink-active:hover": {
                                        backgroundColor: "#29657a",
                                        color: "#FFFFFF"
                                    },
                                }}>
                                <div>
                                    <MenuItemLink
                                        to={{ pathname: "/HospitalSummary" }}
                                        id="mylayout_menu_tenant"
                                        primaryText="navigation.hospital"
                                        leftIcon={<BusinessIcon className={classes.svgIcon} id="hospitalList" />}
                                        className={classes.menuItem} />
                                    <MenuItemLink
                                        to={{ pathname: `/HospitalSummary/${hospital?.id}/show` }}
                                        id="mylayout_menu_tenant_name"
                                        primaryText={hospital?.shortName}
                                        leftIcon={<SubdirectoryArrowRightIcon className={classes.svgIcon} id="hospitalList" />}
                                        className={classes.menuItem} />
                                    {(isSysadmin === 'true' || isClinician === 'true') &&
                                        <MenuItemLink
                                            to={{ pathname: "/PatientSummary" }}
                                            id="mylayout_menu_patient"
                                            primaryText="navigation.patient"
                                            leftIcon={
                                                <IconButton disableRipple size="small" sx={{ ml: -0.6, backgroundColor: 'white' }}>
                                                    <PeopleIcon className={classes.svgIconClosed} id="patientList" />
                                                </IconButton>
                                            }
                                            className={sidebarOpen ? classes.menuItemSub : classes.menuItem} />
                                    }
                                    {(isSysadmin === 'true' || isAdmin === 'true') &&
                                        <MenuItemLink
                                            to={{ pathname: "/gateways" }}
                                            id="mylayout_menu_gateway"
                                            primaryText="navigation.gateway"
                                            leftIcon={
                                                <IconButton disableRipple size="small" sx={{ ml: -0.6, backgroundColor: 'white' }}>
                                                    <ComputerIcon className={classes.svgIconClosed} id="gatewayList" />
                                                </IconButton>
                                            }
                                            className={sidebarOpen ? classes.menuItemSub : classes.menuItem} />
                                    }
                                    {(isSysadmin === 'true' || isAdmin === 'true') &&
                                        <MenuItemLink
                                            to={{ pathname: "/users" }}
                                            id="mylayout_menu_user"
                                            primaryText="navigation.user"
                                            leftIcon={
                                                <IconButton disableRipple size="small" sx={{ ml: -0.6, backgroundColor: 'white' }}>
                                                    <ManageAccountsIcon className={classes.svgIconClosed} />
                                                </IconButton>
                                            }
                                            className={sidebarOpen ? classes.menuItemSub : classes.menuItem} />
                                    }
                                    {isSysadmin === 'true' &&
                                        <MenuItemLink
                                            to={{ pathname: "/dashboard" }}
                                            id="mylayout_menu_dashboard"
                                            primaryText="ra.page.dashboard"
                                            leftIcon={<DashboardIcon className={classes.svgIcon} id="dashboard" />}
                                            className={classes.menuItem} />
                                    }
                                    <MenuItemLink
                                        to="/displayOptions"
                                        id="mylayout_menu_settings"
                                        primaryText="navigation.settings"
                                        leftIcon={<SettingsIcon className={classes.svgIcon} id="settings" />}
                                        className={classes.menuItem} />
                                    <MenuItemLink
                                        to="/about"
                                        id="mylayout_menu_about"
                                        primaryText="ra.message.about"
                                        leftIcon={<InfoOutlinedIcon className={classes.svgIcon} id="about" />}
                                        className={classes.menuItem} />
                                    {config?.strings?.hamburgerMenuInstructionsText && config.strings.hamburgerMenuInstructionsText.trim().length > 0 &&
                                        <MenuItemLink
                                            component="a"
                                            to="/"
                                            id="mylayout_menu_instructions"
                                            primaryText={config.strings.hamburgerMenuInstructionsText}
                                            leftIcon={
                                                <div>
                                                    <HelpOutlineIcon id="mylayout_help_icon" className={classes.svgIcon} />
                                                    <ChevronRightIcon id="mylayout_help_chevron" style={{ color: '#fff', width: 16, height: 24 }} />
                                                </div>
                                            }
                                            href={config.defaults.hamburgerMenuInstructionsLink}
                                            target="temptraq"
                                            className={classes.menuItem} />
                                    }
                                    {config?.strings?.hamburgerMenuWebsiteText && config.strings.hamburgerMenuWebsiteText.trim().length > 0 &&
                                        <MenuItemLink
                                            component="a"
                                            to="/"
                                            id="mylayout_menu_website"
                                            primaryText={config.strings.hamburgerMenuWebsiteText}
                                            leftIcon={
                                                <div>
                                                    <LanguageIcon id="mylayout_language_icon" className={classes.svgIcon} />
                                                    <ChevronRightIcon id="mylayout_language_chevron" style={{ color: '#fff', width: 16, height: 24 }} />
                                                </div>
                                            }
                                            href={config.defaults.hamburgerMenuWebsiteLink}
                                            target="temptraq"
                                            className={classes.menuItem} />
                                    }
                                    {config?.strings?.hamburgerMenuSurveyText && config.strings.hamburgerMenuSurveyText.trim().length > 0 &&
                                        <MenuItemLink
                                            component="a"
                                            to="/"
                                            id="mylayout_menu_survey"
                                            primaryText={config.strings.hamburgerMenuSurveyText}
                                            leftIcon={
                                                <div>
                                                    <ListIcon id="mylayout_survey_list" className={classes.svgIcon} />
                                                    <ChevronRightIcon id="mylayout_survey_chevron" style={{ color: '#fff', width: 16, height: 24 }} />
                                                </div>
                                            }
                                            href={config.defaults.hamburgerMenuSurveyLink}
                                            target="temptraq"
                                            className={classes.menuItem} />
                                    }
                                </div>
                            </Sidebar>
                        }
                        <div className={classes.content}>
                            {(isSysadmin === 'true' || isClinician === 'true') && show &&
                                <Typography
                                    id="network-statement"
                                    variant="subtitle2"
                                    color="inherit"
                                    style={{
                                        float: "left",
                                        margin: '20px',
                                        display: 'inline',
                                        color: "red",
                                        backgroundColor: "white"
                                    }}
                                >
                                    {translate('navigation.network')}
                                </Typography>
                            }
                            {children}
                        </div>
                        {(isSysadmin === 'true' || isClinician === 'true') && getActiveHospitalId() && getActiveHospitalId() > 0 && noUI &&
                            <AlertDrawer sync={{ alertOpen, setAlertOpen, notify, changeNotify }} />
                        }
                    </main>
                    <Notification id="mylayout_notification" />
                </div>
            </div>
        );
    }
}

const componentPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
]);

MyLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    isLoading: PropTypes.bool,
    logout: componentPropType,
    //sidebarOpen: PropTypes.bool.isRequired,
    //setSidebarVisibility: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({ hospital: state.hospital, config: state.config });
export default connect(mapStateToProps, { changeHospital, changeConfig })(MyLayout);
