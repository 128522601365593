import { createTheme, alpha } from "@mui/material/styles";

export const muiTheme = createTheme(
    {
        palette: {
            primary: {
                main: '#3f51b5',
                contrastText: '#fff',
                backgroundColor: '#182F46'
            },
            secondary: {
                main: '#3f51b5',
                contrastText: '#fff',
                backgroundColor: '#182F46'
            },
            contentWithSidebar: {
                display: 'flex',
                flexGrow: 1,
                backgroundColor: '#182F46'
            },
        },
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: "5px 5px 5px 5px rgba(0,0,0,0);"
                    },
                    elevation1: {
                        boxShadow: '0px'
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    head: {
                        "& > th ": {
                            backgroundColor: '#edece8'
                        }
                    },
                    root: {
                        height: "48px"
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        paddingRight: "10px"
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        paddingTop: "15px",
                        paddingBottom: "7px"
                    }
                }
            },
            RaShow: {
                styleOverrides: {
                    root: {
                        borderRadius: '5px',
                        backgroundColor: '#FFF',
                        paddingRight: '10px',
                        paddingBottom: '25px'
                    },
                    noActions: {
                        marginTop: '0em'
                    }
                }
            },
            MuiFormControl: {
                styleOverrides: {
                    marginDense: {
                        marginTop: "16px",
                        marginBottom: "8px"
                    },
                    defaultProps: {
                        variant: 'standard',
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                }
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent",
                        "&.Mui-disabled": {
                            backgroundColor: "transparent",
                            color: "#000",
                            fontFamily: "Arial",
                            fontSize: "14px"
                        }
                    }
                }
            },

            RaEdit: {
                styleOverrides: {
                    root: {
                        paddingRight: '1em',
                        marginLeft: '1em',
                        color: '#000',
                        backgroundColor: '#FFF',
                        borderRadius: '5px'
                    }
                }
            },
            RaEmpty: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#FFF',
                        borderRadius: '5px',
                        opacity: false,
                        margin: '0em',
                        paddingBottom: '15px',
                        toolbar: {
                            marginTop: '0em',
                            paddingTop: '2em',
                            paddingBottom: '2em',
                            backgroundColor: '#FFF',
                            borderRadius: '5px'
                        }
                    }
                }
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'backbutton' },
                        style: {
                            color: '#3f51b5',
                            backgroundColor: '#f5f5f5',
                            height: '35px'
                        }
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                            color: '#3f51b5',
                            borderColor: '#3f51b5',
                            height: '35px'
                        }
                    },
                    {
                        props: { variant: 'contained' },
                        style: {
                            backgroundColor: '#3f51b5',
                            height: '35px'
                        }
                    }
                ]
            }
        }
    }
);

export const cardActionStyle = {
    marginTop: '15px',
    zIndex: 2,
    display: 'flex',
    padding: 0,
    paddingBottom: '15px',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};

muiTheme.overrides = {
    MuiFilledInput: {
        root: {
            backgroundColor: "transparent",
            transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
            "&:hover": {
                backgroundColor: "transparent"
            },
            "&$focused": {
                backgroundColor: "transparent",
                boxShadow: `${alpha(muiTheme.palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: muiTheme.palette.primary.main
            },
            "&.Mui-disabled": {
                backgroundColor: "transparent",
                color: "#000",
                fontFamily: "Arial",
                fontSize: "14px"
            }
        }
    }
};
