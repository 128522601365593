import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import {
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useTranslate, SaveButton, useRefresh, useRedirect, useNotify, Toolbar
} from 'react-admin';
import customDataProvider from "../dataProvider/customDataProvider";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useFormContext} from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import BackButton from "../ui/BackButton";

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}

function isHex(str) {
  return /^[a-fA-F0-9]+$/i.test(str)
}

const SaveButtonAware = ({ invalid, ...rest }) => (
    <SaveButton alwaysEnable id="save_button" {...rest} />
);

const PatchCreate = (props) => {
  const href = window.location.href;
  const [, queryString] = href.split("?");
  const { patientCode, patientName, patient_id } = parse(queryString);
  const translate = useTranslate();
  const { hospital } = props;
  const submitted = useRef(false);
  const [checked, setChecked] = React.useState(JSON.parse(localStorage.getItem('checked'), (key, value) =>
  typeof value !== 'boolean'
      ? false
      : value
));

  const PatchCreateToolbar = ({ enableMultiPatchAdd, patientId, ...props }) => {
    const { reset } = useFormContext();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();

    useEffect(() => {
      const form = document.getElementById('patchForm');
      const button = document.getElementById('save_button');

      if (form && button) {
        button.addEventListener('click', (e) => {
          submitted.current = true;
        });
        form.addEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            submitted.current = true;
            button.click();
          };
        });
      };
    }, []);

    return (
        <Toolbar {...props} >
          <SaveButtonAware
              style={{ margin: '5px' }}
              mutationOptions={{
                onSuccess: () => {
                  if (enableMultiPatchAdd) {
                    reset();
                    document.getElementById("patchID").focus();
                    notify('resources.patch.patchisadded', {
                      type: 'info',
                      messageArgs: { smart_count: 1 },
                    });
                    submitted.current = false;
                  } else {
                    refresh();
                    redirect(`/PatientSummary/${patientId}/show`);
                  }
                }
              }}
              type="button"
              icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
          />
          <BackButton
              goBack={() => redirect(`/PatientSummary/${patientId}/show`)}
          />
        </Toolbar>
    )
  };

  useEffect(() => {

    document.getElementById("patchID").focus();
    return () => {

    };
  }, []);

  const handleChange = (event) => {
    localStorage.setItem('checked', event.target.checked);
    setChecked(event.target.checked);
  };

  const validatePatchId = async (values) => {
    const errors = {};

    if (submitted.current) {
      if (!values.patchID) {
        errors.patchID = translate('resources.patch.isrequired');
      } else if ((values.patchID && isHex(values.patchID) && ((values.patchID.length === 6) || (values.patchID.length === 7 && values.patchID[0] === '3')))) {
        const patchInUse = await customDataProvider.getPatchAssignment(values.patchID);
        if (patchInUse && patchInUse.assignment.json) {
          errors.patchID = translate('resources.patch.assigned');
        }
      } else {
        errors.patchID = translate('resources.patch.invalid');
      }
    }

    return errors;
  }

  return (
      <Create sx={{ mt: { sm: '-1em' }, mb: { xs: '80px', sm: 1 } }} redirect={false} title="resources.patch.addpatch">
        <SimpleForm
            id="patchForm"
            validate={validatePatchId}
            toolbar={<PatchCreateToolbar enableMultiPatchAdd={checked} patientId={patient_id} />}
            defaultValues={{ patient_id: patient_id, patientName: patientName, hospitalCode: hospital.hospitalCode, patientCode: patientCode, patchUUID: uuidv4() }}
        >
          <br />
          {patientCode === undefined &&
              <>
                <ReferenceInput
                    source="patientCode"
                    reference="PatientSummary"
                    allowEmpty
                    validate={required()}
                >
                  <SelectInput optionText="patientName" optionValue="patientCode" label="resources.patient.label" />
                </ReferenceInput>
                <br />
              </>
          }
          {patientCode && <><TextInput disabled source="patientCode" label="resources.patient.patientidlabel" /><br /></>}
          {patientCode && <><TextInput disabled source="patientName" label="resources.patient.patientid2label" /><br /></>}
          <TextInput validate={required()} source="patchID" name="patchID" id="patchID" autoFocus label="resources.patch.patchid" />
          <FormControlLabel
              id="switchLabel"
              control={<Switch id="switch" checked={checked} onChange={handleChange} />}
              label={translate('resources.patch.multiple')}
          />
          <br />
          {patientCode && <><TextInput style={{ display: 'none', visibility: 'hidden' }} value={patient_id} source="patientID" /><br /></>}
        </SimpleForm>
      </Create>
  );
};

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital, enableMultiPatchAdd: state.enableMultiPatchAdd });
const mapDispatchToProps = {}; // to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatchCreate);