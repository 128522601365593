import AutoModeIcon from '@mui/icons-material/AutoMode';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StorageIcon from '@mui/icons-material/Storage';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import Responsive from '../../ui/Responsive';
import { getStatus } from './data';
import PublicIcon from '@mui/icons-material/Public';

const min = 60000;

function Toolbar({ refresh, setRefresh }) {
  const translate = useTranslate();
  const [paused, setPaused] = React.useState(0);
  const [notChecked, setNotChecked] = React.useState(0);
  const [up, setUp] = React.useState(0);
  const [seemsDown, setSeemsDown] = React.useState(0);
  const [down, setDown] = React.useState(0);
  const isSysadmin = JSON.parse(localStorage.getItem('isSysAdmin'));

  const [anchorRef, setAnchorRef] = React.useState(null);
  const openRef = Boolean(anchorRef);

  const monitors = [
    {
      id: 0,
      mon: up,
      text: translate('dashboard.run'),
      color: 'success',
    },
    {
      id: 1,
      mon: paused,
      text: translate('dashboard.pause'),
      color: 'warning',
    },
    {
      id: 2,
      mon: seemsDown,
      text: translate('dashboard.seems'),
      color: 'error',
    },
    {
      id: 3,
      mon: down,
      text: translate('dashboard.down'),
      color: 'error',
    },
    {
      id: 4,
      mon: notChecked,
      text: translate('dashboard.not'),
      color: 'disabled',
    },
  ];

  React.useEffect(() => {
    let isMounted = true;
    getStatus()
      .then((res) => {
        if (isMounted) {
          setPaused(res.paused);
          setNotChecked(res.checked);
          setUp(res.up);
          setSeemsDown(res.seemsDown);
          setDown(res.down);
        }
      })
    setInterval(() => {
      getStatus()
        .then((res) => {
          if (isMounted) {
            setPaused(res.paused);
            setNotChecked(res.checked);
            setUp(res.up);
            setSeemsDown(res.seemsDown);
            setDown(res.down);
          }
        })
    }, refresh);
    return () => { isMounted = false }
  }, []); // eslint-disable-line

  const handleRefClick = (event) => {
    setAnchorRef(event.currentTarget);
  };

  const handleRefClose = () => {
    setAnchorRef(null);
  };
  return (
    <Box
      id="toolbar"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#000',
        borderRadius: '5px',
        px: 4,
        backgroundColor: '#fff',
        width: `${isSysadmin ? null : 'fit-content'}`
      }}
    >
      {isSysadmin &&
        <>
          <StorageIcon id="server_icon" sx={{ height: '24px', width: '24px', mr: 1 }} />
          <Typography id="server_label" sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }} fontWeight="bold" noWrap>
            {translate('dashboard.server')}:
          </Typography>
          <Box sx={{
            maxWidth: 500,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
          >
            {monitors.map((item) => (
              <Box key={item.id}>
                {!!item.mon
                  && (
                    <Box
                      sx={{
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                      }}
                    >
                      <RadioButtonCheckedIcon sx={{ height: '24px', width: '24px' }} id="status_icon" color={item.color} />
                      <Typography id="status_number" fontWeight="bold">
                        {item.mon}
                      </Typography>
                      <Typography id="status_text">
                        {item.text}
                      </Typography>
                    </Box>
                  )}
              </Box>
            ))}
          </Box>
          <Box sx={{ m: 'auto' }}>
            <Responsive
              xsmall={
                <Button
                  component="a"
                  variant="contained"
                  id="uptime_link"
                  href="https://uptimerobot.com/dashboard?ref=website-header#mainDashboard"
                  target="_blank"
                >
                  <PublicIcon sx={{ height: '24px', width: '24px' }} />
                </Button>
              }
              medium={
                <Button
                  component="a"
                  variant="contained"
                  id="uptime_link"
                  href="https://uptimerobot.com/dashboard?ref=website-header#mainDashboard"
                  target="_blank"
                >
                  {translate('dashboard.go')}
                </Button>
              }
            />
          </Box>
        </>
      }
      <Tooltip title={translate('dashboard.tooltip', { refresh: refresh / min })}>
        <IconButton id="refresh_button" sx={{ ml: 'auto' }} onClick={handleRefClick}>
          <AutoModeIcon sx={{ height: '35px', width: '35px' }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="refresh_menu"
        align="center"
        anchorEl={anchorRef}
        open={openRef}
        onClose={handleRefClose}
      >
        <Typography variant="h6" sx={{ width: 140, textAlign: 'center' }}>
          {translate('dashboard.refresh')}
        </Typography>
        <Divider />
        <MenuItem onClick={() => setRefresh(min * 5)} sx={{ fontWeight: refresh / min === 5 ? 'bold' : null }}>5 {translate('dashboard.min')}</MenuItem>
        <MenuItem onClick={() => setRefresh(min * 10)} sx={{ fontWeight: refresh / min === 10 ? 'bold' : null }}>10 {translate('dashboard.min')}*</MenuItem>
        <MenuItem onClick={() => setRefresh(min * 30)} sx={{ fontWeight: refresh / min === 30 ? 'bold' : null }}>30 {translate('dashboard.min')}</MenuItem>
        <MenuItem onClick={() => setRefresh(min * 60)} sx={{ fontWeight: refresh / min === 60 ? 'bold' : null }}>60 {translate('dashboard.min')}</MenuItem>
        <Divider />
        <Typography variant="body2" sx={{ width: 140, textAlign: 'center' }}>
          *{translate('resources.misc.default')}
        </Typography>
      </Menu>
    </Box>
  );
}

Toolbar.propTypes = {
  refresh: PropTypes.number.isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default Toolbar;
