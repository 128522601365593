import * as React from 'react';
import { LocalesMenuButton } from "react-admin";

export const LanguageMenu = () => (
    <LocalesMenuButton languages={[
        { locale: 'de', name: 'Deutsch' },
        { locale: 'es', name: 'Español' },
        { locale: 'en', name: 'English' },
        { locale: 'fr', name: 'Français' }
    ]} />
);