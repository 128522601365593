import React from 'react';
import {
    Create,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SaveButton,
    SelectInput,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useRefresh, useTranslate
} from 'react-admin';
import BackButton from '../ui/BackButton';
import SaveIcon from '@mui/icons-material/Save';
import { getActiveHospitalShortName } from "./ActiveHospital";
import { adjustForOral, highTempThreshold, lowTempThreshold, validateThresholds } from "./hospitalCommon";

const EntityCreateToolbar = (props) => {
    const refresh = useRefresh();
    const redirect = useRedirect();

    return (
        <Toolbar {...props}>
            <SaveButton
                id="save_button"
                alwaysEnable
                submitonenter="true"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
                mutationOptions={{
                    onSuccess: (response) => {
                        refresh();
                        redirect('show', 'HospitalSummary', response.id);
                    }
                }}
                type="button"
            />
            <BackButton
                goBack={() => redirect('list', 'HospitalSummary')}
            />
        </Toolbar>
    )
};

const HospitalsummaryCreate = (props) => {
    const translate = useTranslate();

    return (
        <Create sx={{ mt: { sm: '-1em' }, mb: { xs: '80px', sm: 1 } }} title="resources.hospital.add" {...props}>
            <SimpleForm validate={validateThresholds} toolbar={<EntityCreateToolbar />}  >
                <TextInput id="hospitalCode" label="resources.hospital.parentLabel" source="hospitalCode"
                    defaultValue={getActiveHospitalShortName()} disabled />
                <br />
                <TextInput label="resources.hospital.hospitalid" source="tenantcode" validate={required()} />
                <br />
                <TextInput label="resources.hospital.name" id="hospitalName" source="hospitalName" validate={required()} />
                <br />
                {adjustForOral()}
                <br />
                {highTempThreshold(translate)}
                <br />
                {lowTempThreshold(translate)}
                <ReferenceInput
                    perPage={100}
                    source="configurationCode"
                    reference="configurations"
                >
                    <SelectInput
                        label="resources.hospital.show.config"
                        style={{ width: '190px' }}
                        optionValue="configurationcode"
                        optionText="configurationcode"
                    />
                </ReferenceInput>
                <ReferenceArrayInput
                    perPage={100}
                    source="assignedIntegrations"
                    reference="tenantintegrations"
                >
                    <SelectArrayInput
                        id="integrations"
                        label="resources.hospital.show.integrations"
                        style={{ width: '190px' }}
                        optionText="pipeline"
                    />
                </ReferenceArrayInput>
                <br />
            </SimpleForm>
        </Create>
    );
}

export default HospitalsummaryCreate;