import { TrendingUp } from "@mui/icons-material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import React from 'react';
import { Title, useTranslate } from 'react-admin';
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveHospitalShortName } from '../hospitals/ActiveHospital';
import versionData from '../version.json';

export const About = () => {
  const [serverVersion, setServerVersion] = React.useState();
  const translate = useTranslate();

  React.useEffect(() => {
    let isMounted = true;
    customDataProvider.getVersion()
      .then(response => {
        if (isMounted) setServerVersion(response.data);
      }).catch(err => {
        console.log(err);
      });

    return () => { isMounted = false };
  }, []);

  const version = versionData.MAJOR + '.' + versionData.MINOR + '.' + versionData.PATCH + '.' + versionData.BUILD;
  const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND.trim().toLowerCase() : "temptraq";
  const earlyIndicator = JSON.parse(localStorage.getItem('config')).parameters?.enableEarlyIndicator;

  return (
    <Card id="maincard">
      <Title id="aboutTitle" title="ra.message.about" />
      <CardContent >
        <div />
        {
          brand === 'ct360' &&
          <img
            id="ct360_logo"
            src="/ct360_logo_dark.png"
            style={{ width: "100%", maxWidth: '500px', align: "center-vertical", marginBottom: '70px' }}
            alt="Cell Therapy 360"
          />
        }
        {
          brand === 'temptraq' &&
          <img
            id="temptraq_logo"
            src="/temptraq_logo_dark.png"
            style={{ width: "100%", maxWidth: '500px', align: "center-vertical", marginBottom: '70px' }}
            alt="TempTraq Clinician"
          />
        }

        <table>
          <tbody>
            <tr>
              <td>
                <img
                  id="manufacture_png"
                  src="/ce_manufacturer.png"
                  style={{
                    float: 'right',
                    height: '55px',
                    verticalAlign: 'middle',
                    filter: 'invert(100%)'
                  }}
                  alt="Manufacturer"
                />
              </td>
              <td colSpan='2' id="address">
                <b> Blue Spark Technologies, Inc.</b>
                <br />806 Sharon Drive, Suite G
                <br />Westlake, OH  44145
              </td>
            </tr>
            <tr>
              <td>
                <img
                  id="instructions_png"
                  src="/ce_read_instructions.png"
                  style={{
                    float: 'right',
                    height: '40px',
                    filter: 'invert(100%)',
                  }}
                  alt="read instructions"
                />
              </td>
              <td>
                <img
                  id="md_png"
                  src="/iso_md.png"
                  style={{
                    float: 'left',
                    height: '20px',
                  }}
                  alt="Medical Device"
                />
              </td>
            </tr>
            <tr>
              <td>
                <img
                  id="ref_png"
                  src="/ce_ref.png"
                  style={{
                    float: 'right',
                    height: '20px',
                    filter: 'invert(100%)',
                    verticalAlign: 'middle'
                  }}
                  alt="Ref"
                />
              </td>
              <td colSpan='2' id="ref">
                {version}
              </td>
            </tr>
            <tr>
              <td id="udi_label">
                <img
                  id="udi_png"
                  src="/iso_udi.png"
                  style={{
                    float: 'right',
                    height: '20px',
                    verticalAlign: 'middle'
                  }}
                  alt="UDI"
                />
              </td>
              <td colSpan='2' id="udi">
                (01) 00851203006173 (10) {version.split('.').join('')}
              </td>
            </tr>

            <tr>
              <td id="server_label">
                <b style={{ float: 'right' }}> {translate('settings.server')} </b>
              </td>
              <td colSpan='2' id="server">
                {serverVersion && serverVersion.full} / {serverVersion && serverVersion.key}
              </td>
            </tr>

            <tr>
              <td id="hospital_label">
                <b style={{ float: 'right' }}> {translate('resources.hospital.label')} </b>
              </td>
              <td colSpan='2' id="hospital">
                {getActiveHospitalShortName()}
              </td>
            </tr>
            {
              earlyIndicator &&
              <tr>
                <td id="early_label">
                  <b style={{ float: 'right' }}> {translate('earlyindicator.title')} </b>
                </td>
                <td style={{ width: '15px' }} id="early_icon">
                  {translate('settings.enabled')}
                </td>
                <td>
                  <TrendingUp sx={{ verticalAlign: 'middle', fontSize: 24, color: '#F49C66' }} />
                </td>
              </tr>
            }
            <tr>
              <td id="legal_label">
                <b style={{ float: 'right' }}>{translate('settings.legal')}</b>
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <a
                  id="privacy"
                  href="https://www.iubenda.com/privacy-policy/19501097"
                  target="PRIVACYPOLICY">
                  {translate('settings.privacy')}
                </a>
                <br />
                <a
                  id="terms"
                  href="https://www.iubenda.com/terms-and-conditions/19501097"
                  target="TERMSOFUSE">
                  {translate('settings.terms')}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}



export default About;
