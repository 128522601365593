import React from 'react';
import {
    BooleanInput,
    Edit,
    Labeled,
    PasswordInput,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRecordContext,
    useRedirect,
    useTranslate
} from 'react-admin';
import BackButton from '../ui/BackButton';
import DeleteUserButton from './DeleteUserButton';
import SaveIcon from '@mui/icons-material/Save';
import { validatePassword } from '../util/validatePassword';

const EntityEditToolbar = (props) => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const sameUser = localStorage.getItem('username') !== record.username;

    return (
        <Toolbar {...props} >
            <SaveButton
                id="save_button"
                alwaysEnable
                variant="contained"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
            />
            <BackButton
                goBack={() => redirect('show', 'users', record.id)}
                id="backButton"
            />
            {sameUser &&
                <>
                    <span style={{ flex: "1" }} />
                    <DeleteUserButton />
                    <span style={{ flex: "1" }} />
                </>
            }
        </Toolbar>
    )
};

const UserEdit = (props) => {
    const translate = useTranslate();
    const isSysAdmin = localStorage.getItem('isSysAdmin');
    const config = JSON.parse(localStorage.getItem('config'));

    const validateUserCreation = (values) => {
        const errors = {};
        const passVal = values.password ? validatePassword('cpw', config, translate)(values.password) : null

        if (!values.username) {
            errors.username = 'ra.validation.required';
        };
        if (passVal) {
            errors.password = passVal;
        };
        if ((!values.tenantadminids || !values.tenantadminids.length) && (!values.clinicianids || !values.clinicianids.length)) {
            errors.tenantadminids = 'user.create.role';
            errors.clinicianids = 'user.create.role';
        };
        return errors;
    };

    return (
        <Edit
            redirect="show"
            sx={{
                m: '0px',
                mb: { xs: '80px', sm: 1 },
                pl: '10px',
                pr: '10px',
                minHeight: '0px',
                borderRadius: '5px',
                backgroundColor: '#FFFFFF'
            }}
            title="user.edit.label" {...props}
            actions={false}
        >
            <SimpleForm id="editform" validate={validateUserCreation} toolbar={<EntityEditToolbar />}>
                <TextInput
                    style={{ width: '300px' }}
                    id="fname"
                    source="fName"
                    label="user.create.fname"
                    format={(value) => value == null ? '' : value}
                />
                <br />
                <TextInput
                    style={{ width: '300px' }}
                    id="lname"
                    source="lName"
                    label="user.create.lname"
                    format={(value) => value == null ? '' : value}
                />
                <br />
                <TextInput
                    style={{ width: '300px' }}
                    id="username"
                    source="username"
                    validate={required()}
                    label="ra.auth.username"
                    format={(value) => value == null ? '' : value}
                />
                <br />
                <TextInput
                    style={{ width: '300px' }}
                    id="email" source="email"
                    label="user.create.email"
                    format={(value) => value == null ? '' : value}
                />
                <br />
                {isSysAdmin === 'true' &&
                    <>
                        <PasswordInput
                            id="password"
                            inputProps={{ value: undefined }}
                            source="password"
                            label="ra.auth.password"
                            sx={{ width: '300px' }}
                        />
                        <br />
                    </>
                }
                <Labeled id="adminLabel" label="user.create.admin">
                    <ReferenceArrayInput
                        perPage={0}
                        sort={{ field: 'groupcode', order: 'ASC' }}
                        source="tenantadminids"
                        reference="tenantassignments"
                    >
                        <SelectArrayInput id="TENANT_ADMIN" optionText="shortName" label={false} />
                    </ReferenceArrayInput>
                </Labeled>
                <br />
                <Labeled id="clinicianLabel" label="user.create.clinician">
                    <ReferenceArrayInput
                        perPage={0}
                        sort={{ field: 'groupcode', order: 'ASC' }}
                        source="clinicianids"
                        reference="tenantassignments"
                    >
                        <SelectArrayInput id="CLINICIAN" optionText="shortName" label={false} />
                    </ReferenceArrayInput>
                </Labeled>
                <br />
                <BooleanInput id="active" label="user.create.active" source="active" defaultValue />
            </SimpleForm>
        </Edit>
    );
}

export default UserEdit;