import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useTranslate } from 'react-admin';
import Loading, { LoadingTitle } from './Load';

function Active({ hospital }) {
    const translate = useTranslate();
    let hosp = 0, pat = 0;
    if (!hospital[0]) {
        for (let i = 0; i < hospital[1].length; i += 1) {
            if ((hospital[1])[i].active) hosp += 1;
            pat += (hospital[1])[i].activePatients;
        }
    }
    return (
        <TableContainer id="active_table" component={Paper} sx={{ width: 300, m: 0, height: 'fit-content' }}>
            <Table aria-label="collapsible table" size="small">
                <TableBody>
                    {hospital[0]
                        ? (
                            <>
                                <LoadingTitle />
                                <Loading />
                                <Loading />
                                <Loading />
                            </>
                        ) : (
                            <>
                                <TableRow>
                                    <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                                        <Typography id="active_title" fontWeight="bold" variant="h6">
                                            {translate('dashboard.active')}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography id="active_hospitals_label" variant="h6">
                                        {translate('dashboard.hospitals')}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography id="active_hospitals" fontWeight="bold" variant="h6">
                                            {hosp}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography id="active_patients_label" variant="h6">
                                        {translate('dashboard.patients')}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography id="active_patients" fontWeight="bold" variant="h6">
                                            {pat}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography id="active_patches_label" variant="h6">
                                        {translate('dashboard.patches')}:
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography id="active_patches" fontWeight="bold" variant="h6">
                                            {pat}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </>
                        )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

Active.propTypes = {
    hospital: PropTypes.array.isRequired,
};

export default Active;
