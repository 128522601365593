const roundToTwo = num => {
  return +(Math.round(num + 'e+2') + 'e-2');
};

const roundToOne = num => {
  return Math.round((Math.round(num * 100)/100)*10)/10;
};

export const numConvertFromI = (num) => {
  return Number(roundToTwo(num/100));
};

export const numConvertFromInternalToOralC = (num) => {
  return num>0 ? numConvertFromI(num+56) : 0;
}

export const numConvertFromInternalToAxillaryC = (num) => {
  return num>0 ? numConvertFromI(num) : 0;
}

export const numConvertFromC = (num, scale) => {
  return Number(roundToOne(scale === 'F' ? (num * 9 / 5) + 32 : num));
};

export const numConvertToC = (num, scale) => {
  return Number(roundToTwo(scale === 'C' ? num : (num - 32) * 5 / 9));
};

export const stringConvertFromC = (tempInC, scale) => {
  if (scale === undefined) {
    return;
  }
  if (tempInC === 0) {
    return '--';
  }
  return numConvertFromC(tempInC, scale).toFixed(1) + ' °' + scale;
};
