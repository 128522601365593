import { SwapVert, TrendingUp } from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Divider, IconButton, SwipeableDrawer } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useRedirect, useSidebarState, useTranslate } from 'react-admin';
import { connect } from "react-redux";
import { Context } from "../dataProvider/context";
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveHospitalId } from "../hospitals/ActiveHospital";
import { dateFormat } from '../patches/dateFormat';
import { numConvertFromI, stringConvertFromC } from "../tempscale/tempConvert";
import Responsive from '../ui/Responsive';
import sound from './alert_temp_1.mp3';

const drawerWidth = 300;

const svgIcon = {
    width: '24px',
    height: '24px'
}

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        height: '100%',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

// picks the icon for the given alertCode
const iconPicker = (code) => {
    if (code === 'EARLY') return <TrendingUp sx={svgIcon} />;
    if (code === 'NRD') return <MobiledataOffIcon sx={svgIcon} />;
    if (code === 'FIN') return <CheckCircleIcon sx={svgIcon} />;
    if (code === 'ERR') return <ErrorIcon sx={svgIcon} />;
    if (code === 'AlmostFIN') return <AccessTimeIcon sx={svgIcon} />
    return <ThermostatIcon sx={svgIcon} />
};

// picks the color for the icon given the current alertCode
const colorPicker = (code) => {
    if (code === 'EARLY') return '#F49C66';
    if (code === 'LOW') return '#4BC4E3';
    if (code === 'NRD') return '#CDCDFA';
    if (code === 'FIN' || code === 'AlmostFIN') return '#AAAAAA';
    if (code === 'ERR') return '#F0E68C';
    return '#F26666';
};

const AlertDrawer = (props) => {
    const { resize, setResize, setContext } = useContext(Context);
    const scale = props.temp;
    const timerRef = useRef(null);
    const componentMounted = useRef(true);
    const translate = useTranslate();
    const sortByRef = useRef(localStorage.getItem('alertSortBy') || 'alertTime');
    const dirRef = useRef(localStorage.getItem('alertSortDir') || 'desc');
    const redirect = useRedirect();
    const refresh = 60 * 1000;
    const [sidebarOpen, setSidebarVisibility] = useSidebarState();
    const { alertOpen, setAlertOpen, notify, changeNotify } = props.sync;
    const audioRef = useRef(null);
    const [open, setOpen] = useState(false);
    const isClinician = JSON.parse(localStorage.getItem('isClinician'));
    const delRef = useRef([]);
    // certain true false combintations error any other way inside JSON.parse()
    const isTest = JSON.parse(sessionStorage.getItem('test'), (key, value) =>
        typeof value !== 'boolean'
            ? false
            : value
    );
    const [play, setPlay] = useState(JSON.parse(localStorage.getItem('play'), (key, value) =>
        typeof value !== 'boolean'
            ? false
            : value
    ));
    const [mute, setMute] = useState(JSON.parse(localStorage.getItem('mute'), (key, value) =>
        typeof value !== 'boolean'
            ? false
            : value
    ));

    // picks the text for the given error
    const alertPicker = (item) => {
        const code = item.alertCode;

        if (code === 'ALERT') return [
            translate('alert.title.alert', { patient: item.patientCode }),
            translate('alert.description.alert', {
                time: new Date(item.alertTime).toLocaleTimeString().replace(/:\d{2}\s/, '\u00A0'),
                patient: item.patientCode,
                patch: item.patchId
            })
        ];
        if (code === 'NRD') return [
            translate('alert.title.nrd', { patient: item.patientCode }),
            translate('alert.description.nrd', {
                time: new Date(item.alertTime).toLocaleTimeString().replace(/:\d{2}\s/, '\u00A0'),
                patient: item.patientCode,
                patch: item.patchId
            })
        ];
        if (code === 'AlmostFIN') return [
            translate('alert.title.afin', { patient: item.patientCode }),
            translate('alert.description.afin', { patch: item.patchId })
        ];
        if (code === 'ERR') return [
            translate('alert.title.err', { patient: item.patientCode }),
            translate('alert.description.err', {
                patient: item.patientCode,
                patch: item.patchId
            })
        ];
        if (code === 'EARLY') {
            //convertedTemp comes from hightempThreshold
            return [
                translate('alert.title.early', { patient: item.patientCode }),
                translate('alert.description.early', { alertTemp: stringConvertFromC(numConvertFromI(localStorage.getItem("highTempThreshold")), scale) })
            ];
        }
    };

    // hand made accordion to handle saved open/close states
    const CustomAccordion = ({ item }) => {
        const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem(item.id), (key, value) =>
            typeof value !== 'boolean'
                ? false
                : value
        ));

        const handleChange = () => (e, isExpanded) => {
            setExpanded(isExpanded);
            sessionStorage.setItem(item.id, isExpanded);
        };

        return (
            <Accordion sx={{ backgroundColor: 'transparent', py: 0, px: 1 }} expanded={expanded} onChange={handleChange()} disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
                    <span style={{ color: colorPicker(item.alertCode), marginRight: '5px' }}>
                        {iconPicker(item.alertCode)}
                    </span>
                    <b>
                        {alertPicker(item)[0]}
                    </b>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0, mb: 2 }}>
                    <Divider sx={{ mb: 1 }} />
                    {alertPicker(item)[1]}
                </AccordionDetails>
            </Accordion>
        )
    };

    // handler for agreeing to the notification sounds
    const handleAgree = () => {
        localStorage.setItem('play', true);
        setPlay(true);
        setOpen(false);
    };

    const handleDisagree = () => {
        localStorage.setItem('play', false);
        localStorage.setItem('mute', true);
        setPlay(false);
        setMute(true)
        setOpen(false);
    };

    // handler for the mute toggle button
    const muteToggle = () => {
        localStorage.setItem('mute', !mute);
        setMute(!mute);
    };

    // logic to mute and unmute sound
    useEffect(() => {
        const audio = new Audio(sound);
        if (!audioRef.current) audioRef.current = audio;

        if (play && notify.length && isClinician && !isTest && !mute) {
            audioRef.current.mute = false;
            audioRef.current.loop = true;
            audioRef.current.play()
                .catch(() => {
                    localStorage.setItem('play', false);
                    setPlay(false);
                    setOpen(true);
                });
        }
        if (!play || !notify.length || mute) {
            audioRef.current.mute = true;
            audioRef.current.loop = false;
            audioRef.current.pause();
        }
        if (!play && !isTest) {
            setOpen(true);
        }
    }, [notify, play, mute]); // eslint-disable-line

    // logic to run and sync the refreshData() with other events
    useEffect(() => {
        window.addEventListener('storage', (e) => {
            if (e.key && e.key === 'updatepatchstatus') {
                refreshData();
            }
        });
        refreshData();
        return () => {
            clearTimeout(timerRef.current);
            componentMounted.current = false;
            audioRef.current.pause(); // eslint-disable-line
        }
    }, []); // eslint-disable-line

    // Get server data on alerts
    const refreshData = () => {
        clearTimeout(timerRef.current);

        if (getActiveHospitalId() !== null) {
            customDataProvider.getAlertsByTenant(getActiveHospitalId(), sortByRef.current, dirRef.current)
                .then(alerts => {
                    if (componentMounted.current) {
                        alerts = alerts.filter((obj) => !delRef.current.includes(obj.id));
                        changeNotify(alerts);
                        timerRef.current = setTimeout(() => {
                            delRef.current = [];
                            refreshData();
                        }, refresh);
                        setContext(new Date().getTime());
                        localStorage.setItem("alertList", JSON.stringify(alerts));
                        let newEvent = new Event("storage");
                        newEvent.key = 'updateAlertFrame';
                        window.dispatchEvent(newEvent);
                    }
                });
        }
    };

    // toggle the alert draw synced to the main sidebar state
    const toggleDrawer = () => {
        if (sidebarOpen) {
            setSidebarVisibility(!sidebarOpen);
            sessionStorage.setItem('alertOpen', !alertOpen);
            setAlertOpen(!alertOpen);
        }
        if (!sidebarOpen) {
            sessionStorage.setItem('alertOpen', !alertOpen);
            setAlertOpen(!alertOpen)
        }
        setResize(!resize);
    };

    // make the state of the alert drawer a session value
    useEffect(() => {
        if (sidebarOpen) {
            sessionStorage.setItem('alertOpen', false);
            setAlertOpen(false);
        }
        setResize(!resize);
    }, [sidebarOpen]); // eslint-disable-line

    // remove alerts from the server
    const handleRemove = (id) => {
        delRef.current.push(id);
        changeNotify(notify.filter((obj) => obj.id !== id));
        customDataProvider.clearAlert(id)
            .then(() => {
                refreshData();
            });
    };

    // redirect function
    const patientShow = (patientId) => {
        redirect('/PatientSummary/' + patientId + '/show');
    };

    // taken out of main return for responsive reasons
    const AlertList = () => (
        <List>
            <ListItem disablePadding sx={{ p: 0, display: 'block' }}>
                <ListItemButton
                    id="alert_toggle"
                    sx={{
                        p: 0,
                        minHeight: 48,
                        justifyContent: alertOpen ? 'initial' : 'center',
                        px: 2.5,
                        width: alertOpen && !!notify.length ? 'calc(100% - 45px)' : '100%'
                    }}
                    onClick={alertOpen ? toggleDrawer : toggleDrawer}
                >
                    <ListItemIcon sx={{ minWidth: 0, ml: alertOpen ? -1 : 1 }}>
                        {alertOpen ? <ChevronRightIcon sx={svgIcon} /> : <ChevronLeftIcon sx={svgIcon} />}
                        {notify.length ? <NotificationsActiveIcon sx={svgIcon} /> : <NotificationsNoneIcon sx={svgIcon} />}
                    </ListItemIcon>
                    {alertOpen && translate('alert.headerTitle')}
                </ListItemButton>
                {alertOpen && !!notify.length &&
                    <IconButton
                        sx={{ position: 'absolute', right: 4, top: 4 }}
                        onClick={() => {
                            const direction = dirRef.current === 'asc' ? 'desc' : 'asc';
                            localStorage.setItem('alertSortDir', direction);
                            dirRef.current = direction;
                            refreshData();
                        }}>
                        <SwapVert />
                    </IconButton>}
            </ListItem>
            {isClinician &&
                <ListItem disablePadding sx={{ p: 0, display: 'block' }}>
                    <ListItemButton
                        id="sound_toggle"
                        sx={{ p: 0, minHeight: 48, justifyContent: alertOpen ? 'initial' : 'center', px: 2.5 }}
                        onClick={muteToggle}
                    >
                        <ListItemIcon sx={{ minWidth: 0, ml: alertOpen ? -1 : 1 }}>
                            {mute ? <VolumeOffIcon sx={svgIcon} /> : <VolumeUpIcon sx={svgIcon} />}
                        </ListItemIcon>
                        {alertOpen && (mute ? translate('alert.soundoff') : translate('alert.soundon'))}
                    </ListItemButton>
                </ListItem>
            }
            {notify.map((item) => (
                <ListItem key={item.id} disablePadding sx={{ p: 0, display: 'block', borderTop: alertOpen && "1px solid rgba(200,200,200)" }}>
                    {!alertOpen &&
                        <ListItemButton
                            sx={{ p: 0, minHeight: 48, justifyContent: alertOpen ? 'initial' : 'center', px: 2.5 }}
                        >
                            <Tooltip
                                title={(
                                    <Box style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                                        <Typography fontWeight="bold" noWrap>
                                            {alertPicker(item)[0]}
                                        </Typography>
                                        <Typography>
                                            {alertPicker(item)[1]}
                                        </Typography>
                                        <br />
                                        <span style={{ position: 'absolute', right: '10px', bottom: '5px', fontSize: '11px', fontStyle: 'italic' }}>
                                            {dateFormat(item.alertTime)}
                                        </span>
                                    </Box>
                                )}
                                disableInteractive
                                disableTouchListener
                                disableHoverListener={alertOpen && true}
                            >
                                <ListItemIcon id="alert_icon" sx={{ minWidth: 0, ml: alertOpen ? -1 : 1, color: colorPicker(item.alertCode) }}>
                                    {iconPicker(item.alertCode)}
                                </ListItemIcon>
                            </Tooltip>
                        </ListItemButton>}

                    {alertOpen &&
                        <ListItemText id="alert_message" sx={{ whiteSpace: 'pre-wrap', m: 0 }}>
                            <CustomAccordion item={item} {...props} />
                        </ListItemText>}
                    {alertOpen &&
                        <ListItemText sx={{ mt: -1 }}>
                            <Typography component="div" sx={{ width: '100%', textAlign: 'center' }} noWrap>

                                <Responsive
                                    xsmall={
                                        <>
                                            <Button id="clear_alert_button" sx={{ p: 0 }} onClick={() => handleRemove(item.id)}>
                                                {translate('alert.clear')}
                                            </Button>
                                            <Button
                                                id="view_alert_button"
                                                sx={{ p: 0 }}
                                                onClick={() => { patientShow(item.patientId); setAlertOpen(false); }}
                                            >
                                                {translate('ra.action.show')}
                                            </Button>
                                        </>
                                    }
                                    medium={
                                        <>
                                            <Button id="clear_alert_button" sx={{ p: 0, mr: 1 }} onClick={() => handleRemove(item.id)}>
                                                {translate('alert.clear')}
                                            </Button>
                                            <Button id="view_alert_button" sx={{ p: 0, ml: 1 }} onClick={() => patientShow(item.patientId)}>
                                                {translate('ra.action.show')}
                                            </Button>
                                        </>
                                    }
                                />
                                <span style={{ fontSize: '11px', fontStyle: 'italic' }}>
                                    {dateFormat(item.alertTime)}
                                </span>
                            </Typography>
                        </ListItemText>}
                </ListItem>
            ))
            }
        </List >
    )

    return (
        <Box>
            {isClinician && !isTest && !mute &&
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {translate('alert.notetitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {translate('alert.notedescr')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAgree} autoFocus>
                            {translate('alert.soundon')}
                        </Button>
                        <Button onClick={handleDisagree}>
                            {translate('alert.soundoff')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Responsive
                xsmall={
                    <SwipeableDrawer
                        anchor="right"
                        open={alertOpen}
                        onOpen={toggleDrawer}
                        onClose={toggleDrawer}
                        PaperProps={{ style: { width: 300 } }}
                        disableBackdropTransition
                        disableSwipeToOpen
                        disableDiscovery
                    >
                        <AlertList />
                    </SwipeableDrawer>
                }
                medium={
                    <Drawer
                        PaperProps={{ id: "alerts" }}
                        variant="permanent"
                        open={alertOpen}
                        anchor="right"
                        sx={{
                            '& .MuiDrawer-root': {
                                mt: 1,
                                position: 'sticky',
                                height: 'fit-content',
                            },
                            '& .MuiPaper-root': {
                                borderTopLeftRadius: '4px',
                                borderBottomLeftRadius: '4px',
                                border: 0,
                                mt: 1,
                                position: 'sticky',
                                height: 'fit-content',

                            },
                        }}
                    >
                        <AlertList />
                    </Drawer>
                }
            />
        </Box>
    );
}

const mapStateToProps = state => ({ temp: state.temp });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(AlertDrawer);