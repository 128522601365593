import { connect } from 'react-redux';
import React from 'react';
import {
    Create,
    PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useRefresh,
    useTranslate
} from 'react-admin';
import BackButton from '../ui/BackButton';
import { numConvertFromI } from '../tempscale/tempConvert'
import { validatePassword } from "../util/validatePassword";
import { inputPatientId2, inputPatientAlertTemp } from "./PatientCommon";
import SaveIcon from '@mui/icons-material/Save';
import { getActiveHospitalShortName, getActiveHospitalId } from "../hospitals/ActiveHospital";
import customDataProvider from '../dataProvider/customDataProvider';

const PatientCreateToolbar = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    return (
        <Toolbar id="patientCreateToolbar" {...props} >
            <SaveButton
                variant="contained"
                id="patientCreateSave"
                alwaysEnable
                submitonenter="true"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
                mutationOptions={{
                    onSuccess: (response) => {
                        refresh();
                        redirect('show', 'PatientSummary', response.id);
                    }
                }}
                type="button"
            />
            <BackButton
                goBack={() => redirect('list', 'PatientSummary')}
                id="patientCreateBack"
                submitonenter="false"
            />
        </Toolbar>
    )
}

const validateFields = (hospitalId) => async function (values) {
    const patientCode = values;
    const regex = /^([a-z]|\d|-|\.)+$/i

    if (patientCode.match(regex)) {
        const response = await customDataProvider.getPatientExists(hospitalId, patientCode)
        if (response.exists === 'true') {
            return 'resources.patient.duplicateId';
        }
    } else if (!patientCode.match(regex)) {
        return 'resources.misc.allowed'
    }

    return undefined;
}

const PatientCreate = (props) => {
    const translate = useTranslate();
    const { config } = props;

    const info = {
        patientAlertEditable: config.parameters.patientAlertEditable,
        patientId2Required: config.parameters.patientId2Required,
        minAlertTemp: numConvertFromI(localStorage.getItem("highTempThreshold")),
        patientAlertTemp: config.defaults.patientAlertTemp
    };

    return (
        <Create
            id="patientCreate" {...props}
            sx={{ mt: { sm: '-1em' }, mb: { xs: '80px', sm: 1 } }}
            title="resources.patient.addpatient"
            redirect='show'
        >
            <SimpleForm
                id="patientCreateForm"
                toolbar={<PatientCreateToolbar  {...props} />}
            >
                <TextInput
                    id="hospitalCode"
                    label="resources.hospital.hospitalid"
                    source="tenantShortName"
                    defaultValue={getActiveHospitalShortName()}
                    disabled
                />
                <br />
                <TextInput
                    id="patientCode"
                    label="resources.patient.patientidlabel"
                    source="patientCode"
                    validate={[required(), validateFields(getActiveHospitalId())]}
                    helperText={translate('resources.patient.patientidrequired')}
                />
                <br />
                <PasswordInput
                    id="patientPassword"
                    validate={validatePassword('ppw', config, translate)}
                    defaultValue=""
                    label="resources.patient.patientpassword"
                    source="patientPassword"
                    helperText={translate('resources.patient.passwordhelp')}
                    sx={{ maxWidth: '195px' }}
                />
                <br />
                {inputPatientId2(info, translate)}
                <br />
                {inputPatientAlertTemp(info, translate)}
                <br />
            </SimpleForm>
        </Create>
    );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatientCreate);
