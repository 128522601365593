import { Drawer, SwipeableDrawer } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import { useSidebarState } from 'react-admin';
import { connect } from 'react-redux';
import Responsive from '../ui/Responsive';

const size = 240;
const closedSize = 50;


const styles = makeStyles(theme =>
    createStyles({
        drawerPaper: {
            position: 'relative',
            height: 'auto',
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#182F46',
            marginTop: '24px',
            borderRight: 'none',
            [theme.breakpoints.only('xs')]: {
                marginTop: 0,
                height: '100vh',
                position: 'inherit',
                backgroundColor: "#182F46"
            },
            [theme.breakpoints.up('md')]: {
                border: 'none',
                marginTop: '1.5em',
            },
        },
    })
)

const Sidebar = (props) => {
    const { children, ...rest } = props;
    const classes = styles();
    const [sidebarOpen, setSidebarVisibility] = useSidebarState();
    const toggleSidebar = () => setSidebarVisibility(!sidebarOpen);

    return (
        <Responsive
            xsmall={
                <SwipeableDrawer
                    anchor="left"
                    open={sidebarOpen}
                    onOpen={toggleSidebar}
                    onClose={toggleSidebar}
                    PaperProps={{
                        className: classes.drawerPaper,
                        style: { width: size },
                    }}
                    disableSwipeToOpen
                    disableDiscovery
                    {...rest}
                >
                    {cloneElement(Children.only(children), { dense: "true", })}
                </SwipeableDrawer>
            }
            medium={
                <Drawer
                    variant="permanent"
                    open={sidebarOpen}
                    PaperProps={{
                        className: classes.drawerPaper,
                        style: {
                            width: sidebarOpen ? size : closedSize,
                        },
                    }}
                    style={{ marginTop: '-15px' }}
                    onClose={toggleSidebar}
                    {...rest}
                >
                    {cloneElement(Children.only(children), { dense: "true" })}
                </Drawer>
            }
        />
    );
}


Sidebar.propTypes = {
    children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({ locale: state.locale }); // force redraw on locale change
const mapDispatchToProps = {}; // to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);