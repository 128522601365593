import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './en';
import frenchMessages from './fr';
import germanMessages from './de';
import spanishMessages from './es';

const i18nProvider =  polyglotI18nProvider(locale =>
    locale === 'fr' ? frenchMessages : locale === 'de' ? germanMessages : locale === 'es' ? spanishMessages : englishMessages,
    'en' // Default locale
);

export default i18nProvider;