import React from 'react';
import { connect } from 'react-redux';
import {
	BooleanInput,
	Create,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useRedirect,
	useRefresh
} from 'react-admin';
import BackButton from '../ui/BackButton';
import SaveIcon from '@mui/icons-material/Save'
import { getActiveHospitalShortName } from "../hospitals/ActiveHospital";

const EntityCreateToolbar = (props) => {
	const refresh = useRefresh();
	const redirect = useRedirect();
	return (
		<Toolbar {...props}>
			<SaveButton
				id="save_button"
				alwaysEnable
				submitonenter="true"
				icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
				mutationOptions={{
					onSuccess: (response) => {
						refresh();
						redirect(`show`, 'gateways', response.id);
					}
				}}
				type="button"
			/>
			<BackButton
				goBack={() => redirect('list', 'gateways')}
			/>
		</Toolbar>
	)
};

const GatewayCreate = (props) => {
	return (
		<Create sx={{ mt: { sm: '-1em' } }} title="gateways.add" {...props}>
			<SimpleForm toolbar={<EntityCreateToolbar />}  >
				<TextInput
					id="hospitalCode"
					label="resources.hospital.hospitalid"
					source="tenantId"
					defaultValue={getActiveHospitalShortName()}
					disabled
				/>
				<br />
				<TextInput id="code" validate={required()} source="gatewaycode" label="gateways.id" />
				<br />
				<TextInput id="name" source="name" label="gateways.name" />
				<br />
				<TextInput id="location" source="location" label="gateways.location" />
				<br />
				<BooleanInput id="active" label="user.create.active" source="active" defaultValue />
			</SimpleForm>
		</Create>
	);
}


const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital, config: state.config });
const mapDispatchToProps = {}; // to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(GatewayCreate);