import {NumberInput, required, TextInput} from "react-admin";
import React from "react";
import {curTempSetting} from "../custom/settings/displayOptions";
import {numConvertFromC, numConvertFromI, numConvertToC} from "../tempscale/tempConvert";

const isPatientId2Required = (state) => state && state.patientId2Required;
const inputPatientId2Required = (translate) => <TextInput name="patientName" id="patientName" label="resources.patient.patientid2label" source="patientName" validate={required(translate('resources.patient.patientid2required'))} helperText={`* ${translate('ra.validation.required')}`} />;
const inputPatientId2Optional = (translate) => {
  return (
    <TextInput name="patientName" id="patientName" label="resources.patient.patientid2label" source="patientName" helperText={translate("resources.patient.patientid2helperoptional")}/>
  );
}

const inputPatientId2 = (state, translate) => isPatientId2Required(state) ? inputPatientId2Required(translate) : inputPatientId2Optional(translate);

const validateTemperature = (state, translate) => (actualTemp) => {
  const min = numConvertFromC(state.minAlertTemp, curTempSetting());
  const max = numConvertFromC(40.5, curTempSetting());
  actualTemp = numConvertFromC(numConvertFromI(actualTemp), curTempSetting())
  if (actualTemp < min || actualTemp > max) {
    return translate('resources.patient.patientalerttemphelper', {min: numConvertFromC(state.minAlertTemp, curTempSetting()), max: numConvertFromC(40.5, curTempSetting())});
  }
  return undefined
}

const inputPatientAlertTemp = (state, translate) => state &&
  <NumberInput disabled={state && !state.patientAlertEditable}
               id="alertTemp"
               name="alertTemp"
               options={{ maximumFractionDigits: 1 }}
               label={translate('resources.patient.patientalerttemplabel', {scale: curTempSetting()})}
               defaultValue={state.minAlertTemp * 100}
               source="alertTemp"
               format={v => isNaN(v) ? v : numConvertFromC(numConvertFromI(v), curTempSetting()).toString()}
               parse={v => isNaN(v) ? v : numConvertToC(v, curTempSetting()) * 100}
               validate={validateTemperature(state, translate)}
               helperText={translate('resources.patient.patientalerttemphelper', {min: numConvertFromC(state.minAlertTemp, curTempSetting()), max: numConvertFromC(40.5, curTempSetting())})}
               onChange={validateTemperature(state, translate)}
  />;

export { inputPatientId2, inputPatientAlertTemp };