import React from 'react';
import {useRecordContext, useTranslate} from 'react-admin';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import { changeHospital } from './hospitalActions'
import {
    setActiveHospitalId,
    setActiveHospitalHierarchy,
    setActiveHospitalShortName
} from './ActiveHospital';
import GradeIcon from '@mui/icons-material/Grade';
import ListItemIcon from "@mui/material/ListItemIcon";
import customDataProvider from "../dataProvider/customDataProvider";

const ActivateButton = (props) => {
    const translate = useTranslate();
    const thisHospital = useRecordContext();

    const handleClick = (event) => {
        const { changeHospital } = props;
        if (event.ctrlKey) {
            window.open('?hospitalCode=' + thisHospital.hospitalCode, '_blank');
        } else {
            localStorage.setItem('isAdmin', 'false');
            localStorage.setItem('isSysAdmin', 'false');
            localStorage.setItem('isClinician', 'false');

            customDataProvider.getUserInfo()
                .then(userInfo => {
                    if (userInfo.response && userInfo.response.roles) {
                        userInfo.response.roles.forEach((role) => {
                            if (role.tenantId === thisHospital.id ||
                                thisHospital.hierarchy.indexOf('/' + role.tenantId + '/') > 0 ||
                            role.tenantId === 1) {
                                if (role.role === "TENANT_ADMIN") {
                                    localStorage.setItem("isAdmin", "true");
                                }
                                if (role.role === "SYSADMIN") {
                                    localStorage.setItem("isSysAdmin", "true");
                                }
                                if (role.role === "CLINICIAN") {
                                    localStorage.setItem("isClinician", "true");
                                }
                            }
                        });
                    }
                    changeHospital(thisHospital);

                    setActiveHospitalId(thisHospital.id);
                    setActiveHospitalShortName(thisHospital.hospitalCode);
                    setActiveHospitalHierarchy(thisHospital.fullHierarchy);

                    localStorage.setItem("adjustForOral", thisHospital.adjustForOral);
                    localStorage.setItem("highTempThreshold", thisHospital.highTempThreshold);
                    localStorage.setItem("lowTempThreshold", thisHospital.lowTempThreshold);

                    //Only redirect them if they have permissions to view patients
                    if (localStorage.getItem("isSysAdmin") === "true" || localStorage.getItem("isClinician") === "true") {
                        window.location.replace('/');
                    } else {
                        window.location.reload();
                    }
                })
        }
        event.stopPropagation();
    };

    const { hospital } = props;
    if (hospital == null ||
        thisHospital.id !== hospital.id) {
        const buttonId = "ACTIVATE_" + thisHospital.hospitalCode;
        return (
            <Button
                id={buttonId}
                style={{ marginRight: '1em' }}
                color="primary"
                variant='contained'
                onClick={(e) => handleClick(e)}
            >
                {translate('resources.hospital.activate')}
            </Button>
        )
    } else {
        return (
            <span>
                <ListItemIcon id="active" style={{ marginTop: '5px', height: '20px', width: '20px' }}>
                    <GradeIcon style={{ height: '20px', width: '20px' }} fontSize="small" />
                    {translate('resources.hospital.active')}
                </ListItemIcon>
            </span>
        )
    }

}

const mapStateToProps = state => ({ hospital: state.hospital });
export default connect(mapStateToProps, { changeHospital })(ActivateButton);