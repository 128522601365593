import frenchMessages from 'ra-language-french';

export default {
    null: 'Cet élément ne dispose pas de traduction {NULL}',
    simple: {
        action: {
            close: 'Fermer',
            resetViews: 'Réinitialiser les affichages',
        },
        'create-post': 'Nouvelle publication',
    },
    ...frenchMessages,
    misc: {
        temperatureoral: 'Température orale équivalente',
        temperatureaxillary: 'Température axillaire',
    },
    resources: {
        auth: {
            passwordreset: 'Les instructions de réinitialisation de mot de passe ont été envoyées à %{email}',
            emailrequired: 'L’adresse de messagerie est nécessaire',
            invalid: 'Nom d’utilisateur ou mot de passe non valide'
        },
        misc: {
            ascending: 'Croissant',
            date: 'Date',
            datetime: 'Date/Heure',
            descending: 'Décroissant',
            detail: 'Détail',
            export: 'Exporter',
            id: 'ID',
            lastupdated: 'Dernière mise à jour',
            loadingdata: 'Chargement de données…',
            notviewinginwebview: 'Ne s’affiche pas en mode Web',
            optional: 'Facultatif',
            ok: 'OK',
            processingdata: 'Traitement des données...',
            status: 'Statut',
            sortby: 'Trier par',
            summary: 'Résumé',
            default: 'Défaut',
            error: 'Erreur lors de l’extraction des données',
            nodata: 'Aucune donnée disponible',
            allowed: 'Caractères autorisés : a-z A-Z 0-9 . -'
        },
        hospital: {
            highTempThreshold: 'Seuil de température élevée (°%{scale})',
            lowTempThreshold: 'Seuil de température basse (°%{scale})',
            adjustfororal: 'Ajuster pour la température orale',
            hospitalalerttemphelper: 'Doit être inférieure au seuil de température élevée',
            label: 'Unité',
            hospitalid: 'ID unité',
            group: 'Groupe',
            parentLabel: 'ID unité Parent',
            add: 'Ajouter l’unité',
            name: 'Nom de l’unité',
            activepats: 'Patients actifs',
            completepats: 'Patients terminés',
            unstartedpats: 'Patients non commencés',
            unstarted: 'Non commencé',
            active: 'Actif',
            activate: 'Activer',
            list: 'Unités',
            create: 'Créer une unité',
            show: {
                edit: 'Modifier l’unité',
                hierarchy: 'Hiérarchie des unités',
                config: 'Configuration',
                integrations: 'Intégrations',
                hospitalconfigure: 'Configurations clinicien',
                patientconfigure: 'Configurations patient',
                summary: 'Résumé',
                stats: 'Statistiques',
            }
        },
        patient: {
            activehospitalonly: 'unité active uniquement',
            activepatientsonly: 'Patients actifs',
            addpatient: 'Ajouter Patient',
            alerttemp: 'Alarme de température',
            dailytmax: 'TMax quotidienne',
            duplicateId: 'L’ID patient doit être unique',
            editpatient: 'Modifier Patient',
            exportcsv: 'Exporter toutes les données patient (CSV)',
            kitid: 'ID kit',
            kitpassword: 'Mot de passe du kit',
            kitpasswordrequired: 'Le mot de passe du kit est obligatoire',
            kitidrequired: 'L’ID du kit est obligatoire',
            label: 'Patient',
            passwordhelp: 'Obligatoire pour une utilisation ambulatoire',
            patientid2label: 'ID patient 2',
            patientid2required: 'ID patient 2 est obligatoire',
            patientid2helperrequired: '* Obligatoire',
            patientid2helperoptional: 'Facultatif',
            patientalerttemplabel: 'Alarme de température (°%{scale})',
            patientalerttemphelper: 'La plage valide est %{min} - %{max}',
            patientidlabel: 'ID patient',
            patientidrequired: 'ID patient est obligatoire',
            patientlist: 'Patients',
            patientpassword: 'Mot de passe Patient',
            showcredentials: 'Identifiants',
            credentialstitle: 'Identifiants patient',
            showinactivepatients: 'afficher les patients inactifs',
            temperature: 'Température',
            transfer: 'Transférer',
            transferkit: 'Transférer kit',
            transferpatient: 'Transférer le kit patient à %{hospitalCode}',
            transfersubmit: 'SOUMETTRE',
            transfersourcehospital: 'ID Groupe',
            passwordincorrect: 'Mot de passe incorrect',
            started: 'Commencé'
        },
        patch: {
            addpatch: 'Ajouter Timbre',
            multiple: 'Ajouter plusieurs timbres',
            completed: 'Terminé',
            current: 'Le plus récent',
            deletepatch: 'Supprimer Timbre ?',
            high: 'Élevé',
            label: 'Timbre',
            labelplural: 'Timbres',
            nopatchesadded: 'Aucun timbre ajouté',
            norecentdata: 'Aucune donnée récente',
            nodata: 'Aucune donnée',
            nodatafor: 'Aucune donnée pour',
            patchactive: 'Timbre actif',
            patchid: 'ID timbre',
            replacepatch: 'Remplacer Timbre',
            scanpatch: 'Scanner Timbre',
            starttime: 'Heure de début',
            timeremaining: 'Temps restant',
            unusedpatch: 'Timbre inutilisé',
            graph: 'Diagramme',
            summary: 'Résumé',
            history: 'Historique',
            export: 'Exporter',
            exportcsv: 'Exporter les données d’un seul timbre (CSV)',
            NPA: 'Aucun timbre assigné',
            NOS: 'Timbre non commencé',
            THI: 'Él',
            TLO: 'Ba',
            invalid: 'ID timbre non valide',
            assigned: 'Le timbre est déjà assigné à un patient',
            scan: 'Aucune donnée de scannage à visualiser',
            isrequired: 'L’ID du timbre est obligatoire',
            patchisadded: 'Un timbre a été ajouté'
        },
        password: {
            containnumber: 'Le mot de passe doit contenir un nombre',
            lowercaseletter: 'Le mot de passe doit contenir au moins 1 lettre minuscule',
            uppercaseletter: 'Le mot de passe doit contenir au moins 1 lettre majuscule',
            requiredlength: 'Le mot de passe ne respecte pas la longueur requise de %{length} caractères',
            specialcharacter: 'Le mot de passe doit contenir au moins 1 caractère spécial'
        },
    },
    user: {
        roles: {
            tenantadmin: 'Administrateur unité',
            clinician: 'Clinicien',
            sysadmin: 'Administrateur global'
        },
        detail: 'Utilisateur',
        create: {
            add: 'Ajouter Utilisateur',
            admin: 'Administrateur de l’unité',
            clinician: 'Clinicien de l’unité',
            fname: 'Prénom',
            lname: 'Nom',
            active: 'Statut actif',
            email: 'Courriel',
            role: 'L’utilisateur doit avoir au moins un rôle'
        },
        list: {
            name: 'Nom',
            role: 'Rôle',
            label: 'Utilisateurs',
            search: 'Rechercher',
        },
        form: {
            summary: 'Résumé',
            security: 'Sécurité',
        },
        edit: {
            label: 'Modifier Utilisateur',
            title: 'Utilisateur "%{title}"',
        },
        action: {
            save_and_add: 'Enregistrer et ajouter',
            save_and_show: 'Enregistrer et afficher',
        },
    },
    login: {
        title: {
            cell360: 'Application Web Cell Therapy 360',
            temptraq: 'Application Web clinicienne TempTraq'
        },
        forgot: {
            link: 'Informations d’identification oubliées',
            note: 'Remarque : une fois que vous aurez reçu un courriel à l’adresse indiquée, veuillez suivre le lien contenu dans le courriel pour réinitialiser votre mot de passe.',
            submit: 'Soumettre',
            snack: 'TODO!!! Les instructions de réinitialisation de mot de passe ont été envoyées à'
        },
        client: 'Client',
        error: 'Nom d’utilisateur ou mot de passe non valide',
        email: 'Courriel'
    },
    navigation: {
        analytics: 'Analytique',
        patient: 'Patients',
        gateway: 'Passerelles',
        gatewayid: 'ID de la passerelle',
        hospital: 'Unités',
        user: 'Utilisateurs',
        config: 'Configurations',
        settings: 'Paramètres',
        network: 'Impossible de contacter le serveur. Vérifier la connectivité réseau.'
    },
    settings: {
        server: 'Serveur',
        udi: 'UDI',
        label: 'Paramètres',
        current: 'Utilisateur actuel',
        change: 'Changer le mot de passe',
        diagnostics: "Diagnostics",
        scale: 'Choisir l’échelle de température',
        ui: 'Options IU',
        alert: 'Afficher les alarmes de températures',
        timer: 'Afficher le minuteur Aucune donnée récente',
        legal: 'Mentions légales',
        privacy: 'Politique de confidentialité',
        terms: 'Conditions d’utilisation',
        example: 'Exemple',
        language: 'Options de langue',
        enabled: 'Activé'
    },
    earlyindicator: {
        title: 'Indicateur précoce'
    },
    alert: {
        soundon: 'Son activé',
        soundoff: 'Son désactivé',
        notetitle: 'Ce site voudrait émettre un son.',
        notedescr: 'Il faut activer Commentaire de l’utilisateur pour une lecture automatique.',
        generate: 'Générer une notification aléatoire',
        nonotification: 'Aucune Notification',
        clearall: 'Tout effacer',
        clear: 'Effacer',
        headerTitle: 'Alarmes',
        title: {
            default: 'a',
            alert: 'Alarme de température, %{patient}',
            err: 'Remplacer le timbre, %{patient}',
            nrd: 'Aucune donnée récente, %{patient}',
            afin: 'Timbre en fin de fonctionnement, %{patient}',
            early: 'Alarme de l’indicateur précoce, %{patient}'
        },
        description: {
            default: 'Notification reçue à %{time} température du patient %{code} et du timbre %{patchId} est au niveau d’alarme',
            alert: 'Notification reçue à %{time} température du patient %{patient} est au niveau d’alarme.',
            err: 'Alarme timbre TempTraq. Remplacer le timbre du patient %{patient}.',
            nrd: 'Aucune donnée de température reçue depuis %{time} pour le patient %{patient}. Le patient peut être trop éloigné de l’appareil récepteur, ou il y a des problèmes de connectivité réseau.',
            afin: 'Le timbre %{patch} est presque terminé. Appliquer un nouveau timbre pour poursuivre la surveillance.',
            early: 'D’après les tendances de la température, TempTraq a détecté un risque accru pour que la température du patient atteigne %{alertTemp} dans l’heure qui vient.  Il conviendrait de surveiller plus étroitement la température du patient et d’envisager des préparations adéquates lors d’une actuelle alarme de température.'
        }
    },
    acknowledge: {
        title: '',
        terms: 'Vous devez accepter les conditions d’utilisation de cet outil.',
        accept: 'J’accepte',
        acknowledge: 'Confirmation'
    },
    changepass: {
        currentpass: 'Mot de passe actuel',
        newrequired: 'Le nouveau mot de passe est requis',
        currentrequired: 'Le mot de passe actuel est requis',
        currentincorrect: 'Le mot de passe actuel est incorrect',
        meetlength: 'Le mot de passe ne respecte pas la longueur requise de %{length} caractères',
        uppercase: 'Le mot de passe doit contenir au moins 1 lettre majuscule',
        lowercase: 'Le mot de passe doit contenir au moins 1 lettre minuscule',
        number: 'Le mot de passe doit contenir un nombre',
        special: 'Le mot de passe doit contenir au moins 1 caractère spécial',
        retype: 'Veuillez saisir une deuxième fois le nouveau mot de passe',
        mustmatch: 'Les mots de passe doivent correspondre !',
        change: 'Changer le mot de passe',
        changerequired: 'Changement de mot de passe obligatoire',
        pass: 'Nouveau mot de passe',
        retypepass: 'Saisir une deuxième fois le nouveau mot de passe',
        enter: 'Saisir le nouveau mot de passe',
        note1: 'Note : vous devrez vous connecter',
        note2: 'de nouveau avec votre nouveau mot de passe'
    },
    dashboard: {
        active: 'Actif',
        total: 'Total',
        hospitals: 'Unités',
        patients: 'Patients',
        patches: 'Timbres',
        server: 'Statut serveur',
        run: 'En cours d’exécution',
        pause: 'En pause',
        seems: 'Semble arrêté',
        down: 'Arrêté',
        not: 'Pas encore vérifié',
        go: 'UptimeRobot',
        tooltip: 'Actualisation automatique : %{refresh} minutes',
        refresh: 'Intervalle de l’actualisation automatique',
        min: 'min',
        other: 'Moins de %{num}',
        group: 'Grouper les unités avec moins de'
    },
    gateways: {
        label: 'Passerelle',
        delete: 'Supprimer la passerelle ?',
        edit: 'Modifier la passerelle',
        name: 'Nom de la passerelle',
        location: 'Emplacement de la passerelle',
        id: 'ID de la passerelle',
        active: 'Unités actives uniquement',
        add: 'Ajouter la passerelle',
        list: 'Passerelles',
        inactive: 'Inactive',
        emptytitle: 'Pas encore de passerelle.',
        emptydesc: 'Aucune passerelle n’est assignée à l’unité actuellement active. '
    },
    integration: {
        endpoint: 'Point de terminaison',
        pipeline: 'Pipeline',
        name: 'Nom',
        edit: 'Modifier intégration',
        add: 'Ajouter intégration',
        list: 'Liste intégration',
        delete: 'Supprimer intégration ?',
        detail: 'Détail intégration'
    },
    diagnostic: {
        title: 'Performances timbre',
        voltb: 'Pile volt',
        volts: 'Capteur volt',
        error: 'Erreurs',
    },
    config: {
        type: 'Type',
        inherit: 'Configuration héritée',
        value: 'Valeur'
    }
};
