import { fetchUtils, resolveBrowserLocale } from 'react-admin';
import serverConfig from '../custom/serverConfig';
import { authorization } from "./authProvider";
import _ from 'lodash';

const getHeaders = (type, auth, accept) => {
    const locale = JSON.parse(localStorage.getItem('RaStore.locale')) || resolveBrowserLocale();

    let headers = {}
    headers = new Headers();
    !!accept && headers.set('Accept', 'application/json');
    !!type && headers.set('Content-Type', type);
    !!auth && headers.set('Authorization', auth);
    headers.set('Accept-Language', locale);
    return headers;
};

const httpClient = (apiUrl) => {
    const options = {}
    options.headers = getHeaders('application/json', authorization(), 'application/json')
    return fetchUtils.fetchJson(apiUrl, options)
};

export default {
    getActiveHospitals: () => {
        return Promise.resolve()
    },

    csvPeakHistory: (hospitalCode, patientCode, tempScale) => {
        return {};
    },

    csvExportHistory: (query) => {
        let id = JSON.parse(query).id;
        let patchId = JSON.parse(query).patchID;
        const tempScale = JSON.parse(query).scale;

        if (patchId) {
            const url = serverConfig.endpoint + '/rest/v1/patches/' + id + '/export?temperatureScale=' + tempScale

            return fetch(url, {
                mode: 'cors',
                headers: getHeaders('application/octet-stream', authorization())
            }).then(function (response) {
                return Promise.resolve(response.blob())
            })
        } else {
            const url = serverConfig.endpoint + '/rest/v1/patients/' + id + '/export?temperatureScale=' + tempScale
            return fetch(url, {
                mode: 'cors',
                headers: getHeaders('application/octet-stream', authorization())
            }).then(function (response) {
                return Promise.resolve(response.blob())
            })
        }
    },

    getConfigurationDefinition: (type, value) => {
        const queryString = type && type.length > 0 ? JSON.stringify({ [type]: value }) : "";

        let url = serverConfig.endpoint + '/rest/v1/configurations/definitions?filter=' + queryString;

        return httpClient(`${url}`)
            .then(({ json }) => ({
                data: json
            }))
    },

    getHistory: (id) => {
        let url = serverConfig.endpoint + '/rest/v1/patches/' + id + '/history';
        let temps = {
            history: []
        };

        return httpClient(url).then(response => {
            let { json } = response;
            let returnArray = {
                data: []
            };

            if (json) {
                if (Array.isArray(json)) {
                    _.forEach(json, function (v) {
                        let temp = {
                            'timeIndex': v.timeIndex,
                            'temperatureC': v.temperatureC,
                            'measurementTime': v.measurementTime,
                            'id': v.id
                        }
                        temps.history.push(temp);
                    });
                }
            }

            returnArray.data.push(temps);
            return returnArray.data;
        })
    },

    getPatchStatus: (hospitalCode, patchID, patientId) => {
        if (patientId) {
            let url = serverConfig.endpoint + '/rest/v1/patients/' + patientId + '/patchstatus'

            if (patchID) {
                url += '/' + patchID;
            }

            return httpClient(`${url}`).then(function (response) {
                return Promise.resolve({ data: response.json });
            })
        } else {
            return Promise.resolve({ data: {} });
        }
    },

    getHospitalSummary: (query) => {
        let tenantId = parseInt(query);
        let url;
        if (isNaN(tenantId)) {
            const queryString = JSON.stringify({ "TENANTCODE": query });
            url = serverConfig.endpoint + '/rest/v1/tenants?filter=' + queryString;
            return httpClient(`${url}`).then(function (response) {
                let hospital = JSON.parse(response.body)[0];
                hospital.hospitalCode = hospital.groupcode;
                hospital.hospitalName = hospital.name;

                return Promise.resolve({ data: hospital });
            })
        } else {
            url = serverConfig.endpoint + '/rest/v1/tenants/' + query;
            return httpClient(`${url}`).then(function (response) {
                let hospital = JSON.parse(response.body);
                hospital.hospitalCode = hospital.groupcode;
                hospital.hospitalName = hospital.name;

                return Promise.resolve({ data: hospital });
            })
        }
    },

    acknowledgeTerms: (termsAck, hospitalId) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/acceptterms/' + hospitalId;

        return fetchUtils.fetchJson(`${url}`, {
            method: 'PUT',
            headers: getHeaders('application/json', authorization()),
            body: JSON.stringify(termsAck)
        }).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    getPatchPerformance: (patchName) => {
        const url = serverConfig.endpoint + '/rest/v1/diagnostics/patchperformance/' + patchName;
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    getAlertsByTenant: (hospitalId, sort, dir) => {
        const url = serverConfig.endpoint + `/rest/v1/alerts/tenant/${hospitalId}?sort=["${sort}","${dir}"]`
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    getAlertsByPatient: (patientId) => {
        const url = serverConfig.endpoint + '/rest/v1/alerts/patient/' + patientId;
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    clearAlerts: (patientId) => {
        const url = serverConfig.endpoint + '/rest/v1/alerts/patients/' + patientId + '/clearalerts';
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    clearAlert: (alertId) => {
        const url = serverConfig.endpoint + '/rest/v1/alerts/' + alertId + '/clearalert';
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    getIntegrations: (hospitalId) => {
        const url = serverConfig.endpoint + '/rest/v1/tenants/' + hospitalId + '/integrations';
        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    changeUserPassword: (oldPassword, newPassword) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/changePassword'

        return fetchUtils.fetchJson(`${url}`, {
            method: 'POST',
            headers: getHeaders('application/json', authorization()),
            body: JSON.stringify({ "oldPassword": oldPassword, "newPassword": newPassword, "username": localStorage.getItem("username") })
        }).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    resetUserPassword: (password, token, username) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/resetPassword'

        return fetchUtils.fetchJson(`${url}`, {
            method: 'POST',
            body: JSON.stringify({ "password": password, "token": token, "username": username })
        }).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    passwordReset: (email) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/remindme'
        return fetchUtils.fetchJson(`${url}`, {
            method: 'POST',
            headers: getHeaders('application/json'),
            body: JSON.stringify({ "email": email, "resetLink": window.location.origin + "/#/resetPassword" })
        }).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    checkPatientLogin: (password, patientCode, hospitalCode) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/patient/login'

        return fetchUtils.fetchJson(`${url}`, {
            method: 'POST',
            headers: getHeaders('application/json'),
            body: JSON.stringify({ "patientCode": patientCode, "hospitalCode": hospitalCode, "password": password })
        }).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    checkLogin: (password) => {
        const url = serverConfig.endpoint + '/rest/v1/auth/login'
        return fetchUtils.fetchJson(`${url}`, {
            method: 'POST',
            headers: getHeaders('application/json'),
            body: JSON.stringify({ "username": localStorage.getItem("username"), "password": password })
        }).then(function (response) {
            return Promise.resolve({ json: () => response });
        })
    },

    getVersion: () => {
        const url = serverConfig.endpoint + '/rest/v1/version'
        return fetchUtils.fetchJson(`${url}`, {
            method: 'GET',
            headers: getHeaders('application/json', serverConfig.authSecret)
        }).then(({ json }) => {
            json.full = json.major + '.' + json.minor + '.' + json.patch + '.' + json.build;

            return { data: json }
        })
        // return Promise.resolve({json: () => version});
    },

    getHospitalConfig: (hospitalCode, application) => {
        let url;
        let applicationType = application ? `?applicationType=${application}` : '';

        url = serverConfig.endpoint + '/rest/v1/tenants/' + hospitalCode + '/configuration' + applicationType;

        return httpClient(`${url}`).then(function (response) {
            let hospital;

            hospital = JSON.parse(response.body);

            return Promise.resolve({ data: (hospital) });
        })
    },

    movePatient: (patientMoveData) => {
        const url = serverConfig.endpoint + '/rest/v1/patients/moveFrom'

        return fetchUtils.fetchJson(`${url}`, {
            method: 'PUT',
            headers: getHeaders('application/json', authorization()),
            body: patientMoveData
        }).then(function (response) {
            return Promise.resolve(JSON.parse(response.body));
        })
    },

    getUserInfo: () => {
        let url = serverConfig.endpoint + '/rest/v1/auth/whoami';

        return httpClient(`${url}`)
            .then(function (response) {
                return Promise.resolve({ response: response.json.user })
            })
    },

    getPatchAssignment: (patchId) => {
        let url = serverConfig.endpoint + '/rest/v1/patients/' + patchId + '/assignment';

        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve({ assignment: (response) })
        })
    },

    getPatientCredentials: (patient) => {
        let url = serverConfig.endpoint + '/rest/v1/patients/' + patient.id + '/credentials';

        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve({ credentials: (response) })
        })
    },

    getPatientExists: (hospitalId, patientCode) => {
        let url = serverConfig.endpoint + '/rest/v1/patients/exists/' + hospitalId + '/' + patientCode;

        return httpClient(`${url}`).then(function (response) {
            return Promise.resolve({ exists: (response.body) })
        })
    }
};