import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import ActionDelete from '@mui/icons-material/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { Button, Confirm, useDelete } from 'react-admin';
import withTranslate from "../hoc/withTranslate";
import withRecordContext from "../hoc/withRecordContext";
import withRefresh from "../hoc/withRefresh";
import withRedirect from "../hoc/withRedirect";

const sanitizeRestProps = ({
                               basePath,
                               classes,
                               filterValues,
                               handleSubmit,
                               handleSubmitWithRedirect,
                               invalid,
                               label,
                               pristine,
                               resource,
                               saving,
                               selectedIds,
                               type,
                               ...rest
                           }) => rest;

const styles = theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse configurations
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });

const DeleteConfigurationButton = (props) => {
    const {
        classes = {},
        className,
        icon,
        onClick,
        record,
        resource,
        translate,
        refresh,
        redirect,
        ...rest
    } = this.props;

    const [isOpen, setIsOpen] = useState(false);
    const [deleteOne] = useDelete(
        'configurations',
        { id: record.id, previousData: record },
        {
            onSuccess: (data) => {
                refresh();
                redirect('list', 'configurations');
            }
        }
    );

    const handleClick = e => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const handleDialogClose = e => {
        e.stopPropagation();
        this.setState({ isOpen: false });
    };

    const handleDelete = e => {
        e.stopPropagation();
        deleteOne();
    };

        return (
            <Fragment>
                <Button
                    onClick={handleClick}
                    id="deleteButton"
                    label={'ra.action.delete'}
                    className={classnames(
                        'ra-delete-button',
                        classes.deleteButton,
                        className
                    )}
                    key="button"
                    {...sanitizeRestProps(rest)}
                >
                    {icon}
                </Button>
                <Confirm
                    isOpen={isOpen}
                    title="Delete Configuration?"
                    content="ra.message.delete_content"
                    translateOptions={{
                        name: inflection.humanize(
                            translate(`resources.${resource}.name`, {
                                smart_count: 1,
                                _: inflection.singularize(resource),
                            }),
                            true
                        ),
                        id: record.id,
                    }}
                    onConfirm={handleDelete}
                    onClose={handleDialogClose}
                />
            </Fragment>
        );
}

DeleteConfigurationButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.func,
    refresh: PropTypes.func,
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
    icon: PropTypes.element,
};

DeleteConfigurationButton.defaultProps = {
    icon: <ActionDelete style={{ height: '20px', width: '20px' }} />,
};

export default compose(
    withTranslate,
    withRedirect,
    withRefresh,
    withRecordContext,
    withStyles(styles)
)(DeleteConfigurationButton);