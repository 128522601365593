import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Title, useRedirect, useTranslate } from 'react-admin';
import { changeTemp, changeDisplayAlertSetting, changeDisplayNRDTimer } from './action';
import SettingsIcon from '@mui/icons-material/Settings';
import { stringConvertFromC } from '../../tempscale/tempConvert';
import { setTempSetting, setDisplayAlertSetting, setDisplayNRDTimer } from './displayOptions';
import authProvider from '../../dataProvider/authProvider';
import { AUTH_LOGOUT } from 'react-admin';
import customDataProvider from "../../dataProvider/customDataProvider";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LanguageMenu } from '../../i18n/languageMenu';

export const configureIcon = SettingsIcon;

const DisplayOptionsPage = (props) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const [currentUser, setCurrentUser] = useState(null);
    const isSysadmin = localStorage.getItem('isSysAdmin');
    const isClinician = localStorage.getItem('isClinician');

    useEffect(() => {
        let isMounted = true;

        customDataProvider.getUserInfo()
            .then(response => {
                if (isMounted) {
                    setCurrentUser(response.response);
                }
            });
        return () => {
            isMounted = false;
        };
    }, []);

    const { temp, changeTemp, displayAlertTemps, displayNRDTimer, changeDisplayNRDTimer, changeDisplayAlertSetting } = props;
    const tempChange = unit => {
        changeTemp(unit);
        setTempSetting(unit);
    };
    const logoutUser = () => {
        authProvider(AUTH_LOGOUT);
        redirect('/login');
    }

    return (
        <Card>
            <Title id="configureTitle" title="settings.label" />
            {currentUser &&
                <CardContent>
                    <div id="user_label">
                        <b>{translate('settings.current')}:</b>
                    </div>
                    <span id="currentUserText" style={{ paddingLeft: '10px' }}> {currentUser.username} </span>
                    <br />
                    <Button
                        id="logout"
                        variant="outlined"
                        style={{
                            margin: '10px 10px 10px 10px',
                            background: '#aaa',
                            color: '#000'
                        }}
                        onClick={() => logoutUser()}>
                        {translate('ra.auth.logout')}
                    </Button>
                    <Button
                        id="change_password"
                        variant="outlined"
                        style={{
                            margin: '10px 10px 10px 10px',
                            background: '#aaa',
                            color: '#000'
                        }}
                        onClick={() => redirect('/changePassword?page=displayOptions')}>
                        {translate('settings.change')}
                    </Button>
                </CardContent>
            }
            <CardContent>
                <div id="scale_label">
                    <b>{translate('settings.scale')}:</b>
                </div>
                <Button
                    id="c"
                    variant="outlined"
                    style={{
                        margin: '10px 10px 10px 10px',
                        background: '#aaa',
                        color: temp === 'C' ? '#FFF' : '#000'
                    }}
                    onClick={() => tempChange('C')}>
                    °C
                </Button>
                <Button
                    id="f"
                    variant="outlined"
                    style={{
                        margin: '10px 10px 10px 10px',
                        background: '#aaa',
                        color: temp === 'F' ? '#FFF' : '#000'
                    }}
                    onClick={() => tempChange('F')}>
                    °F
                </Button>
                <span id="tempExample">{translate('settings.example')}: {stringConvertFromC(38, temp)}</span>
            </CardContent>
            {(isSysadmin === 'true' || isClinician === 'true') &&
                <CardContent>
                    <div id="ui_label">
                        <b>{translate('settings.ui')}:</b>
                    </div>
                    <div>
                        <FormControlLabel
                            id="alert_temperatures"
                            style={{ paddingRight: '10px', marginLeft: '20px' }}
                            control={<Checkbox
                                name="optionAlertTemps"
                                color="default"
                                icon={<CheckBoxOutlineBlankIcon style={{ height: '24px', width: '24px' }} />}
                                checkedIcon={<CheckBoxIcon style={{ height: '24px', width: '24px' }} />}
                                checked={displayAlertTemps === 'true' || displayAlertTemps}
                            />}
                            label={translate('settings.alert')}
                            labelPlacement="end"
                            onChange={e => { setDisplayAlertSetting(e.target.checked); changeDisplayAlertSetting(e.target.checked); }}
                        />
                    </div>
                    <div>
                        <FormControlLabel
                            id="data_timer"
                            style={{ paddingRight: '10px', marginLeft: '20px' }}
                            control={<Checkbox
                                name="optionDisplayNRDTimer"
                                color="default"
                                icon={<CheckBoxOutlineBlankIcon style={{ height: '24px', width: '24px' }} />}
                                checkedIcon={<CheckBoxIcon style={{ height: '24px', width: '24px' }} />}
                                checked={displayNRDTimer === 'true' || displayNRDTimer}
                            />}
                            label={translate('settings.timer')}
                            labelPlacement="end"
                            onChange={e => { setDisplayNRDTimer(e.target.checked); changeDisplayNRDTimer(e.target.checked); }}
                        />
                    </div>
                </CardContent>
            }
            <CardContent>
                <div id="language_label">
                    <b>{translate('settings.language')}:</b>
                </div>
                <LanguageMenu />
            </CardContent>
        </Card>
    );
}

const mapStateToProps = state => ({
    temp: state.temp,
    displayAlertTemps: state.displayAlertTemps,
    displayNRDTimer: state.displayNRDTimer
});

export default connect(
    mapStateToProps,
    {
        changeTemp,
        changeDisplayAlertSetting,
        changeDisplayNRDTimer
    }
)(DisplayOptionsPage);
