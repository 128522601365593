import React, { useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import "./styles/TimeRemaining.css"

export const shouldShowTimeRemaining = ({ statusCode, endTime }) => {
  if (['NOS', 'ERR', 'NPA'].includes(statusCode)) {
    return false;
  }

  if (statusCode === 'FIN') {
    const msPerSec = 1000;
    const secPerMin = 60;
    const minutesSinceCompletion = ((new Date().getTime() - endTime) / msPerSec) / secPerMin;
    if (minutesSinceCompletion > 240) {
      return false;
    }
  }
  return true;
}

const TimeRemaining = (props) => {
  const [result, setResult] = React.useState(props.record && props.record.patchStatus);
  const [runTimeSec, setRunTimeSec] = React.useState(props.record && props.record.patchStatus && Number(props.record.patchStatus.runTime));
  const [timeRemaining, setTimeRemaining] = React.useState(
    props.record &&
    props.record.patchStatus &&
    Number(props.record.patchStatus.endTime - new Date().getTime()) / 1000
  );

  React.useEffect(() => {
    setResult((props.record && props.record.patchStatus));
    setRunTimeSec(props.record && props.record.patchStatus && Number(props.record.patchStatus.runTime));
    setTimeRemaining(
      props.record &&
      props.record.patchStatus &&
      Number(props.record.patchStatus.endTime - new Date().getTime()) / 1000
    );
  }, [props]);

  const timerRef = React.useRef(null);
  const componentMounted = useRef(true);
  const refresh = 60 * 1000;
  const msPerSec = 1000;
  const secPerMin = 60;
  const minPerHr = 60;
  const secPerHr = secPerMin * minPerHr;

  const updateTimeRemaining = () => {
    if (_.isEmpty(result)) {
      return;
    }

    clearTimeout(timerRef.current);
    let runTimeSec = Number(result.runTime);
    let timeRemainingSec = Number(result.endTime - new Date().getTime()) / 1000;

    if (timeRemainingSec < 0 && result.statusCode !== 'NPA' && result.statusCode !== 'NOS' && result.statusCode !== 'ERR') {
      let data = result;
      data.statusCode = 'FIN';
      setResult(data);
      clearTimeout(timerRef.current);

      if (!shouldShowTimeRemaining({ statusCode: result.statusCode, endTime: result.endTime })) {
        return;
      }
    }
    setTimeRemaining(timeRemainingSec);
    setRunTimeSec(runTimeSec);

    if (componentMounted.current && shouldShowTimeRemaining({ statusCode: result.statusCode, endTime: result.endTime })) {
      timerRef.current = setTimeout(updateTimeRemaining, refresh);
    }
  };

  React.useEffect(() => {
    if (componentMounted.current) {
      updateTimeRemaining();
    }
    return () => {
      clearTimeout(timerRef.current);
      componentMounted.current = false;
    }
  }, []); // eslint-disable-line

  if (!result || !shouldShowTimeRemaining({ statusCode: result.statusCode, endTime: result.endTime })) {
    return <span id="timeRemainingSpan" className="timeRemaining" />;
  } else if (result.statusCode === 'FIN') {
    const minutesSinceCompletion = ((new Date().getTime() - result.endTime) / msPerSec) / secPerMin;

    const h = Math.floor(minutesSinceCompletion / minPerHr);
    const m = Math.floor(minutesSinceCompletion % minPerHr);

    return <div className="timeRemaining" style={{ paddingTop: '4px' }}>
      <b id="timeRemainingBoldFin" style={{ color: 'red' }}>{'(' + h + 'h ' + m + 'm) '}</b>
    </div>

  } else if (timeRemaining > 0) {
    const h = Math.floor(timeRemaining / secPerHr);
    const m = Math.floor(timeRemaining % secPerHr / minPerHr);

    const pct = Math.round((timeRemaining / runTimeSec) * 100);
    const textColor = h > 1 ? 'black' : 'red';

    return (
      <div className="timeRemaining">
        <b id="timeRemainingBold" style={{ color: textColor }}>{h + 'h ' + m + 'm '}</b>
        <div />
        <meter
          id="timeRemainingMeter"
          value={pct}
          max="100"
          min="0"
          low="3"
          high="10"
          optimum="99"
          style={{ width: '100%', height: '8px', marginTop: '0px', border: 0 }}
        />
      </div>
    )
  } else {
    return <div id="timeRemainingEmptyDiv" className="timeRemaining" />
  }

}

TimeRemaining.defaultProps = {
  // label: translate('resources.patch.timeremaining'),
  showLabel: true,
};

const mapStateToProps = state => ({ temp: state.temp });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(TimeRemaining);
