import Box from '@mui/material/Box';
import * as React from 'react';
import { Title, useTranslate } from 'react-admin';
import Active from './bits/Active';
import getData from './bits/data';
import Toolbar from './bits/Toolbar';
import Totals from './bits/Totals';
import Donut from './bits/Graph';
import Responsive from '../ui/Responsive';

const min = 60000;

function Dashboard() {
  const translate = useTranslate();
  const timerRef = React.useRef(null);
  const componentMounted = React.useRef(true);
  const [refresh, setRefresh] = React.useState(min * 10);
  const [hospital, setHospital] = React.useState([true]);
  const [patient, setPatient] = React.useState([true]);
  const [error, setError] = React.useState([true]);

  const refreshData = () => {
    getData('tenants', 'GET')
      .then((res) => {
        if (componentMounted.current) {
          setHospital([false, res.tenant]);
          setPatient([false, res.patient]);
          setError([false, res.error]);
        }
      });
    timerRef.current = setTimeout(refreshData, refresh);
  }
  React.useEffect(() => {
    refreshData();
    return () => {
      componentMounted.current = false
      clearTimeout(timerRef.current)
    };
  }, []); // eslint-disable-line

  return (
    <Box>
      <Title id="dashTitle" title="ra.page.dashboard" />
      <Toolbar refresh={refresh} setRefresh={setRefresh} />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 2,
          gap: 2,
          m: 'auto',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
        >
          <Active hospital={hospital} patient={patient} />
          <Totals hospital={hospital} patient={patient} error={error} />
        </Box>
        {!!patient[1] &&
          <Responsive
            small={
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                px: 2,
                gap: 2,
                m: 'auto',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
              >
                <Donut hospital={hospital} variable="activePatients" min={1} max={10} name={translate('dashboard.patches')} active />
                <Donut hospital={hospital} variable="totalPatients" min={1} max={100} start={30} name={translate('dashboard.patients')} />
                {!!error[1] &&
                  <Donut hospital={hospital} variable="patchErrors" min={1} max={10} name={translate('diagnostic.error')} />
                }
              </Box>
            }
          />
        }
      </Box>
    </Box>
  );
}

export default Dashboard;
