import React, { useEffect, useState, useRef } from 'react';
import { useRecordContext, useRefresh, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import { dateFormat } from './dateFormat';
import "./styles/currentPatchStatus.css"
import { TrendingUp } from "@mui/icons-material";
import { PatientTile } from '../patients/Grid';
import TemperatureGraph from "./TemperatureGraph";
import { Box, Divider, Typography } from '@mui/material';

const normalStyle = {
  boxSizing: 'border-box',
  height: '162px',
  width: '162px',
  margin: '4px',
  borderRadius: '4px',
  backgroundColor: '#CCC'
}

const CurrentPatchAside = ({ ...rest }) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const timerRef = useRef(null);
  const componentMounted = useRef(true);
  const translate = useTranslate();
  const statusCode = record?.patchStatus?.statusCode;
  const [alert, setAlert] = useState(JSON.parse(localStorage.getItem("alertList")));
  const npa = statusCode && !statusCode.match(/NPA/i);

  useEffect(() => {
    refreshData();

    window.addEventListener('storage', (e) => {
      if (componentMounted.current && e.key && e.key === 'updateAlertFrame') {
        setAlert(JSON.parse(localStorage.getItem("alertList")));
      }
    });

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('storage', (e) => {
        if (componentMounted.current && e.key && e.key === 'updateAlertFrame') {
          setAlert(JSON.parse(localStorage.getItem("alertList")));
        }
      });
      componentMounted.current = false;
    }
  }, []); // eslint-disable-line

  const refreshData = () => {
    refresh();
    timerRef.current = setTimeout(refreshData, 60 * 1000);
  }

  const lastUpdated = dateFormat(record?.patchStatus?.lastUpdated);
  const startTime = dateFormat(record?.patchStatus?.startTime);

  return (
    <div>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          alignItems: 'center'
        }}>
          {!!npa &&
            <div>
              <Typography id="selected_title">
                {translate('resources.patch.label')}:
              </Typography>
              <Typography id="selected_patch" fontWeight="bold">
                {record?.patchStatus?.patchId}
              </Typography>
            </div>}
          <PatientTile alert={alert} normalStyle={normalStyle} record={record} {...rest} />
          {(startTime || "") && !!record?.patchStatus?.id &&
            <div>
              <Typography id="aside_started_label">
                {translate('resources.patch.starttime')}:
              </Typography>
              <Typography id="aside_started" fontWeight="bold" noWrap>
                {startTime}
              </Typography>
            </div>
          }
          {(lastUpdated || "") && !!record?.patchStatus?.id &&
            <div>
              <Divider />
              <Typography id="aside_updated_label">
                {translate('resources.misc.lastupdated')}:
              </Typography>
              <Typography id="aside_updated" fontWeight="bold" noWrap>
                {lastUpdated}
              </Typography>
            </div>
          }
          {record?.patchStatus && record?.patchStatus?.earlyIndicatorExpTime > 0 && !!record?.patchStatus?.id &&
            <div>
              <Divider />
              <Typography id="early_indicator" color="#F49C66" noWrap>
                <TrendingUp id="early_icon" sx={{ mb: -0.75 }} />
                {dateFormat(record?.patchStatus?.earlyIndicatorExpTime)}
              </Typography>
            </div>
          }
        </div>
        {record?.patchStatus &&
          <Box sx={{ width: '100%' }}>
            <TemperatureGraph record={record} {...rest} />
          </Box>
        }
      </Box>
    </div>
  )
}

const mapStateToProps = state => ({ displayAlertTemps: state.displayAlertTemps, temp: state.temp, hospital: state.hospital, displayNRDTimer: state.displayNRDTimer, config: state.config });
const mapDispatchToProps = {}; // to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPatchAside);
