import React from "react";
import {Button, TopToolbar, useRecordContext, useTranslate} from "react-admin";
import {Link} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeletePatchButton from './DeletePatchButton';

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    padding: 0,
    margin: '10px',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
};

const PatchShowActions = (props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const {config, patientId, patientCode} = props;
    return (
    <TopToolbar style={cardActionStyle}>
        <Button
            id="backToPatient"
            variant="blank"
            component={Link}
            to={`/PatientSummary/${patientId}/show/1`}
            label={`${translate('resources.patient.label')} ${patientCode ? patientCode : ''}`}
        >
            <ArrowBackIcon/>
        </Button>
        <span style={{flex: 1}}/>
        {config?.parameters?.enablePatchDelete && record && record.id > 0 &&
            <DeletePatchButton
                resource="PatchSummary"
                patientId={patientId}
            />
        }
        {config?.parameters?.enablePatchDelete && (!record || record.id === 0) &&
            <DeletePatchButton patientId={patientId} record={record} resource={"patientassignments"} />
        }
    </TopToolbar>
    );
};

export default PatchShowActions;