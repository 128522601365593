import React from "react";
import {
    Button,
    Datagrid,
    Filter,
    List,
    Pagination,
    SearchInput,
    TextField,
    TopToolbar
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const KeywordFilter = ({ ...props }) => {
    return (
        <Filter {...props}>
            <SearchInput source="q" alwaysOn />
        </Filter>
    );
};

const EntityListActions = ({ basePath }) => (
    <TopToolbar>
        <Button style={{marginTop:'0px', marginLeft:'0px'}} component={Link} to={'/configurations/create'} variant="contained" label="Add Configuration"><AddIcon style={{ height: '20px', width: '20px' }} /></Button>
    </TopToolbar>
);

const CustomPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ConfigurationList = props => (
    <List {...props}
        bulkActionButtons={false}
          pagination={<CustomPagination />} perPage={25}
          sort={{ field: "name", order: "ASC" }}
          filters={<KeywordFilter />}
          actions={<EntityListActions />}
    >
        <Datagrid rowClick='show' {...props} >
            <TextField source="name" />
            <TextField source="type" />
            <TextField label="Configuration Code" source="configurationcode" />
        </Datagrid>
    </List>
);