import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { propTypes, reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import {
    AUTH_LOGOUT,
    required,
    SimpleForm,
    PasswordInput,
    Title,
    useLogin,
    useRedirect,
    useTranslate, Toolbar, SaveButton
} from 'react-admin';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import LockIcon from '@mui/icons-material/Lock';
import authProvider, { authorization } from '../dataProvider/authProvider';
import { Notification } from 'react-admin';
import { createStyles, makeStyles } from '@mui/styles';
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveHospitalId } from "../hospitals/ActiveHospital";
import BackButton from "../ui/BackButton";
import Cookies from "js-cookie";

const styles = makeStyles(theme =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: '#182F46'
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary.main,
        },
        hint: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.grey[500],
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        actions: {
            padding: '0 1em 1em 1em',
        },
    })
);

const getRedirectPage = () => (
    new URL(window.location.href.replace('/#', '')).searchParams.get('page')
);

const ChangePasswordToolbar = () => {
    const redirect = useRedirect();
    const backRedirect = getRedirectPage() === null ? '/login' : '/displayOptions';

    return (
        <Toolbar style={{ paddingLeft: '30px' }} id="changePasswordToolbar">
            <BackButton
                variant="contained"
                style={{ width: '100%' }}
                goBack={() => redirect(backRedirect)}
                id="changePasswordBack"
                submitonenter="false"
            />
            <SaveButton
                icon={<div />}
                style={{ marginLeft: '8px', width: '100%' }}
                label="resources.patient.transfersubmit"
                variant="contained"
                id="changePasswordSave"
                submitonenter="true"
                type="submit"
            />
        </Toolbar>
    )
}

async function validation(config, translate, currentPassword, login, password, matchPassword, redirect, auth) {
    let specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    let requireLength = config.parameters.cpwClinicianRequireLength;
    let requireUpper = config.parameters.cpwClinicianRequireUpper;
    let requireLower = config.parameters.cpwClinicianRequireLower;
    let requireDigit = config.parameters.cpwClinicianRequireDigit;
    let requireSpecial = config.parameters.cpwClinicianRequireSpecial;
    let error = false;
    const errors = {};

    // Confirm the current password is correct
    await customDataProvider.checkLogin(currentPassword).then(() => {

        return Promise.resolve();
    }).catch(() => {
        errors.currentPass = translate('changepass.currentincorrect') + '\n';
        error = true;
    });

    if (requireLength > 0 && password.length < requireLength) {
        errors.newPass = translate('changepass.meetlength', { length: requireLength }) + '\n';
        error = true;
    }

    if (requireUpper && !/[A-Z]/.test(password)) {
        errors.newPass = translate('changepass.uppercase') + '\n';
        error = true;
    }
    if (requireLower && !/[a-z]/.test(password)) {
        errors.newPass = translate('changepass.lowercase') + '\n';
        error = true;
    }
    if (requireDigit && !/\d/.test(password)) {
        errors.newPass = translate('changepass.number') + '\n';
        error = true;
    }
    if (requireSpecial && !specialCharacters.test(password)) {
        errors.newPass = translate('changepass.special') + '\n';
        error = true;
    }

    if (password.current === null || password.current.length === 0) {
        errors.newPass = translate('changepass.newrequired');
        error = true;
    }

    if (matchPassword.current === null || matchPassword.current.length === 0) {
        errors.matchPass = translate('changepass.retype');
        error = true;
    } else if (matchPassword.current.trim() !== password.current.trim()) {
        errors.matchPass = translate('changepass.mustmatch');
        error = true;
    }

    if (!error) {
        const newPasswordBody = matchPassword.current.trim();
        const oldPasswordBody = currentPassword.trim();
        return customDataProvider.changeUserPassword(oldPasswordBody, newPasswordBody).then(() => {
            Cookies.remove('token');
            localStorage.clear();
            sessionStorage.clear();
            redirect('/login');
            return Promise.resolve();
        }).catch(() => {
            authProvider(AUTH_LOGOUT);
        });
    }

    return errors;
}

const ChangePassword = (props) => {
    const classes = styles();
    const translate = useTranslate();
    const redirect = useRedirect();
    const [config, setConfig] = useState(props.config);
    const [auth, setAuth] = useState(authorization());
    const login = useLogin();
    const matchPassword = useRef(null);
    const password = useRef(null);
    const [currentPassword, setCurrentPassword] = useState('');

    const validatePasswords = () => {
        return validation(config, translate, currentPassword, login, password, matchPassword, redirect, auth);
    }

    useEffect(() => {
        if (window.location.href.indexOf('changePassword?token') > 0) {
            let url = window.location.href;
            url = url.replace('/#', '');
            const params = new URLSearchParams(url.substring(url.indexOf('?') + 1));
            setAuth(params.get('token'));

            customDataProvider.getHospitalConfig(getActiveHospitalId())
                .then(data => {
                    setConfig(data.configuration);
                })
        }
    }, []);

    return (
        <div className={classes.main}>
            <Title id="changePasswordTitle" title="changepass.change" />
            <Card id="change_card" className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar id="change_lock_icon" className={classes.icon}>
                        <LockIcon style={{ height: '24px', width: '24px' }} />
                    </Avatar>
                </div>
                <div id="change_title" className={classes.hint}>{getRedirectPage() ? translate('changepass.change') : translate('changepass.changerequired')}<br />{translate('changepass.enter')}<br /></div>
                <SimpleForm style={{ marginTop: '-10px' }} validate={validatePasswords} toolbar={<ChangePasswordToolbar  {...props} />}>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <PasswordInput id="currentPass" name="currentPass" onChange={e => setCurrentPassword(e.target.value)}
                                label={translate('changepass.currentpass')}
                                source="currentPass"
                                validate={required()}
                            />
                        </div>
                        <div className={classes.input}>
                            <PasswordInput id="newPass" name="newPass" onChange={e => password.current = e.target.value}
                                label={translate('changepass.pass')}
                                source="newPass"
                                validate={required()}
                            />
                        </div>
                        <div className={classes.input}>
                            <PasswordInput id="matchPass" name="matchPass" onChange={e => matchPassword.current = e.target.value}
                                label={translate('changepass.retypepass')}
                                source="matchPass"
                                validate={required()}
                            />
                        </div>
                    </div>
                    <div id="change_note" className={classes.hint} style={{ margin: '5px', marginLeft: '35px', marginTop: '0px', color: '#FF8888' }}>{translate('changepass.note1')}<br />{translate('changepass.note2')}</div>
                </SimpleForm>
            </Card>
            <Notification />
        </div>
    )
}

ChangePassword.propTypes = {
    ...propTypes,
};

const mapStateToProps = state => ({ config: state.config });
export default compose(
    reduxForm({ form: 'changePassword' }),
    connect(mapStateToProps, {}),
)(ChangePassword);