export const MONITOR_SORTBY = 'MONITOR_SORTBY';
export const MONITOR_SORTDIR = 'MONITOR_SORTDIR';
export const CHANGE_TEMP = 'CHANGE_TEMP';
export const CHANGE_DISPLAY_ALERT_TEMPS = 'CHANGE_DISPLAY_ALERT_TEMPS';
export const CHANGE_LOCAL_SEARCH = 'CHANGE_LOCAL_SEARCH';
export const CHANGE_DISPLAY_NRD_TIMER = 'CHANGE_DISPLAY_NRD_TIMER';

export const changeSort = (sortBy, sortDir) => ({
  type:"RA/CRUD_CHANGE_LIST_PARAMS",
  payload: {
    filter: {},
    page:1,
    perPage:100,
    sort:sortBy,
    order: sortDir,
  },
  meta: {
    resource:"PatientSummary"
  }
});

export const changeSortDir = sortDir => ({
  type:"RA/CRUD_CHANGE_LIST_PARAMS",
  payload: {
    filter: {},
    order: sortDir,
    page:1,
    perPage:100,
  },
  meta: {
    resource:"PatientSummary"
  }
});

export const changeTemp = temp => ({
  type: CHANGE_TEMP,
  payload: temp
});

export const changeDisplayAlertSetting = display => ({
  type: CHANGE_DISPLAY_ALERT_TEMPS,
  payload: display
});

export const changeDisplayNRDTimer = display => ({
  type: CHANGE_DISPLAY_NRD_TIMER,
  payload: display
});

export const localSearch = search => ({
  type: CHANGE_LOCAL_SEARCH,
  payload: search
})
