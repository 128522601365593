import React, { useEffect, useState } from 'react';
import Responsive from '../ui/Responsive';
import {
    Show,
    Button,
    SimpleShowLayout,
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Datagrid,
    useTranslate,
    useRecordContext,
    TextField,
    FunctionField,
    EditButton,
    SimpleList
} from 'react-admin';
import AddPatchButton from '../patches/AddPatchButton';
import { connect } from 'react-redux';
import AlertTempField from './AlertTempField';
import CurrentPatchAside from '../patches/CurrentPatchAside';
import { dateFormat } from '../patches/dateFormat';
import PatchStatus from '../patches/PatchStatus';
import PatientExport from './PatientExport';
import CardActions from '@mui/material/CardActions';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScanPatchButton from '../patches/ScanButton';
import ShowCredentialsButton from './ShowCredentialsButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import EditIcon from '@mui/icons-material/Edit';
import TemperatureGraph from '../patches/TemperatureGraph';
import { Divider } from '@mui/material';

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    padding: 0,
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    mx: -2
};
var patientAlertTemp;

const Title = ({ showPatientId2First }) => {
    const record = useRecordContext();
    const translate = useTranslate();
    var displayTitle = "";
    if (record) {
        patientAlertTemp = record.alertTemp;
        localStorage.setItem("alertTemp", patientAlertTemp);
        if (showPatientId2First) {
            if (record.patientName !== undefined && record.patientName.length > 0) {
                displayTitle = `${record.patientName} / `
            }
            displayTitle += `${record.patientCode}`;
        } else {
            displayTitle = `${record.patientCode}`;
            if (record && record.patientName && record.patientName.length > 0) {
                displayTitle += ` / ${record.patientName}`
            }
        }
    }
    return <span id="patientShowLabel" >{translate('resources.patient.label') + ': '}{record ? displayTitle : ''}</span>;
};

const Aside = () => {
    return <CurrentPatchAside />;
};

const Graph = ({ currentSelection }) => {
    const record = useRecordContext();
    if (currentSelection === null) currentSelection = record;
    return (
        <div>
            {currentSelection &&
                <TemperatureGraph
                    record={{
                        patientCode: record.patientCode,
                        patientName: record.patientName,
                        ...currentSelection
                    }}
                />
            }
        </div>
    )
};

const ViewPatchButton = ({ patient, patch }) => {
    if (!patient || !patch) {
        return null;
    }
    const zoomButtonId = "zoom_" + patch.patchId;

    //If ID is 0 it's an unused patch. We have the patch ID though, so we need to figure out how to point to a different endpoint
    const linkToPatch = patch.id === 0 ? `/PatchSummary/0/show?patchId=${patch.patchId}&patientCode=${patient.patientCode}&patientId=${patient.id}` : `/PatchSummary/${patch.id}/show?patientCode=${patient.patientCode}&patientId=${patient.id}`;
    return <Link to={linkToPatch}><ZoomInIcon style={{ height: '24px', width: '24px' }} id={zoomButtonId} /></Link>;
};

const NestedPatchList = ({ onPatchSelect, currentSelection, ...props }) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const patient = record;

    if (currentSelection === null || !currentSelection) {
        currentSelection = record.patchStatus;
    }

    const patchRowStyle = (record) => {
        return {
            backgroundColor: record && currentSelection && record.patchId === currentSelection.patchId ? '#f5f5f5' : '#ffffff'
        };
    }

    return (
        <ReferenceManyField
            sort={{ field: "startTime", order: "DESC" }}
            reference="PatchSummary"
            label={false}
            target="patientCode"
            filter={{
                "hospitalCode": record.hospitalCode,
                "patientCode": record.patientCode
            }}
        >
            {/*<DumbList style={{boxSizing: 'border-box'}} pagination={false} {...props}  bulkActionButtons={false} actions={false} title=" ">*/}
            <Responsive
                xsmall={
                    <SimpleList
                        linkType={
                            record => record.patchStatus.id > 0 ?
                                `/PatchSummary/${record.patchStatus.id}/show?patientCode=${patient.patientCode}&patientId=${patient.id}`
                                : `/PatchSummary/0/show?patchId=${record.patchId}&patientCode=${patient.patientCode}&patientId=${patient.id}`
                        }
                        primaryText={record => record?.patchId}
                        secondaryText={record => (
                            record?.patchStatus?.startTime
                                ? `${translate('resources.patient.started')}: ${dateFormat(record.patchStatus.startTime)}`
                                : null)}
                        tertiaryText={
                            <>
                                <br />
                                <PatchStatus
                                    source="patchStatus"
                                    label="resources.misc.status"
                                    sortable={false}
                                    tempFormat={props.temp}
                                    adjustForOral={localStorage.getItem("adjustForOral") === 'true'}
                                />
                            </>
                        }
                    />
                }
                small={
                    <Datagrid
                        rowStyle={patchRowStyle}
                        bulkActionButtons={false}
                        rowClick={(id, resource, record) => onPatchSelect(record)}
                        {...props}>
                        <TextField id="patchID" source="patchID" label="resources.patch.patchid" sortable={false} />
                        <FunctionField id="start_time" label="resources.patch.starttime" source='startTime' render={record => `${dateFormat(record.patchStatus.startTime)}`} sortable={false} />
                        <PatchStatus source="patchStatus" label="resources.misc.status" sortable={false} tempFormat={props.temp} adjustForOral={localStorage.getItem("adjustForOral") === 'true'} />
                        <FunctionField label="" source="" render={record => record.patchStatus && <ViewPatchButton patient={patient} patch={record.patchStatus} />} />
                    </Datagrid>
                }
            />
            {/*</DumbList>*/}
        </ReferenceManyField>
    );
};

const PatientShowActions = () => {
    const record = useRecordContext();
    return (
        <CardActions sx={cardActionStyle}>
            <Button
                id="backToPatients"
                variant="blank"
                component={Link}
                to="/#/PatientSummary"
                label="resources.patient.patientlist"
            >
                <ArrowBackIcon style={{ height: '20px', width: '20px' }} />
            </Button>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                whiteSpace: 'nowrap'
            }}>
                <ShowCredentialsButton />
                <EditButton
                    icon={<EditIcon style={{ height: '20px', width: '20px' }} />}
                    variant="outlined"
                    label="resources.patient.editpatient"
                    id="editPatient" />
                {/* Add your custom actions */}
                <AddPatchButton record={record} />
                <ScanPatchButton />
            </div>
        </CardActions>
    );
};

const PatientSummaryShow = (props) => {
    const translate = useTranslate();
    const [selectedPatch, setSelectedPatch] = useState(null);
    const isMounted = React.useRef(true);

    // customDataProvider.getAlertsByPatient(233)
    //     .then(alerts => {
    //         console.log(alerts);
    //     })
    useEffect(() => {
        isMounted.current = true;
        document.addEventListener('addPatchMsg', (e) => addPatch(e));
        return () => {
            isMounted.current = false;
            document.removeEventListener('addPatchMsg', (e) => addPatch(e));
        }
    }, [])

    const addPatch = (event) => {
        console.log('adding patch ' + JSON.stringify(event.detail));
        console.log('adding patch ' + JSON.stringify(event.detail.hospitalCode) + "/" + JSON.stringify(event.detail.patientCode) + "/" + JSON.stringify(event.detail.patchId));

        if (window.NativeInterface) {
            window.NativeInterface.patchAddSuccess(event.detail.patchId);
            // window.NativeInterface.patchAddError("patchAddError");
        }
        // save patch

    }

    // not used?
    // const setPatientCode = (patientCode) => {
    //     console.log('patient code is ' + patientCode);
    // }

    return (
        <Show actions={null}
            sx={{ backgroundColor: "transparent", marginTop: '-16px', p: 0 }}
            title={<Title showPatientId2First={props.config.parameters.showPatientId2First} />}
            {...props}>
            <SimpleShowLayout>
                <PatientShowActions config={props.config} data={props.id} />
                <Responsive
                    xsmall={
                        <>
                            <TabbedShowLayout>
                                <Tab id="patient_tab" label={translate('resources.patch.current') + ' ' + translate('resources.patch.label')}>
                                    <Aside />
                                </Tab>
                                <Tab id="patches_tab" label="resources.patch.labelplural">
                                    <NestedPatchList
                                        onPatchSelect={(record) => setSelectedPatch(record)}
                                        currentSelection={selectedPatch}
                                        {...props} />
                                </Tab>
                                <Tab id="summary_tab" label="resources.misc.summary">
                                    <TextField source="groupCode" label={translate('resources.hospital.group') + " " + translate('resources.misc.id')} id="group_code" />
                                    <TextField source="tenantShortName" label={translate('resources.hospital.hospitalid')} id="hospital_code" />
                                    <TextField source="patientCode" label="resources.patient.patientidlabel" id="patientCode" />
                                    <TextField source="patientName" label="resources.patient.patientid2label" id="patientName" />
                                    <AlertTempField addLabel="true" label="resources.patient.alerttemp" source="alertTemp" sourceFormat="internal" />
                                    <FunctionField id="last_updated" label="resources.misc.lastupdated" render={record => `${dateFormat(record.patchStatus.lastUpdated)}`} />
                                </Tab>
                            </TabbedShowLayout>
                            {props.config.parameters.enableExport &&
                                <PatientExport />
                            }
                        </>
                    }
                    small={
                        <TabbedShowLayout>
                            <Tab id="patient_tab" label={translate('resources.patch.current') + ' ' + translate('resources.patch.label')}>
                                <Aside />
                            </Tab>
                            <Tab id="patches_tab" label="resources.patch.labelplural">
                                <Graph currentSelection={selectedPatch} />
                                <Divider />
                                <NestedPatchList
                                    onPatchSelect={(record) => setSelectedPatch(record)}
                                    currentSelection={selectedPatch}
                                    {...props} />
                            </Tab>
                            <Tab id="summary_tab" label="resources.misc.summary">
                                <TextField source="groupCode" label={translate('resources.hospital.group') + " " + translate('resources.misc.id')} id="group_code" />
                                <TextField source="tenantShortName" label={translate('resources.hospital.hospitalid')} id="hospital_code" />
                                <TextField source="patientCode" label="resources.patient.patientidlabel" id="patientCode" />
                                <TextField source="patientName" label="resources.patient.patientid2label" id="patientName" />
                                <AlertTempField addLabel="true" label="resources.patient.alerttemp" source="alertTemp" sourceFormat="internal" />
                                <FunctionField id="last_updated" label="resources.misc.lastupdated" render={record => `${dateFormat(record.patchStatus.lastUpdated)}`} />
                            </Tab>
                            {props.config.parameters.enableExport &&
                                <Tab id="export_tab" label="resources.misc.export">
                                    <PatientExport />
                                </Tab>
                            }
                        </TabbedShowLayout>
                    }
                />
            </SimpleShowLayout>
        </Show>
    );

}

const mapStateToProps = state => ({ temp: state.temp, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatientSummaryShow);
