import SaveIcon from '@mui/icons-material/Save';
import PropTypes from 'prop-types';
import React from 'react';
import {
    Edit,
    ReferenceArrayInput,
    required,
    SaveButton,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useRecordContext,
    useRedirect, useTranslate
} from 'react-admin';
import withRoles from "../hoc/withRoles";
import BackButton from '../ui/BackButton';
import { getActiveHospitalId } from "./ActiveHospital";
import DeleteHospitalButton from './DeleteHospitalButton';
import { adjustForOral, highTempThreshold, lowTempThreshold, validateThresholds } from "./hospitalCommon";

const EntityEditToolbar = ({ isClinician, isSysadmin, ...props }) => {
    const redirect = useRedirect();
    const record = useRecordContext();

    return (
        <Toolbar style={{ borderRadius: '5px', margin: '0px' }} {...props} >
            <SaveButton
                id="save_button"
                alwaysEnable
                variant="contained"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
            />
            <BackButton
                goBack={() => redirect(`show`, 'HospitalSummary', record.id)}
            />
            <span style={{ flex: "1" }} />
            {localStorage.getItem('isSysAdmin') === 'true' && record.id.toString() !== getActiveHospitalId() &&
                <DeleteHospitalButton id="delete_button" resource={"tenant"} />
            }
            <span style={{ flex: "1" }} />
        </Toolbar>
    )
};

const displayParent = (hierarchy) => {
    if (!hierarchy) {
        return "";
    }
    let hierarchyList = hierarchy.split("/");
    let hierarchyString = "";

    for (let i = 0; i < hierarchyList.length; i++) {
        if (hierarchyList[i].length === 0) { continue; }
        if (hierarchyString.length > 0) {
            hierarchyString += " / ";
        }
        hierarchyString = hierarchyList[i];
    }

    return hierarchyString;
}

const HospitalsummaryEdit = ({ pwChangeDate, termsAck, isSysadmin, isClinician, ...props }) => {
    const translate = useTranslate();
    return (
        <Edit
            sx={{ margin: '0px', mb: { xs: '80px', sm: 1 } }}
            mutationMode="pessimistic"
            redirect="show"
            title="resources.hospital.show.edit"
            {...props}
            actions={false}
        >
            <SimpleForm validate={validateThresholds} id="editform" toolbar={<EntityEditToolbar />}>
                <TextInput
                    label="resources.hospital.parentLabel"
                    id="parentName"
                    source="displayHierarchy"
                    format={(value) => displayParent(value)}
                    disabled
                />
                <br />
                <TextInput id="hospitalCode" label="resources.hospital.hospitalid" source="tenantcode" disabled />
                <br />
                <TextInput label="resources.hospital.name" id="hospitalName" source="hospitalName" validate={required()} />
                {adjustForOral()}
                {highTempThreshold(translate)}
                <br />
                {lowTempThreshold(translate)}
                <br />
                <TextInput label="resources.hospital.show.config" id="hospitalConfigurationCode" source="configurationCode" disabled />
                <br />
                <ReferenceArrayInput
                    perPage={100}
                    source="assignedIntegrations"
                    reference="tenantintegrations"
                >
                    <SelectArrayInput
                        id="integrations"
                        label="resources.hospital.show.integrations"
                        style={{ width: '190px' }}
                        optionText="pipeline"
                    />
                </ReferenceArrayInput>
                <br />
            </SimpleForm>
        </Edit>
    );
}

HospitalsummaryEdit.propTypes = {
    isSysadmin: PropTypes.bool.isRequired,
    isClinician: PropTypes.bool.isRequired,
};

HospitalsummaryEdit.defaultProps = {
    isSysadmin: false,
    isClinician: false,
};

export default withRoles(HospitalsummaryEdit);