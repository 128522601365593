import  HospitalsummaryList from "./hospitalList";
import HospitalsummaryShow from './hospitalShow';
import HospitalsummaryCreate from "./hospitalCreate";
import HospitalsummaryEdit from "./hospitalEdit";

export { HospitalsummaryList };
export { HospitalsummaryShow };

export default {
  list: HospitalsummaryList,
  create: HospitalsummaryCreate,
  edit: HospitalsummaryEdit,
  show: HospitalsummaryShow
};
