import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Loading, { LoadingTitle } from './Load';
import { useTranslate } from 'react-admin';

function Totals({ hospital, patient, error }) {
  const translate = useTranslate();
  return (
    <TableContainer id="total_table" component={Paper} sx={{ width: 300, m: 0, height: 'fit-content' }}>
      <Table aria-label="collapsible table" size="small">
        <TableBody>
          {hospital[0] || patient[0]
            ? (
              <>
                <LoadingTitle />
                <Loading />
                <Loading />
                <Loading />
              </>
            ) : (
              <>
                <TableRow>
                  <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
                    <Typography id="total_title" fontWeight="bold" variant="h6">
                      {translate('dashboard.total')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography id="total_hospitals_label" variant="h6">
                      {translate('dashboard.hospitals')}:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="total_hospitals" fontWeight="bold" variant="h6">
                      {hospital[1].length}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography id="total_patients_label" variant="h6">
                      {translate('dashboard.patients')}:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="total_patients" fontWeight="bold" variant="h6">
                      {patient[1]}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography id="total_errors_label" variant="h6">
                      {translate('diagnostic.error')}:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography id="total_errors" fontWeight="bold" variant="h6">
                      {error[1]}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

Totals.propTypes = {
  hospital: PropTypes.array.isRequired,
  patient: PropTypes.array.isRequired,
};

export default Totals;
