import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import { Dialog } from '@mui/material';
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogContentText } from '@mui/material';
import { DialogTitle } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility'
import customDataProvider from "../dataProvider/customDataProvider";

const ShowCredentialsButton = (props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState(undefined);

    const handleClick = e => {
        e.stopPropagation();

        if (password !== undefined) {
            setIsOpen(true);
        } else {
            setIsLoading(true);

            customDataProvider.getPatientCredentials(record)
                .then(credentials => {
                    credentials = credentials.credentials.json;

                    setPassword(credentials.passwordHash);
                    setIsLoading(false);
                    setIsOpen(true);
                })
        }
    };

    const handleDialogClose = e => {
        e.stopPropagation();
        setPassword(undefined);
        setIsOpen(false);
    };

    return (
        <Fragment>

            <Button
                id="showcredentials"
                style={{ fontSize: '.8125rem', width: 'fit-content', padding: '10px' }}
                variant="contained"
                onClick={handleClick}
                startIcon={isLoading
                    ? <CircularProgress size={24} color="inherit" />
                    : <VisibilityIcon sx={{ height: '20px', width: '20px' }} />}
            >
                {translate('resources.patient.showcredentials')}
            </Button>
            <Dialog
                open={isOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{translate('resources.patient.credentialstitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span style={{ width: 'fit-content', display: 'table' }}>
                            {props.config.parameters.showHospitalCode &&
                                <span style={{ display: 'table-row' }}>
                                    <span id="hospitalLabel" style={{ width: '66%', display: 'table-cell', paddingRight: '12px' }}>{translate('resources.hospital.group') + " " + translate('resources.misc.id')}</span>
                                    <span id="hospital" style={{ width: '33%', display: 'table-cell', whiteSpace: 'nowrap' }}><b>{record && record.groupCode}</b></span>
                                </span>
                            }
                            <span style={{ display: 'table-row' }}>
                                <span id="patientLabel" style={{ display: 'table-cell', paddingRight: '12px' }}>{translate('resources.patient.patientidlabel')}</span>
                                <span id="patient" style={{ display: 'table-cell', whiteSpace: 'nowrap' }}><b>{record && record.patientCode}</b></span>
                            </span>
                            <span style={{ display: 'table-row' }}>
                                <span id="passwordLabel" style={{ display: 'table-cell', paddingRight: '12px' }}>{translate('ra.auth.password')}</span>
                                <span id="password" style={{ display: 'table-cell', whiteSpace: 'nowrap' }}><b>{password}</b></span>
                            </span>
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="okButton" onClick={handleDialogClose} color="primary" autoFocus>
                        {translate('resources.misc.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

const mapStateToProps = state => ({ config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(ShowCredentialsButton);
