import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    EditButton,
    FunctionField,
    Show,
    Tab,
    TabbedShowLayout,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { TextField as TextFieldRA } from 'react-admin';
import { CardActions } from '@mui/material';
import ActivateButton from './ActivateButton';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from 'react-router-dom';
import { ConfigValueField } from "../configurations/ConfigValueField";
import EditIcon from '@mui/icons-material/Edit';
import customDataProvider from "../dataProvider/customDataProvider";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from '@mui/material/Paper';
import AlertTempField from "../patients/AlertTempField";
import {curTempSetting} from "../custom/settings/displayOptions";


const Title = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return <span>{translate('resources.hospital.label')}: {record ? record.hospitalName : ''}</span>;
};

const displayHierarchy = (hierarchy) => {
    if (!hierarchy) {
        return "";
    }
    let hierarchyList = hierarchy.split("/");
    let hierarchyString = "";

    for (let i = 0; i < hierarchyList.length; i++) {
        if (hierarchyList[i].length === 0) { continue; }
        if (hierarchyString.length > 0) {
            hierarchyString += " / ";
        }
        //Just show the last item in the hierarchy
        hierarchyString = hierarchyList[i];
    }

    return hierarchyString;
}

const HospitalActions = ({ isSysadmin }) => {
    const record = useRecordContext();

    return (
        <CardActions>
            <Button
                id="backToHospitalSummary"
                variant="blank"
                component={Link}
                to={`/HospitalSummary`}
                label="resources.hospital.list"
            >
                <ArrowBackIcon style={{ height: '20px', width: '20px' }} />
            </Button>
            <span style={{ flex: 1 }} />
            {isSysadmin === 'true' &&
                <EditButton
                    style={{ marginRight: '.5em' }}
                    variant="outlined"
                    label="resources.hospital.show.edit"
                    icon={<EditIcon style={{ height: '20px', width: '20px' }} />}
                    record={record}
                    id="editHospital" />
            }
            {record &&
                <ActivateButton record={record} />
            }
        </CardActions>
    )
};

const HospitalIntegrations = ({ isSysAdmin }) => {
    const componentMounted = useRef(true);
    const record = useRecordContext();
    const [assignedIntegrations, setAssignedIntegrations] = useState(undefined);

    useEffect(() => {

        return () => {
            componentMounted.current = false;
        }
    }, []);

    if (isSysAdmin === 'true' && !assignedIntegrations) {
        customDataProvider.getIntegrations(record.id)
            .then(integrations => {
                if (componentMounted.current) {
                    setAssignedIntegrations(integrations);
                    return Promise.resolve();
                }
            })

        return (
            <div></div>
        )
    } else if (isSysAdmin === 'true') {
        return (
            <div>
                <TableContainer style={{ marginBottom: '10px' }} component={Paper}>
                    <Table id="assignedIntegrations" sx={{ maxWidth: 350 }} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow >
                                <TableCell style={{ fontWeight: 'bold' }}>Integrations</TableCell>
                            </TableRow>
                            {assignedIntegrations.assignedIntegrations.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Table id="inheritedIntegrations" sx={{ maxWidth: 350 }} size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Inherited Integrations</TableCell>
                            </TableRow>
                            {assignedIntegrations.inheritedIntegrations.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{row.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    } else {
        return (
            <div></div>
        )
    }
}

const HospitalsummaryShow = ({ record, isClinician, isSysadmin, ...props }) => {
    const isSysAdmin = localStorage.getItem('isSysAdmin');
    const translate = useTranslate();

    return (
        <Show {...props} actions={<HospitalActions isSysadmin={isSysAdmin} />} title={<Title  {...props} />}>

            <TabbedShowLayout>
                <Tab id="summary_tab" label="resources.hospital.show.summary">
                    {isSysAdmin === 'true' &&
                        <FunctionField id="hierarchy" label="resources.hospital.parentLabel"
                            render={record => `${displayHierarchy(record.displayHierarchy)}`} />
                    }
                    <TextFieldRA id="hospital_code" source="hospitalCode" label="resources.hospital.hospitalid" />
                    <TextFieldRA id="hospital_name" source="hospitalName" label="resources.hospital.name" />
                    {isSysAdmin === 'true' &&
                        <TextFieldRA id="hospital_adjustForOral" source="adjustForOral"
                                     label="resources.hospital.adjustfororal"/>
                    }
                    <AlertTempField addLabel="true" label={translate('resources.hospital.highTempThreshold', {scale: curTempSetting()})} source="highTempThreshold" sourceFormat="internal" />
                    <AlertTempField addLabel="true" label={translate('resources.hospital.lowTempThreshold', {scale: curTempSetting()})} source="lowTempThreshold" sourceFormat="internal" />
                    {isSysAdmin === 'true' &&
                        <TextFieldRA id="config_code" source="configurationCode" label="resources.hospital.show.config" />
                    }
                    <HospitalIntegrations isSysAdmin={isSysAdmin} />
                </Tab>
                <Tab id="statistics_tab" label="resources.hospital.show.stats">
                    <TextFieldRA id="active_patients" source="activePatients" label="resources.hospital.activepats" />
                    <TextFieldRA id="completed_patients" source="completedPatients" label="resources.hospital.completepats" />
                    <TextFieldRA id="unstarted_patients" source="unstartedPatients" label="resources.hospital.unstartedpats" />
                </Tab>
                {isSysAdmin === 'true' &&
                    <Tab id="clinician_configurations_tab" label="resources.hospital.show.hospitalconfigure">
                        <ConfigValueField application={"clinician"}/>
                    </Tab>
                }
                {isSysAdmin === 'true' &&
                    <Tab id="patient_configurations_tab" label="resources.hospital.show.patientconfigure">
                        <ConfigValueField application={"patient"}/>
                    </Tab>
                }
            </TabbedShowLayout>
        </Show>
    );
}

HospitalsummaryShow.propTypes = {
    isSysadmin: PropTypes.bool.isRequired,
    isClinician: PropTypes.bool.isRequired,
};

HospitalsummaryShow.defaultProps = {
    isSysadmin: false,
    isClinician: false,
};

export default HospitalsummaryShow;