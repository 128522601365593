import { MONITOR_SORTBY } from './action';
import { MONITOR_SORTDIR } from './action';
import { CHANGE_TEMP } from './action';
import { CHANGE_DISPLAY_ALERT_TEMPS} from './action';
import { CHANGE_LOCAL_SEARCH} from './action';
import { CHANGE_DISPLAY_NRD_TIMER} from './action';
import {curSortByVal, curSortDirVal, curTempSetting, curDisplayAlertSetting, curDisplayNRDTimer} from './displayOptions';

export const monitorSortBy = (previousState = curSortByVal(), { type, payload }) => {
  if (type === MONITOR_SORTBY) {
    return payload;
  }
  return previousState;
};

export const monitorSortDir =(previousState = curSortDirVal(), { type, payload }) => {
  if (type === MONITOR_SORTDIR) {
    return payload;
  }
  return previousState;
};

export const temp =(previousState = curTempSetting(), { type, payload }) => {
  if (type === CHANGE_TEMP) {
    return payload;
  }
  return previousState;
};

export const displayAlertTemps =(previousState = curDisplayAlertSetting(), { type, payload }) => {
  if (type === CHANGE_DISPLAY_ALERT_TEMPS) {
    return payload;
  }
  return previousState;
};

export const displayNRDTimer =(previousState = curDisplayNRDTimer(), { type, payload }) => {
  if (type === CHANGE_DISPLAY_NRD_TIMER) {
    return payload;
  }
  return previousState;
};

export const localSearch = (previousState = {text:'', includeInactive:false, activeHospitalOnly:false}, {type, payload}) => {
  if(type===CHANGE_LOCAL_SEARCH) {
    return {
      ...previousState,
      ...payload
    };
  }
  return previousState;
}
