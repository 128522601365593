import {GatewayList} from './gatewaysList';
import GatewayEdit from './gatewaysEdit';
import GatewayCreate from './gatewaysCreate';
import GatewayShow from './gatewaysShow';

export default {
    list: GatewayList,
	create: GatewayCreate,
	edit: GatewayEdit,
	show: GatewayShow
};