import spanishMessages from '@blackbox-vision/ra-language-spanish';

export default {
    null: 'Este elemento no existe en la traducción {NULL}',
    simple: {
        action: {
            close: 'Cerrar',
            resetViews: 'Restablecer vistas',
        },
        'create-post': 'Nueva entrada',
    },
    ...spanishMessages,
    misc: {
        temperatureoral: 'Temperatura equivalente bucal',
        temperatureaxillary: 'Temperatura axilar',
    },
    resources: {
        auth: {
            passwordreset: 'Las instrucciones para restablecer la contraseña se han enviado a %{email}',
            emailrequired: 'La dirección de correo electrónico es obligatoria',
            invalid: 'Nombre de usuario o contraseña no válidos'
        },
        misc: {
            ascending: 'Subiendo',
            date: 'Fecha',
            datetime: 'Fecha/hora',
            descending: 'Bajando',
            detail: 'Detalle',
            export: 'Exportar',
            id: 'ID',
            lastupdated: 'Última actualización',
            loadingdata: 'Cargando datos...',
            notviewinginwebview: 'No se ve en webview',
            optional: 'Opcional',
            ok: 'Aceptar',
            processingdata: 'Procesando datos...',
            status: 'Estado',
            sortby: 'Ordenar por',
            summary: 'Resumen',
            default: 'Predeterminado',
            error: 'Error al recuperar los datos',
            nodata: 'No hay datos disponibles',
            allowed: 'Caracteres permitidos: a-z A-Z 0-9 . -'
        },
        hospital: {
            highTempThreshold: 'Umbral de alta temperatura (°%{scale})',
            lowTempThreshold: 'Umbral de baja temperatura (°%{scale})',
            adjustfororal: 'Ajustar para bucal',
            hospitalalerttemphelper: 'Debe ser inferior al umbral de alta temperatura',
            label: 'Unidad',
            hospitalid: 'ID de la unidad',
            group: 'Grupo',
            parentLabel: 'ID de la unidad matriz',
            add: 'Añadir unidad',
            name: 'Nombre de la unidad',
            activepats: 'Pacientes activos',
            completepats: 'Pacientes completados',
            unstartedpats: 'Pacientes sin comenzar',
            unstarted: 'Sin comenzar',
            active: 'Activo',
            activate: 'Activar',
            list: 'Unidades',
            create: 'Crear unidad',
            show: {
                edit: 'Editar unidad',
                hierarchy: 'Jerarquía de unidades',
                config: 'Configuración',
                integrations: 'Integraciones',
                hospitalconfigure: 'Configuraciones clínicas',
                patientconfigure: 'Configuraciones del paciente',
                summary: 'Resumen',
                stats: 'Estadísticas',
            }
        },
        patient: {
            activehospitalonly: 'unidad activa solo',
            activepatientsonly: 'Pacientes activos',
            addpatient: 'Añadir paciente',
            alerttemp: 'Alerta temperatura',
            dailytmax: 'Tmáx diaria',
            duplicateId: 'El ID del paciente debe ser único',
            editpatient: 'Editar paciente',
            exportcsv: 'Exportar todos los datos del paciente (CSV)',
            kitid: 'ID del kit',
            kitpassword: 'Contraseña del kit',
            kitpasswordrequired: 'La contraseña del kit es obligatoria',
            kitidrequired: 'El ID del kit es obligatorio',
            label: 'Paciente',
            passwordhelp: 'Requerido para uso ambulatorio',
            patientid2label: 'ID del paciente 2',
            patientid2required: 'El ID del paciente 2 es obligatorio',
            patientid2helperrequired: '*Obligatorio',
            patientid2helperoptional: 'Opcional',
            patientalerttemplabel: 'Alerta temperatura (°%{scale})',
            patientalerttemphelper: 'El rango válido es %{min} - %{max}',
            patientidlabel: 'ID del paciente',
            patientidrequired: 'El ID del paciente es obligatorio',
            patientlist: 'Pacientes',
            patientpassword: 'Contraseña del paciente',
            showcredentials: 'Credenciales',
            credentialstitle: 'Credenciales del paciente',
            showinactivepatients: 'mostrar pacientes inactivos',
            temperature: 'Temperatura',
            transfer: 'Transferir',
            transferkit: 'Transferir kit',
            transferpatient: 'Transferir kit del paciente a %{hospitalCode}',
            transfersubmit: 'ENVIAR',
            transfersourcehospital: 'ID del grupo',
            passwordincorrect: 'La contraseña es incorrecta',
            started: 'Iniciado'
        },
        patch: {
            addpatch: 'Añadir parche',
            multiple: 'Añadir varios parches',
            completed: 'Completado',
            current: 'Último',
            deletepatch: '¿Borrar parche?',
            high: 'Alto',
            label: 'Parche',
            labelplural: 'Parches',
            nopatchesadded: 'No se han añadido parches',
            norecentdata: 'No hay datos recientes',
            nodata: 'No hay datos',
            nodatafor: 'No hay datos para',
            patchactive: 'Parche activo',
            patchid: 'ID del parche',
            replacepatch: 'Sustituir parche',
            scanpatch: 'Escanear parche',
            starttime: 'Hora de inicio',
            timeremaining: 'Tiempo restante',
            unusedpatch: 'Parche no usado',
            graph: 'Gráfico',
            summary: 'Resumen',
            history: 'Historial',
            export: 'Exportar',
            exportcsv: 'Exportar datos de parches únicos (CSV)',
            NPA: 'No hay parches asignados',
            NOS: 'Parche no iniciado',
            THI: 'Al',
            TLO: 'Ba',
            invalid: 'ID de parche no válido',
            assigned: 'El parche ya está asignado a un paciente',
            scan: 'No hay datos de escaneado disponibles para su visualización',
            isrequired: 'El ID del parche es obligatorio',
            patchisadded: 'Se ha añadido el parche'
        },
        password: {
            containnumber: 'La contraseña debe contener un número',
            lowercaseletter: 'La contraseña debe contener al menos 1 letra minúscula',
            uppercaseletter: 'La contraseña debe contener al menos 1 letra mayúscula',
            requiredlength: 'La contraseña no cumple con la longitud requerida de %{length} caracteres',
            specialcharacter: 'La contraseña debe contener al menos 1 carácter especial'
        },
    },
    user: {
        roles: {
            tenantadmin: 'Administrador de la unidad',
            clinician: 'Facultativo',
            sysadmin: 'Administrador global'
        },
        detail: 'Usuario',
        create: {
            add: 'Añadir usuario',
            admin: 'Administrador de la unidad',
            clinician: 'Facultativo de la unidad',
            fname: 'Nombre',
            lname: 'Apellidos',
            active: 'Estado activo',
            email: 'Correo electrónico',
            role: 'El usuario debe tener al menos una función'
        },
        list: {
            name: 'Nombre',
            role: 'Función',
            label: 'Usuarios',
            search: 'Buscar',
        },
        form: {
            summary: 'Resumen',
            security: 'Seguridad',
        },
        edit: {
            label: 'Editar usuario',
            title: 'Usuario "%{title}" ',
        },
        action: {
            save_and_add: 'Guardar y añadir',
            save_and_show: 'Guardar y mostrar',
        },
    },
    login: {
        title: {
            cell360: 'Aplicación web Cell Therapy 360',
            temptraq: 'Aplicación web clínica TempTraq'
        },
        forgot: {
            link: 'He olvidado las credenciales',
            note: 'Nota: cuando reciba un correo electrónico en la dirección indicada, siga el enlace del correo para restablecer su contraseña.',
            submit: 'Enviar',
            snack: 'PENDIENTES Las instrucciones para restablecer la contraseña se han enviado a'
        },
        client: 'Cliente',
        error: 'Nombre de usuario o contraseña no válidos',
        email: 'Correo electrónico'
    },
    navigation: {
        analytics: 'Analítica',
        patient: 'Pacientes',
        gateway: 'Gateways',
        gatewayid: 'ID del gateway',
        hospital: 'Unidades',
        user: 'Usuarios',
        config: 'Configuraciones',
        settings: 'Configuración',
        network: 'No se ha podido contactar con el servidor. Compruebe la conectividad de red.'
    },
    settings: {
        server: 'Servidor',
        udi: 'UDI',
        label: 'Configuración',
        current: 'Usuario actual',
        change: 'Cambiar contraseña',
        diagnostics: "Diagnóstico",
        scale: 'Elija su escala de temperatura',
        ui: 'Opciones de interfaz de usuario',
        alert: 'Mostrar alertas de temperatura',
        timer: 'Mostrar temporizador sin datos recientes',
        legal: 'Legal',
        privacy: 'Política de privacidad',
        terms: 'Condiciones de uso',
        example: 'Ejemplo',
        language: 'Opciones de idioma',
        enabled: 'Activado'
    },
    earlyindicator: {
        title: 'Indicador precoz'
    },
    alert: {
        soundon: 'Sonido activado',
        soundoff: 'Sonido desactivado',
        notetitle: 'A este sitio le gustaría reproducir sonido.',
        notedescr: 'Se requiere una acción del usuario para permitir la reproducción automática.',
        generate: 'Generar notificación aleatoria',
        nonotification: 'Sin notificación',
        clearall: 'Borrar todo',
        clear: 'Borrar',
        headerTitle: 'Alertas',
        title: {
            default: 'a',
            alert: 'Alerta de temperatura, %{patient}',
            err: 'Sustituir parche, %{patient}',
            nrd: 'Sin datos recientes, %{patient}',
            afin: 'Parche finalizando, %{patient}',
            early: 'Alerta de indicador precoz, %{patient}'
        },
        description: {
            default: 'Notificación recibida a las %{time}. La temperatura para el paciente %{code} y el parche %{patchId} está en el nivel de alerta.',
            alert: 'Notificación recibida a las %{time}. La temperatura para el paciente %{patient} está en el nivel de alerta.',
            err: 'Alerta de parche de TempTraq. Sustituir parche para el paciente %{patient}.',
            nrd: 'No se han recibido datos de temperatura desde las %{time} para el paciente %{patient}. Puede que el paciente esté demasiado lejos del dispositivo receptor o que haya problemas de conectividad en la red.',
            afin: 'El parche %{patch} está casi terminado. Aplique un nuevo parche para continuar la vigilancia.',
            early: 'En base a las tendencias de temperatura, TempTraq ha detectado un mayor riesgo de que el paciente alcance la temperatura %{alertTemp} en la próxima hora.  Es posible que desee vigilar más de cerca la temperatura del paciente y considerar los preparativos adecuados para una alerta de temperatura real.'
        }
    },
    acknowledge: {
        title: '',
        terms: 'Debe aceptar las condiciones para utilizar esta herramienta',
        accept: 'Acepto',
        acknowledge: 'Confirmar'
    },
    changepass: {
        currentpass: 'Contraseña actual',
        newrequired: 'Se necesita una nueva contraseña',
        currentrequired: 'Se necesita la contraseña actual',
        currentincorrect: 'La contraseña actual es incorrecta',
        meetlength: 'La contraseña no cumple con la longitud requerida de %{length} caracteres',
        uppercase: 'La contraseña debe contener al menos 1 letra mayúscula',
        lowercase: 'La contraseña debe contener al menos 1 letra minúscula',
        number: 'La contraseña debe contener un número',
        special: 'La contraseña debe contener al menos 1 letra especial',
        retype: 'Vuelva a escribir la nueva contraseña',
        mustmatch: 'Las contraseñas deben coincidir',
        change: 'Cambiar contraseña',
        changerequired: 'Cambio de contraseña necesario',
        pass: 'Nueva contraseña',
        retypepass: 'Vuelva a escribir la nueva contraseña',
        enter: 'Escriba la nueva contraseña',
        note1: 'Nota: deberá iniciar sesión',
        note2: 'de nuevo con su nueva contraseña'
    },
    dashboard: {
        active: 'Activo',
        total: 'Total',
        hospitals: 'Unidades',
        patients: 'Pacientes',
        patches: 'Parches',
        server: 'Estado del servidor',
        run: 'En ejecución',
        pause: 'Pausado',
        seems: 'Parece que está inactivo',
        down: 'Inactivo',
        not: 'No se ha comprobado todavía',
        go: 'UptimeRobot',
        tooltip: 'Actualización automática: %{refresh} minutos',
        refresh: 'Intervalo de actualización automática',
        min: 'min',
        other: 'Menos de %{num}',
        group: 'Agrupar unidades con menos de'
    },
    gateways: {
        label: 'Gateway',
        delete: '¿Borrar gateway?',
        edit: 'Editar gateway',
        name: 'Nombre del gateway',
        location: 'Ubicación del gateway',
        id: 'ID del gateway',
        active: 'Solo unidades activas',
        add: 'Añadir gateway',
        list: 'Gateways',
        inactive: 'Inactivo',
        emptytitle: 'No hay gateway todavía.',
        emptydesc: 'La unidad actualmente activa no tiene asignada un gateway.'
    },
    integration: {
        endpoint: 'Endpoint',
        pipeline: 'Pipeline',
        name: 'Nombre',
        edit: 'Editar integración',
        add: 'Añadir integración',
        list: 'Lista de integración',
        delete: '¿Borrar integración?',
        detail: 'Datos de integración'
    },
    diagnostic: {
        title: 'Rendimiento del parche',
        voltb: 'Batería voltios',
        volts: 'Sensor voltios',
        error: 'Errores',
    },
    config: {
        type: 'Tipo',
        inherit: 'Configuración heredada',
        value: 'Valor'
    }
};
