import _ from 'lodash';
import React, { useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import {
  numConvertFromC, numConvertFromI,
  numConvertFromInternalToAxillaryC,
  numConvertFromInternalToOralC,
  stringConvertFromC
} from '../tempscale/tempConvert';
import { dateFormat } from "./dateFormat";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const PatchHistoryTable = (props) => {
  const getRowValues = (data) => {
    var rowValues = [];
    var numValues = data?.history?.length;

    for (var i = 0; i < numValues; i++) {
      var tempVal = data.history[i].temperatureC;
      var measurementTime = data.history[i].measurementTime;
      var displayTemp = adjustForOral ? numConvertFromInternalToOralC(tempVal) : numConvertFromInternalToAxillaryC(tempVal);

      data.history[i].convertedTemp = stringConvertFromC(displayTemp, props.temp);
      data.history[i].convertedTime = dateFormat(measurementTime);
      rowValues.push(data.history[i]);
    }

    const zeroValueString = stringConvertFromC(0, props.temp);
    // note: if you change rows above, change index of o[] to point temperature column
    rowValues = _.dropRightWhile(rowValues, function (o) { return o[1] === zeroValueString; })

    return rowValues;
  }
  const record = useRecordContext();
  const translate = useTranslate();
  const adjustForOral = localStorage.getItem("adjustForOral") === 'true';
  const lowTempThreshold = numConvertFromI(localStorage.getItem("lowTempThreshold"));
  const highTempThreshold = numConvertFromI(localStorage.getItem("highTempThreshold"));
  const lowTemp = numConvertFromC(lowTempThreshold, props.temp);
  const highTemp = numConvertFromC(highTempThreshold, props.temp);
  const alertTemp = numConvertFromC(numConvertFromI(localStorage.getItem("alertTemp")), props.temp);
  const patchID = record?.id;
  const [sort, setSort] = useState(localStorage.getItem('sortStr') || 'measurementTime');
  const [sortBy, setSortBy] = useState(localStorage.getItem('sortDir') || 'asc');
  const asc = (a, b) => (a > b) - (a < b);
  const desc = (a, b) => (a < b) - (a > b);
  const initialSort = (a, b) => (sortBy === 'desc' ? desc(a[sort], b[sort]) : asc(a[sort], b[sort]));
  const [rows, setRows] = useState(getRowValues(props.data).sort(initialSort));

  React.useEffect(() => {
    if (props?.data?.history) {
      return;
    }
    props.refreshData(patchID);

    if (props?.data?.history) {
      setRows(getRowValues(props.data).sort(initialSort));
    }
  }, []); // eslint-disable-line

  const handleSort = (str) => {

    if (sortBy === null) {
      setSortBy('desc');
      localStorage.setItem('sortDir', 'desc');
    } else if (sortBy === 'desc') {
      setSortBy('asc');
      localStorage.setItem('sortDir', 'asc');
    } else {
      setSortBy('desc');
      localStorage.setItem('sortDir', 'desc');
    }

    rows.sort((a, b) => (sortBy === 'desc' ? asc(a[str], b[str]) : desc(a[str], b[str])));

    setSort(str);
    localStorage.setItem('sortStr', str);
  };

  const iconPicker = (sortString) => {
    if (sort === null && sortString === 'measurementTime') {
      return <ArrowUpwardIcon />;
    }
    if (sort !== sortString) {
      return <div />;
    }
    if (sortBy === 'asc') return <ArrowUpwardIcon />;
    if (sortBy === 'desc') return <ArrowDownwardIcon />;
    return <SwapVertIcon color="disabled" />;
  };

  const head = [
    { string: translate('resources.misc.datetime'), sort: 'measurementTime' },
    { string: translate('resources.patient.temperature'), sort: 'temperatureC' }
  ];

  const colorPicker = (temperature) => {
    let displayTemp = adjustForOral ? numConvertFromInternalToOralC(temperature) : numConvertFromInternalToAxillaryC(temperature);
    let convertedDisplayTemp = numConvertFromC(displayTemp, props.temp);

    if (temperature === 0) {
      return '#CCCCCC';
    } else if (convertedDisplayTemp < lowTemp) {
      return '#4BC4E3';
    } else if (convertedDisplayTemp < highTemp) {
      return '#71C6A8';
    } else if (convertedDisplayTemp < alertTemp) {
      return '#F49C66';
    }

    return '#F26666';
  };

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell style={{ width: '5px' }}></TableCell>
          {head.map((item) => (
            <TableCell
              key={item.sort}
              style={{ width: '50%', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: '.8rem' }}
            >
              <IconButton
                size="small"
                sx={{
                  borderRadius: 0,
                  color: '#000',
                  '&:hover': { backgroundColor: '#fff' },
                }}
                onClick={() => handleSort(item.sort)}
              //disabled={isEqual(item.sort)}
              >
                {item.string}
                {iconPicker(item.sort)}
              </IconButton>
            </TableCell>
          ))}
        </TableRow>

        {rows?.map((row) => (
          <TableRow key={row.convertedTime}>
            <TableCell>
              <span
                style={{
                  height: '15px',
                  width: '15px',
                  backgroundColor: colorPicker(row.temperatureC),
                  borderRadius: '50%',
                  display: 'inline-block'
                }}
              />
            </TableCell>
            <TableCell>{row.convertedTime}</TableCell>
            <TableCell>{row.convertedTemp}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatchHistoryTable);