import Button from '@mui/material/Button';
import React from 'react';
import { useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

const BackButton = (props) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const variant = props.variant ? props.variant : 'backbutton';

    const handleClick = props.goBack instanceof Function ? props.goBack : () => navigate(-1);

    return <Button id="back_button" style={props.style} variant={variant} color="primary" onClick={handleClick}>{translate('ra.action.cancel')}</Button>;
}

export default BackButton;
