import React, { useRef, useEffect, useState } from "react";
import { init } from "echarts";
import { Card, Slider, TextField, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import GraphClick from "./GraphClick";

const Donut = ({ hospital, variable, min, max, start, name, active }) => {
    const translate = useTranslate();
    const chartRef = useRef(null);
    const [myChart, setMyChart] = useState({});
    const [data, setData] = useState([]);
    const [num, setNum] = useState(start || 1);

    useEffect(() => {
        const array = [];
        const other = {
            value: 0,
            name: translate('dashboard.other', { num: num }),
            itemStyle: {
                color: '#ddd'
            }
        };
        hospital[1] && hospital[1].forEach(e => {
            if (e[variable] < num) {
                other.value += e[variable]
            } else {
                array.push({ name: e.shortName, value: e[variable] });
            };
        });
        array.sort((a, b) => b.value - a.value);
        num !== 1 && array.push(other);
        setData(array);
    }, [hospital, num]); // eslint-disable-line

    useEffect(() => {
        // Initialize chart
        chartRef.current && setMyChart(init(chartRef.current, "light"));
    }, []); // eslint-disable-line

    let option = {
        legend: {
            type: 'scroll',
            orient: 'vertical',
            left: 10,
            top: 20,
            bottom: 20
        },
        series: [
            {
                center: ['68%', '42%'],
                type: 'pie',
                radius: ['32%', '70%'],
                itemStyle: {
                    borderRadius: 5,
                    borderColor: '#fff',
                    borderWidth: 1
                },
                label: {
                    show: false,
                    position: 'center',
                    formatter: `{b}\n{c} ${name}\n{d}%`
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                data: data
            }
        ]
    };

    if (option && typeof option === 'object' && Object.keys(myChart).length > 0) {
        myChart.setOption(option);
        myChart.on('click', (params) => {
            if (!params.name.match(/fewer/i)) {
                GraphClick(hospital[1].find((x) => x.shortName === params.name));
            };
        });

        const suppressSelection = (chart, params) => {
            chart.setOption({ animation: false });
            chart.dispatchAction({
                type: 'legendSelect',
                name: params.name
            });
            chart.setOption({ animation: true });
        };

        myChart.on('legendselectchanged', (params) => {
            suppressSelection(myChart, params);
            if (!params.name.match(/fewer/i)) {
                GraphClick(hospital[1].find((x) => x.shortName === params.name));
            };
        });
    };

    return (
        <Card sx={{ width: '500px', height: '400ox' }}>
            <Typography id="total_title" fontWeight="bold" variant="h6" textAlign="center" m={1}>
                {translate(`dashboard.${active ? 'active' : 'total'}`)} {name}
            </Typography>
            <div ref={chartRef} style={{ height: '400px' }} />
            <Typography variant="caption" ml="50px">
                {translate('dashboard.group')}
                <TextField
                    sx={{ width: '50px', mx: 1 }}
                    type="number"
                    value={num}
                    size="small"
                    onChange={(e) => setNum(Number(e.target.value))}
                    InputProps={{ inputProps: { min: min, max: max } }}
                />
                {name}
            </Typography>
            <Slider
                sx={{ width: '300px', ml: '50px' }}
                value={num}
                min={min}
                max={max}
                onChange={(e, n) => setNum(n)}
            />
        </Card >
    );
}

export default Donut;
