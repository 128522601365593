import React, { useEffect, useState } from 'react';
import customDataProvider from "../dataProvider/customDataProvider";

const withRoles = (WrappedComponent) => (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isSysadmin, setIsSysAdmin] = useState(false);
    const [isClinician, setIsClinician] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (!hasLoaded && !isLoading) {
            setIsLoading(true);

            customDataProvider.getUserInfo()
                .then(userInfo => {
                    if (isMounted && userInfo.response && userInfo.response.roles) {
                        userInfo.response.roles.forEach((role) => {
                            if (role.role === "TENANT_ADMIN") {
                                setIsClinician(true);
                            } else if (role.role === "SYSADMIN") {
                                setIsSysAdmin(true);
                            }
                        });
                    }
                }).finally(() => {
                    if (isMounted) {
                        setHasLoaded(true);
                        setIsLoading(false);
                    }
                });
        }
        return () => { isMounted = false };
    }, []); // eslint-disable-line
    return (
        <WrappedComponent
            isSysadmin={isSysadmin}
            isClinician={isClinician}
            {...props}
        />);
};

export default withRoles;