import React, { useRef, useEffect, useState, useContext } from "react";
import { connect } from 'react-redux';
import { init } from "echarts";
import customDataProvider from "../dataProvider/customDataProvider";
import {
    numConvertFromC, numConvertFromI,
    numConvertFromInternalToAxillaryC,
    numConvertFromInternalToOralC
} from "../tempscale/tempConvert";
import { Box, Typography } from "@mui/material";
import { dateFormat } from './dateFormat';
import { useTranslate } from "react-admin";
import { Context } from "../dataProvider/context";

const TemperatureGraph = ({ record, temp }) => {
    const { resize, setResize } = useContext(Context);
    const translate = useTranslate();
    const lowTempThreshold = numConvertFromI(localStorage.getItem("lowTempThreshold"));
    const highTempThreshold = numConvertFromI(localStorage.getItem("highTempThreshold"));
    const lowTemp = numConvertFromC(lowTempThreshold, temp);
    const highTemp = numConvertFromC(highTempThreshold, temp);
    const alertTemp = numConvertFromC(numConvertFromI(localStorage.getItem("alertTemp")), temp);
    const chartRef = useRef(null);
    const timerRef = useRef(null);
    const idRef = useRef(record?.patchStatus?.id);
    const modelNum = record?.patchStatus?.modelNum;
    const componentMounted = useRef(true);
    const [myChart, setMyChart] = useState({});
    const [timeIndexValues, setTimeIndexValues] = useState([]);
    const [tempIndexValues, setTempIndexValues] = useState([]);
    const adjustForOral = localStorage.getItem("adjustForOral") === 'true';
    const href = window.location.href.match(/show\/1/);
    const npa = !record?.patchStatus?.statusCode.match(/NPA/i);
    let option;

    const colorChooser = (val) => {
        if (val <= lowTemp) return '#4BC4E3';
        if (val < highTemp && val > lowTemp) return '#71C6A8';
        if (val < alertTemp && val >= highTemp) return '#F49C66';
        if (val >= alertTemp) return '#F26666';
        return '#000000';
    };

    const refreshData = (id) => {
        clearTimeout(timerRef.current);

        if (id === 0) {
            setTempIndexValues([]);
            setTimeIndexValues([]);
        }
        if (id > 0) {
            customDataProvider.getHistory(id)
                .then(({ 0: { history } }) => {
                    const startIndex = 0;
                    const startTime = record?.patchStatus?.startTime;
                    const gapTime = modelNum * 120000;
                    const gapNum = modelNum * 2;
                    const arr = []
                    for (let i = 0; i < 720; i++) {
                        const historyIndex = history.findIndex((obj) => obj.timeIndex === startIndex + (gapNum * i))
                        if (historyIndex === (-1)) {
                            arr.push({
                                measurementTime: startTime + (gapTime * i),
                                temperatureC: 0
                            })
                        } else {
                            arr.push({
                                measurementTime: history[historyIndex].measurementTime,
                                temperatureC: history[historyIndex].temperatureC
                            })
                        }
                    }
                    if (componentMounted.current && idRef.current) {
                        const tempVal = [];
                        const timeVal = [];
                        arr.forEach((obj) => {
                            if (obj.temperatureC === 0) obj.temperatureC = 2945;
                            const displayTemp = adjustForOral
                                ? numConvertFromInternalToOralC(obj.temperatureC)
                                : numConvertFromInternalToAxillaryC(obj.temperatureC);
                            const convertedDisplayTemp = numConvertFromC(displayTemp, temp);

                            timeVal.push(dateFormat(obj.measurementTime));
                            tempVal.push({
                                value: convertedDisplayTemp,
                                itemStyle: {
                                    color: colorChooser(convertedDisplayTemp)
                                }
                            })
                        });
                        setTempIndexValues(tempVal);
                        setTimeIndexValues(timeVal);
                    }
                });
        }
        timerRef.current = setTimeout(() => {
            //setIsLoading(false);
            refreshData(id)
        }, 60 * 1000);
    }

    useEffect(() => {
        idRef.current = record.patchStatus.id;
        refreshData(idRef.current);
    }, [record.patchStatus.id]); // eslint-disable-line

    useEffect(() => {
        // Initialize chart
        if (chartRef.current !== null) {
            refreshData(idRef.current);
            setMyChart(init(chartRef.current, null));
        }
        return () => {
            clearTimeout(timerRef.current);
            componentMounted.current = false;
        };
    }, []); // eslint-disable-line

    option = {
        animation: false,
        tooltip: {
            show: true,
            formatter: (params) => {
                if (params.componentType === 'markLine') return `<b>${params.value} °${temp}</b>`
                return `${params.name}<br /> <b>${params.marker}${params.value} °${temp}</b>`
            }
        },
        dataZoom: [
            {
                type: 'inside'
            },
            {
                type: 'slider',
                showDetail: false
            }
        ],
        xAxis: {
            type: 'category',
            data: timeIndexValues,
        },
        yAxis: {
            type: 'value',
            max: Math.ceil(numConvertFromC(43, temp)),
            min: Math.floor(numConvertFromC(30, temp)),
            axisLabel: {
                formatter: `{value} °${temp}`
            }
        },
        grid: {
            bottom: 60,
            containLabel: true
        },
        series: [
            {
                name: 'Temp',
                type: 'bar',
                data: tempIndexValues,
                stack: 'temperature',
                markLine: {
                    symbol: ['none', 'none'],
                    data: [
                        {
                            symbol: 'circle',
                            yAxis: highTemp,
                            label: {
                                show: false,
                                position: 'middle',
                                formatter: ''
                            },
                            lineStyle: {
                                type: 'solid',
                                color: '#F49C66',
                                width: 2
                            },
                        },
                        {
                            symbol: 'circle',
                            yAxis: alertTemp,
                            label: {
                                show: false,
                                position: 'middle',
                                formatter: ''
                            },
                            lineStyle: {
                                type: 'solid',
                                color: '#F26666',
                                width: 2
                            },
                        },
                        {
                            symbol: 'circle',
                            yAxis: lowTemp,
                            label: {
                                show: false,
                                position: 'middle',
                                formatter: ''
                            },
                            lineStyle: {
                                type: 'solid',
                                color: '#4BC4E3',
                                width: 2
                            },
                        }
                    ],
                }
            }
        ]
    };

    if (option && typeof option === 'object' && Object.keys(myChart).length > 0) {
        myChart.setOption(option);
    }

    if (Object.keys(myChart).length > 0) {
        window.addEventListener('resize', myChart.resize);
        if (resize) {
            setTimeout(() => {
                myChart.resize();
                setResize(false);
            }, 250);
        }
    }

    return (
        <>
            {!!npa ?
                <Box
                    id="gCont"
                    sx={{
                        position: 'relative',
                        margin: '0px',
                        flex: 1,
                        maxWidth: '100%',
                        height: '400px'
                    }}
                >
                    {!!href &&
                        <Typography noWrap>
                            {translate('resources.patch.label')}: <b>{record.patchStatus.patchId}</b>
                        </Typography>
                    }
                    <div
                        ref={chartRef}
                        style={{
                            width: '100%',
                            height: '400px',
                            marginTop: '-30px'
                        }}
                    />

                </Box>
                : <>
                    {!!href && translate('resources.patch.NPA')}
                </>}
        </>
    );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(TemperatureGraph);
