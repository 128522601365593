import React from 'react';
import { Route } from 'react-router-dom';
import DisplayOptionsPage from './settings/displayOptionsPage';
import About from './about';
import PatientTransferContainer from '../patients/PatientTransferContainer';
import ChangePasswordWithTheme from './ChangePassword';
import AcknowledgementWithTheme from './Acknowledgement';
import Dashboard from '../dashboard/Dashboard';
import ResetPassword from './ResetPassword';

export default [
                <Route exact path="/displayOptions" component={DisplayOptionsPage} />,
                <Route exact path="/about" component={About} />,
                <Route exact path="/changePassword" component={ChangePasswordWithTheme} noLayout />,
                <Route exact path="/acknowledgement" component={AcknowledgementWithTheme} noLayout />,
                <Route exact path="/patientTransfer" component={PatientTransferContainer} />,
                <Route exact path="/dashboard" component={Dashboard} />,
                <Route path="/resetPassword" component={ResetPassword} noLayout />
               ];
