import PatientSummaryShow from './patientShow';
import PatientEdit from './patientEdit';
import PatientCreate from './PatientCreate';
import GroupIcon from '@mui/icons-material/Group';
import PatientSummaryGrid from "./patientList";

export const UserIcon = GroupIcon;

export default {
  icon: UserIcon,
  options: { label: 'Patients' },
  list: PatientSummaryGrid,
  create: PatientCreate,
  edit: PatientEdit,
  show: PatientSummaryShow
};
