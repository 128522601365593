import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import "./styles/GridList.css"
import { connect } from 'react-redux';
import _ from 'lodash';
import { useListContext, useRefresh } from "react-admin";
import { PatientTile } from './Grid';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
// const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

const normalStyle = {
    boxSizing: 'border-box',
    height: '162px',
    width: '162px',
    margin: '4px',
    borderRadius: '4px',
    backgroundColor: '#FFF'
}

const LoadedGridList = (props) => {
    const seconds = 60 * 1000;
    const { data } = useListContext();
    const timerRef = useRef(null);
    const refresh = useRefresh();
    const [alert, setAlert] = useState();

    const refreshData = () => {
        localStorage.setItem('patientsLoaded', 'true');
        let newEvent = new Event("storage");
        newEvent.key = 'updatepatchstatus';
        window.dispatchEvent(newEvent);
        refresh();
        timerRef.current = setTimeout(function () { refreshData() }, seconds);
    }
    useEffect(() => {
        let isMounted = true;

        refreshData();

        window.addEventListener('storage', (e) => {
            if (isMounted && e.key && e.key === 'updateAlertFrame') {
                setAlert(JSON.parse(localStorage.getItem("alertList")));
            }
        });

        return () => {
            window.removeEventListener('storage', (e) => {
                if (isMounted && e.key && e.key === 'updateAlertFrame') {
                    setAlert(JSON.parse(localStorage.getItem("alertList")));
                }
            });
            clearTimeout(timerRef.current);
            isMounted = false;
        };
    }, []); // eslint-disable-line


    if (!data) { return null; }
    return (
        <Grid
            container
            id="patientLoadedGrid"
            key="patientLoadedGrid"
            sx={{
                width: 'fit-content',
                backgroundColor: '#182F46',
                justifyContent: { xs: 'center', md: 'flex-start' },
                ml: { xs: 0, md: 2 },
                py: '4px'
            }}
        >
            {data.map(record => (
                <PatientTile alert={alert} normalStyle={normalStyle} key={record.id} {...props} record={record} />
            ))}
        </Grid>
    );
}

const sortData = ({ currentSort, data }) => {
    let sortedData = data;

    if (!data) {
        return [];
    }

    if (!currentSort) {
        return data;
    }

    if (currentSort.field === 'patchStatus') {
        if (currentSort.order === 'ASC') {
            sortedData = _.orderBy(data, function (e) { return ['TLO', 'TOK', 'THI', 'NRD', 'ERR', 'FIN', 'NOS', 'NPA'].indexOf(e.patchStatus.statusCode) + '_' + e.patchStatus.temperatureActual + '_' + e.patchStatus.startTime + '_' + e.patientCode }, 'asc');
        } else {
            sortedData = _.orderBy(data, function (e) { return ['NPA', 'NOS', 'FIN', 'ERR', 'NRD', 'TLO', 'TOK', 'THI'].indexOf(e.patchStatus.statusCode) + '_' + e.patchStatus.temperatureActual + '_' + e.patchStatus.startTime + '_' + e.patientCode }, 'desc');
        }
    } else {
        sortedData = _.orderBy(data, [element => element[currentSort.field] && element[currentSort.field].toLowerCase()], currentSort.order.toLowerCase());
    }

    return sortedData;
}

const ImageList = ({ loaded, ...props }) => {
    const sortedData = sortData({ currentSort: props.currentSort, data: props.data });
    return (
        <div style={{ backgroundColor: '#182F46' }}>
            <LoadedGridList {...props} sortedData={sortedData} />
        </div>
    );
}

const mapStateToProps = state => ({ displayAlertTemps: state.displayAlertTemps, displayPatchCount: state.displayPatchCount, displayNRDTimer: state.displayNRDTimer, localSearch: state.localSearch, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(ImageList);