import { parse } from 'query-string';
import React, { useRef } from 'react';
import { FunctionField, Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField, useRecordContext, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import customDataProvider from "../dataProvider/customDataProvider";
import { dateFormat } from "./dateFormat";
import PatchHistoryExport from "./PatchHistoryExport";
import PatchHistoryTable from "./PatchHistoryTable";
import PatchShowActions from "./PatchShowActions";
import PatchDiagnostics from "./PatchDiagnostics";
import TemperatureGraph from "./TemperatureGraph";

const Title = () => {
  const record = useRecordContext(),
    translate = useTranslate(),
    displayTitle = record ? record.patchName : "";

  return <span>{translate('resources.patch.label')}: {displayTitle}</span>;
};



const PatchSummaryShow = (props) => {
  const href = window.location.href;
  const patient = parse(href.split("?")[1]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const { alertTemp, config } = props;
  const componentMounted = React.useRef(true);
  const timerRef = useRef(null);

  const CustomTabbedShowLayout = () => {
    const record = useRecordContext();

    if (!record || record.id === 0) {
      return (
        <SimpleShowLayout>
          <TextField source="patchName" label="resources.patch.patchid" id="patchId" />
          <FunctionField id="patientID" label="resources.patient.patientidlabel" render={() => `${patient.patientCode}`} />
        </SimpleShowLayout>
      )
    }

    return (
      <TabbedShowLayout>
        <Tab label="resources.patch.graph" id="graph">
          <TemperatureGraph record={record} />
        </Tab>
        <Tab label="resources.patch.summary" path="summary" id="summary">
          <TextField source="patchName" label="resources.patch.patchid" id="patchId" />
          <FunctionField id="start_time" label="resources.patch.starttime" source='startTime' render={record => `${dateFormat(record.starttime)}`} />
          <FunctionField id="patientID" label="resources.patient.patientidlabel" render={() => `${patient.patientCode}`} />
        </Tab>
        <Tab label="resources.patch.history" path="history" id="history">
          <PatchHistoryTable reference="PatchHistory" source="patchID" target="patchID" label=""
            alertTemp={localStorage.getItem("alertTemp")}
            refreshData={refreshData()}
            data={data} />
        </Tab>
        {/* <Tab label="Graph (line)">
                                <PatchHistoryGraphAnnotation reference="PatchHistory" source="patchID" target="patchID" label="" alertTemp={alertTemp}/>
                            </Tab> */}
        {
          config?.parameters?.enableExport &&
          <Tab label="resources.patch.export" path="export" id="export">
            <PatchHistoryExport reference="PatchHistory"
              source="patchID"
              target="patchID"
              label=""
              alertTemp={alertTemp}
              id={patient.patientId}
              patientID={patient.patientCode} />
          </Tab>
        }
        {
          localStorage.getItem('isSysAdmin') === 'true' &&
          <Tab label="settings.diagnostics" path="diagnostics" id="diagnostics">
            <PatchDiagnostics reference="PatchHistory"
              source="patchID"
              target="patchID"
              label=""
              alertTemp={alertTemp}
              id={patient.patientId}
              patientID={patient.patientCode} />
          </Tab>
        }
      </TabbedShowLayout>
    );
  }

  const refreshData = () => (patchId) => {
    if (isLoading) {
      setData(undefined);
      return;
    }
    setIsLoading(true);

    if (componentMounted.current) {
      customDataProvider.getHistory(patchId)
        .then(data => {
          if (data && data.length === 1) {
            setData(data[0]);
            setIsLoading(false);
          }
        });
    }

    let newEvent = new Event("storage");
    newEvent.key = 'updatepatchstatus';
    window.dispatchEvent(newEvent);

    setIsLoading(false);

    timerRef.current = setTimeout(() => {
      setIsLoading(false);
      refreshData()(patchId)
    }, 60 * 1000);
  };

  React.useEffect(() => {
    setIsLoading(false);

    return () => {
      clearTimeout(timerRef.current);
      componentMounted.current = false;
    }
  }, []); // eslint-disable-line

  if (!patient) {
    return null;
  }
  return (
    <Show title={<Title />}
      actions={<PatchShowActions config={config}
        patientId={patient.patientId}
        patientCode={patient.patientCode} />
      }>
      <CustomTabbedShowLayout />
    </Show>
  );
}

const mapStateToProps = state => ({ alertTemp: state.temp, config: state.config, hospital: state.hospital });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatchSummaryShow);