import React from 'react';
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveHospitalId } from "../hospitals/ActiveHospital";
import PatientTransfer from "./PatientTransfer";

const PatientTransferContainer = (props) => {

    const movePatient = () => {
        customDataProvider.movePatient(JSON.stringify({
            dstHospitalCode: getActiveHospitalId(),
            srcPatientCode: document.getElementById("kitId").value,
            srcPatientPassword: document.getElementById("password").value.trim()
        }));
    };

    return <PatientTransfer
        {...props}
        movePatient={movePatient}
    />
}

export default PatientTransferContainer;