import React from "react";
import {
    Button,
    Datagrid,
    Filter,
    FunctionField,
    List,
    Pagination,
    SimpleList,
    TextField,
    TextInput,
    TopToolbar,
    useTranslate
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Responsive from "../ui/Responsive";
import { Fab } from "@mui/material";

const KeywordFilter = ({ ...props }) => {
    return (
        <Filter {...props}>
            <TextInput key={"searchText"} resettable style={{ paddingBottom: '12px' }} label="ra.action.search" source="q" alwaysOn />
        </Filter>
    );
};

const EntityListActions = ({ hasCreate, ...props }) => (
    <Responsive
        xsmall={
            <Fab
                component={Link}
                to="/users/create"
                style={{
                    right: '20px',
                    bottom: '60px',
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1000
                }}
                color="primary"
            >

                <AddIcon />
            </Fab>
        }
        medium={
            <TopToolbar {...props} style={{ paddingBottom: "15px" }}>
                {(localStorage.getItem('isAdmin') === 'true' || localStorage.getItem('isSysAdmin') === 'true') &&

                    <Button
                        id="adduser"
                        style={{ paddingTop: '4px', marginLeft: '10px' }}
                        variant="contained"
                        component={Link}
                        to="/users/create"
                        label="user.create.add"
                    >
                        <AddIcon id="addbutton" />
                    </Button>
                }
            </TopToolbar>
        }
    />
);

const CustomPagination = props => <Pagination id="paginate" rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const UserList = props => {
    const translate = useTranslate();

    return (
        <List {...props}
            pagination={<CustomPagination />} perPage={25}
            sort={{ field: "lname", order: "ASC" }}
            filters={<KeywordFilter />}
            style={{ paddingLeft: '10px', paddingRight: '10px', minHeight: '0px', borderRadius: '5px', backgroundColor: '#FFFFFF' }}
            actions={<EntityListActions />}
        >
            <Responsive
                xsmall={
                    <SimpleList
                        linkType="show"
                        primaryText={<TextField id="username" source="username" />}
                        secondaryText={record => `${record.lName ? record.lName + ',' : ''} ${record.fName ? record.fName : ''}`}
                        tertiaryText={record => `${record.active ? translate('resources.hospital.active') : translate('gateways.inactive')}`}
                    />
                }
                medium={
                    <Datagrid bulkActionButtons={false} rowClick='show' {...props} >
                        <FunctionField
                            sortBy="lname"
                            id="name"
                            label="user.list.name"
                            render={record => `${record.lName ? record.lName + ',' : ''} ${record.fName ? record.fName : ''}`}
                        />
                        <TextField id="username" label="ra.auth.username" source="username" />
                        <FunctionField
                            sortBy="active"
                            id="active"
                            label="user.create.active"
                            render={record => `${record.active ? translate('resources.hospital.active') : translate('gateways.inactive')}`}
                        />
                    </Datagrid>
                }
            />
        </List>
    )
};