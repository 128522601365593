import LockIcon from '@mui/icons-material/Lock';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useRef } from 'react';
import {
    Notification,
    PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Title,
    Toolbar,
    useRedirect,
    useTranslate
} from 'react-admin';
import customDataProvider from "../dataProvider/customDataProvider";

const styles = makeStyles(theme =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: '#182F46'
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary.main,
        },
        hint: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.grey[500],
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            width: '250px',
            marginTop: '1em',
        },
        actions: {
            padding: '0 1em 1em 1em',
        },
    })
);

const ResetPasswordToolbar = () => (
    <Toolbar id="resetPasswordToolbar">
        <SaveButton
            icon={<div />}
            style={{ width: '100%' }}
            label="resources.patient.transfersubmit"
            variant="contained"
            id="resetPasswordSave"
            submitonenter="true"
            type="submit"
        />
    </Toolbar>
)

const validation = (translate, password, matchPassword, username) => {
    const errors = {};

    if (username.current === null || username.current.length === 0) {
        errors.username = translate('ra.validation.required');
    }
    if (password.current === null || password.current.length === 0) {
        errors.newPass = translate('changepass.newrequired');
    }
    if (matchPassword.current === null || matchPassword.current.length === 0) {
        errors.matchPass = translate('changepass.retype');
    } else if (matchPassword.current.trim() !== password.current.trim()) {
        errors.matchPass = translate('changepass.mustmatch');
    }

    return errors;
}

const ResetPassword = () => {
    const classes = styles();
    const translate = useTranslate();
    const redirect = useRedirect();
    const matchPassword = useRef(null);
    const password = useRef(null);
    const url = window.location.href.replace('/#', '');
    const params = new URLSearchParams(url.substring(url.indexOf('?') + 1));
    const username = useRef(null);
    const token = params.get('token');

    const submit = () => {
        customDataProvider.resetUserPassword(password.current.trim(), token, username.current.trim()).then(() => {
            redirect('/login');
            return Promise.resolve();
        });
    };

    const validatePasswords = () => {
        return validation(translate, password, matchPassword, username);
    }

    return (
        <div className={classes.main}>
            <Title id="resetPasswordTitle" title="changepass.change" />
            <Card id="change_card" className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar id="change_lock_icon" className={classes.icon}>
                        <LockIcon style={{ height: '24px', width: '24px' }} />
                    </Avatar>
                </div>
                <div id="change_title" className={classes.hint}>{translate('changepass.enter')}<br /></div>
                <SimpleForm
                    sx={{ marginTop: '-10px' }}
                    validate={validatePasswords}
                    onSubmit={submit}
                    toolbar={<ResetPasswordToolbar
                    />}>
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <TextInput
                                id="username"
                                name="username"
                                source="username"
                                onChange={e => username.current = e.target.value}
                                label={translate('ra.auth.username')}
                                validate={required()}
                                sx={{ width: '100%' }}
                            />
                        </div>
                        <div className={classes.input}>
                            <PasswordInput
                                id="newPass"
                                name="newPass"
                                onChange={e => password.current = e.target.value}
                                label={translate('changepass.pass')}
                                source="newPass"
                                validate={required()}
                                sx={{ width: '100%' }}
                            />
                        </div>
                        <div className={classes.input}>
                            <PasswordInput
                                id="matchPass"
                                name="matchPass"
                                onChange={e => matchPassword.current = e.target.value}
                                label={translate('changepass.retypepass')}
                                source="matchPass"
                                validate={required()}
                                sx={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </SimpleForm>
            </Card>
            <Notification />
        </div>
    )
}

export default ResetPassword;