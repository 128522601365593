import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { legacy_createStore as createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import dataProvider from './dataProvider/dataProvider';
import authProvider from './dataProvider/authProvider';
import TempTraqLogin from './custom/TempTraqLogin';
import MyLayout from './custom/MyLayout';
import customRoutes from './custom/routes';
import {
    temp,
    monitorSortBy,
    monitorSortDir,
    displayAlertTemps,
    displayNRDTimer,
    localSearch
} from './custom/settings/reducer';
import { hospital, config } from './hospitals/hospitalReducer';
import gateways from './gateways/index';
import users from './users/index';
import configurations from './configurations/index';
import hospitals from './hospitals/index';
import patients from './patients';
import patches from './patches';
import { muiTheme } from "./custom/themes";
import { StyledEngineProvider } from "@mui/material";
import i18nProvider from './i18n/i18nProvider';
import { Route } from "react-router-dom";
import About from "./custom/about";
import ChangePasswordWithTheme from "./custom/ChangePassword";
import AcknowledgementWithTheme from "./custom/Acknowledgement";
import PatientTransferContainer from "./patients/PatientTransferContainer";
import DisplayOptionsPage from './custom/settings/displayOptionsPage';
import Dashboard from './dashboard/Dashboard';
import { Context } from './dataProvider/context';
import ResetPassword from './custom/ResetPassword';

const messages = {
    resources: {
        PatientSummary: {
            name: "Patient"
        }
    }
};

export const appDataProvider = dataProvider();
const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND.trim().toLowerCase() : "temptraq";
const favicon = document.getElementById("favicon");

if (brand === 'ct360') {
    document.title = "Cell Therapy 360";
    favicon && favicon.setAttribute("href", "ctfavicon.ico");
} else if (brand === 'temptraq') {
    document.title = "TempTraq Clinician";
    favicon && favicon.setAttribute("href", "ttfavicon.ico");
}

const customReducers = {
    hospital: hospital,
    config: config,
    temp: temp,
    displayAlertTemps: displayAlertTemps,
    displayNRDTimer: displayNRDTimer,
    monitorSortBy: monitorSortBy,
    monitorSortDir: monitorSortDir,
    localSearch: localSearch
};
const store = createStore(combineReducers(customReducers));
const App = () => {
    const { setContext } = React.useContext(Context);
    return (
        <StyledEngineProvider injectFirst>
            <Provider store={store}>
                <Admin
                    requireAuth
                    theme={muiTheme}
                    i18nProvider={i18nProvider}
                    dataProvider={dataProvider(setContext)}
                    authProvider={authProvider}
                    loginPage={TempTraqLogin}
                    layout={MyLayout}
                    customRoutes={customRoutes}
                    messages={messages}
                    disableTelemetry>
                    {/* <Resource name="users" list={ListGuesser} /> */}
                    <CustomRoutes>
                        <Route exact path="/displayOptions" element={<DisplayOptionsPage />} />
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route exact path="/about" element={<About />} />
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route exact path="/changePassword" element={<ChangePasswordWithTheme />} noLayout />
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route exact path="/acknowledgement" element={<AcknowledgementWithTheme />} noLayout />
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route exact path="/patientTransfer" element={<PatientTransferContainer />} />,
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route exact path="/dashboard" element={<Dashboard />} />,
                    </CustomRoutes>
                    <CustomRoutes>
                        <Route path="/resetPassword" element={<ResetPassword />} noLayout />
                    </CustomRoutes>
                    <Resource name="PatientSummary" {...patients} />
                    <Resource name="gateways" {...gateways} />
                    <Resource name="configurations" {...configurations} />
                    <Resource name="HospitalSummary" {...hospitals} />
                    <Resource name="users" {...users} />
                    <Resource name="PatchSummary" {...patches} />
                    <Resource name="PatchHistory" />
                    <Resource name="patientassignments" />
                    <Resource name="tenantassignments" />
                    <Resource name="tenantconfigurations" />
                    <Resource name="tenantintegrations" />
                </Admin>
            </Provider>
        </StyledEngineProvider>
    )
};

export default App;