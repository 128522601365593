import React from 'react';
// import { stringConvertFromC, numConvertFromI } from '../tempscale/tempConvert';
import { dateFormat } from './dateFormat';
import {useTranslate} from "react-admin";

export const ReplacePatchList = () => {
    const translate = useTranslate();
    return <span id="status_ERR" className="err statusWithBackground list" style={{fontSize:'1.4em'}}>{translate('resources.patch.replacepatch')}</span>
}

export const PatchCompletedList = ({ endTime }) => {
    const translate = useTranslate();
    const timeAndDate = dateFormat(endTime)
    return <div id="status_FIN" className="timeCompleted">{translate('resources.patch.completed')} {timeAndDate}</div>
}

// not used

// export const HiList = ({ statusText }) => {
//     return <span className="temphi statusWithBackground">{statusText}</span>
// }

// export const LoList = ({ statusText }) => {
//     return <span className="templo statusWithBackground">{statusText}</span>
// }

// export const TemperatureList = ({ result, lowTemp, highTemp, alertTemp, gridOrList, tempFormat, adjustForOral }) => {
//     const alertTempC = numConvertFromI(alertTemp);
//     const lowTempC = numConvertFromI(lowTemp);
//     const highTempC = numConvertFromI(highTemp);
//     const currentTemp = numConvertFromI(result.temperatureOral);

//     let color = '#4BC4E3' // low
//     if (currentTemp > lowTempC) {
//         color = '#71C6A8' // normal
//     }
//     if (currentTemp >= highTempC) {
//         color = '#F49C66' // fever
//     }
//     if (currentTemp >= alertTempC) {
//         color = '#F26666'  // alert
//     }
//     if (alertTempC == null || isNaN(alertTempC)) {
//         color = '#FFFFFF'
//     }
//     return <div>
//         <span className={`temperature list`} style={{ backgroundColor: color }}>{stringConvertFromC(currentTemp, tempFormat)}</span>
//     </div>
// }

// export const NoRecentDataList = () => {
//     const translate = useTranslate();
//     return <span className="nrd statusWithBackground"  style={{fontSize:'1.4em', paddingLeft:'15px', paddingRight:'15px'}}>{translate('resources.patch.norecentdata')}</span>
// }