export const validatePassword = (type, config, translate) => function(password) {
    let errors;
    let specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    if (password === undefined) {
        return;
    } else {
        password = password.trim();
    }

    if(password.length===0) {
        return;
    }

    let requireLength;
    let requireUpper;
    let requireLower;
    let requireDigit;
    let requireSpecial;

    if(type==='cpw') {
        requireLength = config.parameters.cpwClinicianRequireLength;
        requireUpper = config.parameters.cpwClinicianRequireUpper;
        requireLower = config.parameters.cpwClinicianRequireLower;
        requireDigit = config.parameters.cpwClinicianRequireDigit;
        requireSpecial = config.parameters.cpwClinicianRequireSpecial;
    } else {
        requireLength = config.parameters.ppwPatientRequireLength;
        requireUpper = config.parameters.ppwPatientRequireUpper;
        requireLower = config.parameters.ppwPatientRequireLower;
        requireDigit = config.parameters.ppwPatientRequireDigit;
        requireSpecial = config.parameters.ppwPatientRequireSpecial;
    }

    if (requireLength > 0 && password.length < requireLength) {
        errors = translate('resources.password.requiredlength', { length: requireLength} );
    }

    if (requireUpper && !/[A-Z]/.test(password)) {
        errors = translate('resources.password.uppercaseletter');
    }
    if (requireLower && !/[a-z]/.test(password)) {
        errors = translate('resources.password.lowercaseletter');
    }
    if (requireDigit && !/\d/.test(password)) {
        errors = translate('resources.password.containnumber');
    }
    if (requireSpecial && !specialCharacters.test(password)) {
        errors = translate('resources.password.specialcharacter');
    }

    return errors;
}