import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'recompose/compose';
import Card from '@mui/material/Card';
import { createStyles, makeStyles } from '@mui/styles';
import {
    PasswordInput,
    required,
    SaveButton,
    SimpleForm,
    TextInput,
    Title,
    Toolbar,
    useRedirect,
    useTranslate
} from 'react-admin';
import BackButton from "../ui/BackButton";
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveGroupCode, getActiveHospitalId, getActiveHospitalShortName } from "../hospitals/ActiveHospital";
import TextField from '@mui/material/TextField';

const styles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden'
        },
        container: {
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
        },
        paper: {
            textAlign: 'center',
            height: '150px',
            width: '150px'
        },
        grid: {
            margin: 'auto',
        },
        gridList: {
            width: 1000,
            height: 1000,
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: 'translateZ(0)',
        },
        titleBar: {
            background: "#182F46"
        },
        icon: {
            color: 'white',
        },
        pagination: {
            backgroundColor: "#182F46"
        },

    })
);

const TransferToolbar = () => {
    const redirect = useRedirect();
    const translate = useTranslate();

    return (
        <Toolbar style={{ paddingLeft: '30px' }} id="changePasswordToolbar">
            <SaveButton
                icon={<div />}
                alwaysEnable
                label={translate('resources.patient.transfersubmit')}
                variant="contained"
                id="changePasswordSave"
                submitonenter="true"
                type="submit"
            />
            <BackButton
                goBack={() => redirect('list', 'PatientSummary')}
                id="patientCreateBack"
                submitonenter="false"
            />
        </Toolbar>
    )
}

async function validation(config, translate, password, kitId, groupCode, redirect) {
    let specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    let requireLength = config.parameters.cpwClinicianRequireLength;
    let requireUpper = config.parameters.cpwClinicianRequireUpper;
    let requireLower = config.parameters.cpwClinicianRequireLower;
    let requireDigit = config.parameters.cpwClinicianRequireDigit;
    let requireSpecial = config.parameters.cpwClinicianRequireSpecial;
    let error = false;
    const errors = {};

    if (password === null ||
        password.length === 0) {
        errors.password = translate('resources.patient.kitpasswordrequired', { length: requireLength }) + '\n';
        error = true;
    } else {
        password = password.trim();
    }

    if (kitId === null ||
        kitId.length === 0) {
        errors.kitId = translate('resources.patient.kitidrequired') + '\n';
        error = true;
    } else {
        kitId = kitId.trim();
    }

    if (groupCode === null || groupCode.length === 0) {
        groupCode = getActiveGroupCode();
    }

    if (requireLength > 0 && password.length < requireLength) {
        errors.password = translate('resources.password.requiredlength', { length: requireLength }) + '\n';
        error = true;
    }

    if (requireUpper && !/[A-Z]/.test(password)) {
        errors.password = translate('resources.password.uppercaseletter') + '\n';
        error = true;
    }
    if (requireLower && !/[a-z]/.test(password)) {
        errors.password = translate('resources.password.lowercaseletter') + '\n';
        error = true;
    }
    if (requireDigit && !/\d/.test(password)) {
        errors.password = translate('resources.password.containnumber') + '\n';
        error = true;
    }
    if (requireSpecial && !specialCharacters.test(password)) {
        errors.password = translate('resources.password.specialletter') + '\n';
        error = true;
    }

    if (!error) {
        const moveBody = JSON.stringify({ "groupCode": groupCode, "patientCode": kitId, "password": password, "dstTenantId": getActiveHospitalId() });

        return customDataProvider.movePatient(moveBody).then(() => {
            redirect('/PatientSummary');
            return Promise.resolve();
        })
    }

    return errors;
}

const PatientTransfer = (props) => {
    const redirect = useRedirect();
    const classes = styles();
    const translate = useTranslate();
    const config = props.config;
    const [password, setPassword] = useState('');
    const [kitId, setKitId] = useState('');
    const [sourceGroup, setSourceGroup] = useState('');

    const performTransfer = () => {
        return validation(config, translate, password, kitId, sourceGroup, redirect);
    }

    return (
        <div className={classes.main}>
            <Title title="resources.patient.transferkit" />
            <Card className={classes.card}>
                <SimpleForm style={{ marginTop: '-10px' }} validate={performTransfer} toolbar={<TransferToolbar  {...props} />}>
                    <div id="patientTransferLabel" className={classes.hint} style={{ margin: '20px' }}>
                        {translate('resources.patient.transferpatient', { hospitalCode: getActiveHospitalShortName() })}<br />
                    </div>
                    <div className={classes.form}>
                        {localStorage.getItem('isSysAdmin') === 'true' &&
                            <div className={classes.input} style={{ margin: '20px' }}>
                                <TextField id="sourceGroup" defaultValue={getActiveGroupCode()} label={translate('resources.patient.transfersourcehospital')} onChange={e => setSourceGroup(e.target.value)} />
                            </div>}
                        <div className={classes.input} style={{ margin: '20px' }}>
                            <TextInput name="kitId" key="kitId" onChange={e => setKitId(e.target.value)} label={translate('resources.patient.kitid')} source="kitId" />
                        </div>
                        <div className={classes.input} style={{ margin: '20px' }}>
                            <PasswordInput id="password" name="password" onChange={e => setPassword(e.target.value)}
                                label={translate('resources.patient.kitpassword')}
                                source="password"
                                validate={required()}
                            />
                        </div>
                    </div>
                </SimpleForm>
            </Card>
        </div>
    );
}

const mapStateToProps = state => ({ config: state.config });
export default compose(
    reduxForm({ form: 'patientTransfer', }),
    connect(mapStateToProps, {})
)(PatientTransfer);