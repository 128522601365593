import React from 'react';
import {
    Button,
    Datagrid,
    FilterButton,
    List,
    Pagination,
    TextInput,
    TextField,
    TopToolbar,
    SimpleList,
    useTranslate,
} from 'react-admin';
import ActivateButton from './ActivateButton';
import AddIcon from "@mui/icons-material/Add";
import { Link } from 'react-router-dom';
import Responsive from '../ui/Responsive';
import { Fab } from '@mui/material';

const keywordFilters = [
    <TextInput style={{ paddingBottom: "15px" }} resettable label="ra.action.search" source="q" alwaysOn />
];

const EntityListActions = ({ hasCreate, ...props }) => (
    <TopToolbar {...props} style={{ paddingBottom: "15px" }}>
        <FilterButton />
        {localStorage.getItem('isSysAdmin') === 'true' &&
            <Responsive
                xsmall={
                    <>
                        {localStorage.getItem('isSysAdmin') === 'true' &&
                            <Fab
                                id="addHospital"
                                style={{
                                    right: '20px',
                                    bottom: '60px',
                                    left: 'auto',
                                    position: 'fixed',
                                    zIndex: 1000
                                }}
                                color="primary"
                                component={Link}
                                to={'/hospitalsummary/create'}
                                variant="contained"
                            >
                                <AddIcon />
                            </Fab>
                        }
                    </>
                }
                medium={
                    <>
                        {localStorage.getItem('isSysAdmin') === 'true' &&
                            < Button
                                id="addHospital"
                                style={{ marginTop: '0px', marginLeft: '10px' }}
                                component={Link}
                                to={'/hospitalsummary/create'}
                                variant="contained"
                                label="resources.hospital.add"
                            >
                                <AddIcon style={{ height: '20px', width: '20px' }} />
                            </Button >
                        }
                    </>
                }
            />
        }
    </TopToolbar >
);

const CustomPagination = ({ props }) => (
    <TopToolbar>
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
    </TopToolbar>
);

// Not needed for now. This occurred when a hospital name was really long and had no spaces
// const CustomTextField = () => {
//     const record = useRecordContext();
//     return record ? (
//         <div style={{ minWidth: '20ch', wordBreak: 'break-word' }}>
//             <span id="hospital_name">{record.hospitalName}</span>
//         </div>
//     ) : null;
// };

const HospitalsummaryList = ({ props }) => {
    const translate = useTranslate();
    return (
        <List {...props}
            style={{ paddingLeft: '10px', paddingRight: '10px', minHeight: '0px', borderRadius: '5px', backgroundColor: '#FFFFFF' }}
            filters={keywordFilters}
            actions={<EntityListActions />}
            exporter={false}
            pagination={<CustomPagination />}
            empty={false}
            perPage={25}
            sort={{ field: "activePatients", order: "DESC" }}
            title="resources.hospital.list">
            <Responsive
                xsmall={
                    <SimpleList
                        linkType="show"
                        style={{ whiteSpace: 'pre-line' }}
                        primaryText={record => record?.hospitalName}
                        secondaryText={record => record?.shortName}
                        tertiaryText={record => (
                            `${translate('resources.hospital.active')}: ${record?.activePatients}\n${translate('resources.patch.completed')}: ${record?.completedPatients}\n${translate('resources.hospital.unstarted')}: ${record?.unstartedPatients}`
                        )}
                    />
                }
                medium={
                    <Datagrid bulkActionButtons={false} rowClick='show'>
                        <ActivateButton {...props} />
                        <TextField id="hospital_code" source="hospitalCode" label="resources.hospital.hospitalid" />
                        <TextField id="hospital_name" source="hospitalName" label="resources.hospital.name" />
                        {/* <TextField source="statistics.totalPatients" label="total" /> */}
                        <TextField
                            id="active_patients"
                            source="activePatients"
                            label="resources.hospital.activepats"
                            sortBy="activePatients"
                            sortByOrder="DESC"
                        />
                        <TextField
                            id="completed_patients"
                            source="completedPatients"
                            label="resources.hospital.completepats"
                            sortBy="completedPatients"
                            sortByOrder="DESC"
                        />
                        <TextField
                            id="unstarted_patients"
                            source="unstartedPatients"
                            label="resources.hospital.unstartedpats"
                            sortBy="unstartedPatients"
                            sortByOrder="DESC"
                        />
                    </Datagrid>
                }
            />
        </List>
    );

}

export default HospitalsummaryList;
