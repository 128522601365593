import ActionDelete from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Confirm, useDelete, useRecordContext, useRedirect, useTranslate } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    crudDelete,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    resource,
    saving,
    selectedIds,
    type,
    ...rest
}) => rest;

const styles = makeStyles(theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse users
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    })
);

const DeleteUserButton = (props) => {
    const { className, icon, onClick, resource, ...rest } = props;
    const classes = styles();
    const record = useRecordContext();
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const [deleteOne] = useDelete(
        'users',
        { id: record.id, previousData: record },
        {
            onSuccess: (data) => {
                redirect('list', 'users');
            }
        }
    );

    const handleClick = e => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const handleDialogClose = e => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        deleteOne();
    };

    return (
        <>
            <Button
                onClick={handleClick}
                id="deleteButton"
                label="ra.action.delete"
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                {...sanitizeRestProps(rest)}
            >
                {icon}
            </Button>
            <Confirm
                id="deleteButtonConfirm"
                isOpen={isOpen}
                title={translate('ra.action.delete') + ' ' + translate('user.detail') + '?'}
                content="ra.message.delete_content"

                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </>
    );
}

DeleteUserButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.element,
};

DeleteUserButton.defaultProps = {
    icon: <ActionDelete style={{ height: '20px', width: '20px' }} />,
};

export default DeleteUserButton;