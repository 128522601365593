export const msToHS = (ms) => {
    // const msPerSec = 1000;
    // const secPerMin = 60;
    const minPerHr = 60;
    // const secPerHr = secPerMin * minPerHr;

    const inSec = ms/1000;
    const inMin = inSec/60;
    const hr = Math.floor(inMin / minPerHr);
    const min = Math.floor(inMin % minPerHr);

    return {
        h: hr,
        m: min
    }

}