import React from 'react';
import {
    Button,
    EditButton,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import CardActions from '@mui/material/CardActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { cardActionStyle } from "../custom/themes";
import { dateFormat } from '../patches/dateFormat';

const GatewayActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <Button
            id="backToGateways"
            variant="blank"
            component={Link}
            to={`/gateways`}
            label="gateways.list"
        >
            <ArrowBackIcon />
        </Button>
        <span style={{ flex: 1 }} />
        {localStorage.getItem('isSysAdmin') === 'true' &&
            <EditButton
                style={{ marginRight: '1em' }}
                variant="outlined"
                label="gateways.edit"
                record={data}
                id="editGateway" />
        }
    </CardActions>
);

const LocationField = () => {
    const record = useRecordContext();

    if (record && record.location) {
        return (
            <TextField id="location" label="gateways.location" source="location" />
        )
    } else {
        return (
            <br />
        )
    }
};

const GatewayShow = props => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();
        return <span>{translate('gateways.label')}: {record ? record.name : ''}</span>;
    };

    const displayActiveStatus = (status) => {
        if (status) {
            return translate('dashboard.active');
        }
        return translate('gateways.inactive');
    }

    return (
        <Show {...props} actions={<GatewayActions />} title={<Title  {...props} />}>
            <SimpleShowLayout>
                <TextField id="code" label="gateways.id" source="gatewaycode" />
                <TextField id="name" label="gateways.name" source="name" />
                <FunctionField
						id="lastUpdated"
						render={record => dateFormat(record.lastupdated)}
						label="resources.misc.lastupdated"

				/>
                <LocationField id="location" label="gateways.location" source="location" />
                <FunctionField
                    id="active"
                    label="user.create.active"
                    render={record => `${displayActiveStatus(record.active)}`}
                />
            </SimpleShowLayout>
        </Show>
    )
};

export default GatewayShow;