import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import ActionDelete from '@mui/icons-material/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { useDelete, Button, Confirm, useTranslate, useRefresh, useRedirect, useRecordContext } from 'react-admin';

const sanitizeRestProps = ({
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    resource,
    saving,
    selectedIds,
    type,
    ...rest
}) => rest;

const styles = makeStyles(theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    })
);

const DeletePatchButton = (props) => {
    const { className, icon, onClick, resource, patientId, ...rest } = props;
    const refresh = useRefresh();
    const redirect = useRedirect();
    const record = useRecordContext();
    const translate = useTranslate();
    const classes = styles();
    const [isOpen, setIsOpen] = useState(false);
    const [deleteOne] = useDelete(
        'PatchSummary',
        { id: patientId, previousData: record, patientId: patientId },
        {
            onSuccess: () => {
                refresh();
                redirect(`/PatientSummary/${patientId}/show`);
                window.location.reload();
            }
        }
    );

    const handleClick = e => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const handleDialogClose = e => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const handleDelete = () => {
        deleteOne();
    };

    return (
        <Fragment>
            <Button
                onClick={handleClick}
                id="deleteButton"
                label="ra.action.delete"
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                {...sanitizeRestProps(rest)}
            >
                {icon}
            </Button>
            <Confirm
                isOpen={isOpen}
                title="resources.patch.deletepatch"
                content="ra.message.delete_content"
                translateOptions={{
                    name: inflection.humanize(
                        translate(`resources.${resource}.name`, {
                            smart_count: 1,
                            _: inflection.singularize(resource),
                        }),
                        true
                    )
                }}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

DeletePatchButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    resource: PropTypes.string.isRequired,
    icon: PropTypes.element,
};

DeletePatchButton.defaultProps = {
    icon: <ActionDelete style={{ height: '20px', width: '20px' }} />,
};

export default DeletePatchButton;