import { connect } from 'react-redux';
import { temperatureFormat } from './temperatureFormat';

const CurrentTemperatureState = (props) => {
  const tempFormat = props.temp;
  const alertTemp = props.record.alertTemp;
  const displayNRDTimer = props.displayNRDTimer;
  const lowTempThreshold = localStorage.getItem("lowTempThreshold");
  const highTempThreshold = localStorage.getItem("highTempThreshold");
  const adjustForOral = localStorage.getItem("adjustForOral") === 'true';

  return temperatureFormat(props.record.patchStatus, lowTempThreshold, highTempThreshold, alertTemp, tempFormat, displayNRDTimer, props.isGrid, adjustForOral);
}

CurrentTemperatureState.defaultProps = {
  addLabel: true,
  showUpdatedTime: true
};

const mapStateToProps = state => ({ temp: state.temp, displayNRDTimer: state.displayNRDTimer, config: state.config });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(CurrentTemperatureState);
