import React from 'react';
import { connect } from 'react-redux';
import { useRecordContext, useTranslate } from 'react-admin';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { downloadFile } from '../util/download';
import customDataProvider from "../dataProvider/customDataProvider";
import { Button } from '@mui/material';

const PatientExport = (props) => {
  const translate = useTranslate();
  const record = useRecordContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const dataAvailable = true;
  const exportData = {
    patientCode: record.patientCode,
    patientId: record.id,
    hospitalCode: props.hospital.hospitalCode,
    tempScale: props.temp
  };

  const getExport = ({ hospitalCode, patientCode, patientId, tempScale }) => {
    const exportQuery = JSON.stringify({
      "hospitalCode": hospitalCode,
      "patientCode": patientCode,
      "id": patientId,
      "scale": tempScale
    });
    setIsLoading(true);

    customDataProvider.csvExportHistory(exportQuery)
      .then(body => {
        if (body && body.csvStringData) {
          if (window.nativeInterface) {
          } else {
            downloadFile(patientCode + ".csv", decodeURI(body.csvStringData));
          }
        } else if (body) {
          if (window.nativeInterface) {
          } else {
            downloadFile(patientCode + ".csv", body);
          }
        }
        setIsLoading(false);
        return Promise.resolve();
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        window.alert(translate('resources.misc.error'));
      });
  };

  return (
    <div>
      <br />
      {dataAvailable ?
        <div>
          <Button
            sx={{ height: 'fit-content' }}
            disabled={isLoading}
            variant="contained"
            onClick={() => getExport(exportData)}
            id="patientExportButton"
            color="primary">
            <ImportExportIcon style={{ height: '20px', width: '20px' }} />
            {isLoading ? translate('resources.misc.processingdata') : translate('resources.patient.exportcsv')}
          </Button>
        </div> : translate('resources.misc.loadingdata')}
    </div>
  );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatientExport);
