import React from 'react';
import {
    Button,
    EditButton,
    FunctionField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import CardActions from '@mui/material/CardActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { cardActionStyle } from "../custom/themes";
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";

const RolesTable = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    if (record) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell id="unitID-label" style={{ fontWeight: 'bold' }}>{translate('resources.hospital.hospitalid')}</TableCell>
                    <TableCell id="role-label" style={{ fontWeight: 'bold' }}>{translate('user.list.role')}</TableCell>
                </TableRow>
                {record.roles.map((row, index) => (
                    row.tenantShortName &&
                    <TableRow
                        key={row.tenantShortName + '_' + row.role}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell id={`shortName_${index}`} align="left">{row.tenantShortName}</TableCell>
                        <TableCell id={`role_${index}`} align="left">{row.role === 'TENANT_ADMIN' ? translate('user.roles.tenantadmin') : row.role === 'SYSADMIN' ? translate('user.roles.sysadmin') : translate('user.roles.clinician')}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        );
    }
};

const UserActions = ({ data }) => (
    <CardActions style={cardActionStyle}>
        <Button
            id="backToUsers"
            variant="blank"
            component={Link}
            to="/users"
            label="user.list.label"
        >
            <ArrowBackIcon style={{ height: '20px', width: '20px' }} />
        </Button>
        <span style={{ flex: 1 }} />
        {(localStorage.getItem('isAdmin') === 'true' || localStorage.getItem('isSysAdmin') === 'true') &&
            <EditButton
                style={{ marginRight: '1em' }}
                variant="outlined"
                label="user.edit.label"
                icon={<EditIcon style={{ height: '20px', width: '20px' }} />}
                record={data}
                id="editUser" />
        }
    </CardActions>
);
const UserShow = () => {
    const translate = useTranslate();

    const Title = () => {
        const record = useRecordContext();

        const userName = () => {
            const { fName, lName, username } = record;
            if (fName) return `${fName + ' ' + lName}`
            return `${username}`
        }

        if (record) {
            return (
                <span>
                    {translate('user.detail')}: {record && userName()}
                </span>
            );
        }
        return (
            <span>
                {translate('user.detail')}:
            </span>
        );
    };

    return (
        <Show
            style={{ paddingLeft: '10px', paddingRight: '10px', minHeight: '0px', borderRadius: '5px', backgroundColor: '#FFFFFF' }}
            actions={<UserActions />}
            title={<Title />}
        >
            <SimpleShowLayout>
                <FunctionField
                    id="name"
                    label="user.list.name"
                    render={record => `${record.lName ? record.lName + ',' : ''} ${record.fName ? record.fName : ''}`}
                />
                <TextField id="username" source="username" label="ra.auth.username" />
                <TextField id="email" source="email" label="user.create.email" />
                <TableContainer style={{ marginBottom: '10px' }} component={Paper}>
                    <Table sx={{ maxWidth: 350 }} aria-label="simple table">
                        <RolesTable />
                    </Table>
                </TableContainer>
                <FunctionField
                    id="active"
                    label="user.create.active"
                    render={record => `${record.active ? translate('dashboard.active') : translate('gateways.inactive')}`}
                />
            </SimpleShowLayout>
        </Show>
    )
};

export default UserShow;