const providers = {
    STANDARD: 'standard'
}

const servers = {
    local: {
        endpoint: 'http://localhost:8080',
        provider: providers.STANDARD,
        name: 'GCP Internal'
    },
    development: {
        endpoint: 'https://api-connect-dot-temptraq-cloud-dev.ue.r.appspot.com/',
        provider: providers.STANDARD,
        name: 'GCP Development'
    },
    stage: {
        endpoint: 'https://api-connect-dot-temptraq-cloud-stage.ue.r.appspot.com/',
        provider: providers.STANDARD,
        name: 'GCP Staging'
    },
    prod: {
        endpoint: 'https://connect.temptraqapi.com',
        provider: providers.STANDARD,
        name: 'GCP Production'
    }
};

export const currentConfig = process.env.REACT_APP_TYPE && process.env.REACT_APP_TYPE.toLowerCase().trim();
console.log('connecting ' + process.env.NODE_ENV + ' to ' + currentConfig + '/' + process.env.GAE_VERSION);
console.log(process.env.REACT_APP_TESTVAR);
console.log(JSON.stringify(process.env));

const serverConfig = {
    endpoint: servers[currentConfig].endpoint,
    name: servers[currentConfig].name,
    provider: servers[currentConfig].STANDARD
}

export {providers};
export default serverConfig;