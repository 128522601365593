import { fetchUtils } from 'react-admin';
import serverConfig from '../../custom/serverConfig';
import { authorization } from '../../dataProvider/authProvider';

const robot = 'https://api.uptimerobot.com/v2/getMonitors?api_key=ur1098679-d39979bd6aac251f8f3d6e3d';
const robOptions = { method: 'POST' };

const httpClient = (apiUrl, method) => {
  const options = {};
  options.method = method;
  options.headers = new Headers();
  options.headers.set('Authorization', authorization());

  return fetchUtils.fetchJson(apiUrl, options);
}

export async function getStatus() {
  try {
    const res = await fetchUtils.fetchJson(robot, robOptions);
    const data = res.json.monitors;
    const monitors = {
      paused: 0,
      checked: 0,
      up: 0,
      seemsDown: 0,
      down: 0
    };
    data.forEach((item) => {
      if (item.status === 0)
        monitors.paused += 1
      if (item.status === 1)
        monitors.checked += 1
      if (item.status === 2)
        monitors.up += 1
      if (item.status === 8)
        monitors.seemsDown += 1
      if (item.status === 9)
        monitors.down += 1
    });
    return monitors;
  } catch (err) {
    console.log(err);
  }
}

async function getData(string, method) {
  const url = `${serverConfig.endpoint}/rest/v1/${string}`
  const reg = /(^demo$)|(^demo-charlie$)|(^demo-echo$)|(^demo-sierra$)|(^devhos$)/i
  try {
    const res = await httpClient(url, method);
    const filtered_res = res.json.filter((unit) => !unit?.shortName?.match(reg));
    if (/tenants/i.test(string)) {
      let num = 0;
      let err = 0;
      for (let i = 0; i < filtered_res.length; i += 1) {
        num += filtered_res[i].totalPatients;
        err += filtered_res[i].patchErrors;
      }
      return ({ tenant: filtered_res, patient: num, error: err });
    }
  } catch (err) {
    console.log(err);
  };
}

export default getData;