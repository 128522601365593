import React from 'react';
import {
    Edit,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    useRedirect,
    useRecordContext,
    useTranslate
} from 'react-admin';
import { connect } from 'react-redux';
import BackButton from '../ui/BackButton';
import { numConvertFromI } from '../tempscale/tempConvert'
import DeletePatientButton from './DeletePatientButton'
import { inputPatientId2, inputPatientAlertTemp } from "./PatientCommon";
import SaveIcon from '@mui/icons-material/Save';

const PatientEditToolbar = (props) => {
    const redirect = useRedirect();
    const record = useRecordContext();

    return (
        <Toolbar id="patientEditToolbar" {...props} >
            <SaveButton
                id="save_button"
                alwaysEnable
                variant="contained"
                icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
            />
            <BackButton
                id="patientEditBack"
                goBack={() => redirect('show', 'PatientSummary', record.id)}
            />
            <span style={{ flex: "1" }} />
            {props.config?.parameters?.enablePatientDelete &&
                <DeletePatientButton
                    resource="patient"
                />
            }
            <span style={{ flex: "1" }} />
        </Toolbar>
    )
}

const PatientEdit = (props) => {
    const translate = useTranslate();
    const { config, ...rest } = props;
    // const alertTemp = props.record.alertTemp
    const info = {
        minAlertTemp: numConvertFromI(localStorage.getItem("highTempThreshold")),
        patientAlertEditable: config.parameters.patientAlertEditable,
        patientId2Required: config.parameters.patientId2Required
    };

    return (
        <Edit
            sx={{ m: 0, mb: { xs: '80px', sm: 1 } }}
            redirect="show"
            title={translate('ra.action.edit') + ' ' + translate('resources.patient.label')}
            {...rest}
            actions={false}
        >
            <SimpleForm id="patientEditForm" toolbar={<PatientEditToolbar {...props} />} >
                {/* <HiddenInput id="id" source="id" label="Ref" /> */}
                <TextInput disabled id="hospitalCode" label="resources.hospital.hospitalid" source="tenantShortName" />
                <br />
                <TextInput disabled id="patientCode" label="resources.patient.patientidlabel" source="patientCode" />
                <br />
                {inputPatientId2(info, translate)}
                <br />
                {inputPatientAlertTemp(info, translate)}
                <br />
            </SimpleForm>
        </Edit>
    );
}

const mapStateToProps = state => ({ temp: state.temp, config: state.config });
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(PatientEdit);
