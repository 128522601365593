import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import _ from 'lodash';
import React, { Component } from 'react';
import {
    Create,
    FormTab,
    regex,
    required,
    SaveButton,
    SelectInput,
    TabbedForm,
    TextInput,
    Toolbar,
    useInput
} from 'react-admin';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import customDataProvider from "../dataProvider/customDataProvider";
import SaveIcon from '@mui/icons-material/Save';
import withRefresh from "../hoc/withRefresh";
import withRedirect from "../hoc/withRedirect";
import { compose } from 'recompose';
import BackButton from "../ui/BackButton";

const { useState, useEffect } = React;

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            "&.Mui-focused": {
                border: "1px outset grey",
                borderColor: "#858585",
                borderRadius: "5px",
                boxShadow: ".5px .5px blue",
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                }
            }
        }
    })
);

const EntityCreateToolbar = compose(
    withRedirect,
    withRefresh
)(({ refresh, redirect, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            submitonenter={"true"}
            icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
            mutationOptions={{
                onSuccess: (response) => {
                    refresh();
                    redirect('show', 'configurations', response.id);
                }
            }}
            type="button"
        />
        <BackButton
            style={{ margin: '5px' }}
            label="Cancel"
            goBack={() => redirect('list', 'configurations')}
            variant="contained"
        />
    </Toolbar>
));

const CustomCreateField = props => {
    const classes = useStyles();

    //
    if (props.record.name === undefined) {
        props.record.name = "Name Required";
    }
    const {
        input
    } = useInput(props.record);

    if (props.record === undefined || props.record.configurationvalues === undefined) {
        props.record.configurationvalues = [];
    }

    //This will save our values to the record we want to persist
    props.record.configurationvalues = props.definitions.record.configurationvalues;

    //These are the variables that store the state of existing configurations
    const [parametersState, setParametersState] = useState({});
    const [defaultsState, setDefaultsState] = useState({});
    const [stringsState, setStringsState] = useState({});

    //These are variables that store the state of configurations that are not being used
    const [availableStringsState, setAvailableStringsState] = useState({});
    const [availableDefaultsState, setAvailableDefaultsState] = useState({});
    const [availableParametersState, setAvailableParametersState] = useState({});

    const [newConfigurationField, setNewConfigurationField] = useState({});

    //Need to go through definitions and remove any that are already in use
    let availableParameters = [];
    let availableDefaults = [];
    let availableStrings = [];
    let usedParameters = {};
    let usedDefaults = {};
    let usedStrings = {};
    let availableMaps = [];

    //availableMaps is used later so we don't reproduce the same code 3 times for each configuration type
    availableMaps.push(availableParameters);
    availableMaps.push(availableDefaults);
    availableMaps.push(availableStrings);

    useEffect(() => {
        Object.entries(availableStrings).map(([key, value]) => setAvailableStringsState(prevState => ({ ...prevState, [value.name]: true })));
        Object.entries(availableDefaults).map(([key, value]) => setAvailableDefaultsState(prevState => ({ ...prevState, [value.name]: true })));
        Object.entries(availableParameters).map(([key, value]) => setAvailableParametersState(prevState => ({ ...prevState, [value.name]: true })));
        Object.entries(usedParameters).map(([key, value]) => setParametersState(prevState => ({ ...prevState, [key]: value })));
        Object.entries(usedDefaults).map(([key, value]) => setDefaultsState(prevState => ({ ...prevState, [key]: value })));
        Object.entries(usedStrings).map(([key, value]) => setStringsState(prevState => ({ ...prevState, [key]: value })));
    }, []); // eslint-disable-line

    //break up the existing configurations into separate objects for each type
    for (let value of props.record.configurationvalues) {
        if (value.configurationdefinition.type === 'parameters') {
            if (value.configurationdefinition.datatype === 'STRING') {
                usedParameters[value.configurationdefinition.name] = value.stringvalue;
            } else if (value.configurationdefinition.datatype === 'NUMBER') {
                usedParameters[value.configurationdefinition.name] = value.numbervalue;
            } else {
                usedParameters[value.configurationdefinition.name] = value.booleanvalue;
            }
        } else if (value.configurationdefinition.type === 'strings') {
            if (value.configurationdefinition.datatype === 'STRING') {
                usedStrings[value.configurationdefinition.name] = value.stringvalue;
            } else if (value.configurationdefinition.datatype === 'NUMBER') {
                usedStrings[value.configurationdefinition.name] = value.numbervalue;
            } else {
                usedStrings[value.configurationdefinition.name] = value.booleanvalue;
            }
        } else {
            if (value.configurationdefinition.datatype === 'STRING') {
                usedDefaults[value.configurationdefinition.name] = value.stringvalue;
            } else if (value.configurationdefinition.datatype === 'NUMBER') {
                usedDefaults[value.configurationdefinition.name] = value.numbervalue;
            } else {
                usedDefaults[value.configurationdefinition.name] = value.booleanvalue;
            }
        }
    }

    //Populate available arrays with config definitions that aren't in use
    if (props.definitions && props.definitions.parameters) {
        for (let i = 0; i < props.definitions.parameters.length; i++) {
            let key = props.definitions.parameters[i];

            if (usedParameters[key.name] === undefined) {
                availableParameters.push(props.definitions.parameters[i]);
            }
        }
    }

    if (props.definitions && props.definitions.defaults) {
        for (let i = 0; i < props.definitions.defaults.length; i++) {
            let key = props.definitions.defaults[i];

            if (usedDefaults[key.name] === undefined) {
                availableDefaults.push(props.definitions.defaults[i]);
            }
        }
    }

    if (props.definitions && props.definitions.strings) {
        for (let i = 0; i < props.definitions.strings.length; i++) {
            let key = props.definitions.strings[i];

            if (usedStrings[key.name] === undefined) {
                availableStrings.push(props.definitions.strings[i]);
            }
        }
    }

    //Handle new fields by making API call to get definition
    const newField = (configName, event) => {
        let booleanValue = event ? event.target.isChecked : true;
        let numberValue = event ? event.target.value : 0;
        let stringValue = event ? event.target.value : "";
        let newConfigurationValue = {};

        for (let definition of props.definitions[props.source]) {
            if (definition.name === configName) {
                newConfigurationValue.configurationdefinition = definition;
                newConfigurationValue.configurationdefinitionid = definition.id;
                definition.datatype === 'BOOLEAN' ? newConfigurationValue.booleanvalue = booleanValue : definition.datatype === 'NUMBER' ? newConfigurationValue.numbervalue = numberValue :
                    newConfigurationValue.stringvalue = stringValue;

                props.definitions.record.configurationvalues.push(newConfigurationValue);

                //Remove item from available fields
                let sourceArray = props.source === "parameters" ? props.definitions.parameters
                    : props.source === "defaults" ? props.definitions.defaults : props.definitions.strings;

                for (let i = 0; i < sourceArray.length; i++) {
                    let parameter = sourceArray[i];

                    if (parameter.name === configName) {
                        sourceArray.splice(i, 1);

                        break;
                    }
                }

                break;
            }
        }
    }

    //This is used to ensure the new field is recognized as soon as the + button is clicked on
    const handleNewField = (key) => {
        newField(key.name);
        let usedState = props.source === 'defaults' ? defaultsState : props.source === 'strings' ? stringsState : parametersState;
        let usedSetState = props.source === 'defaults' ? setDefaultsState : props.source === 'strings' ? setStringsState : setParametersState;
        let value = key.type === 'boolean' ? true : '';
        usedSetState({ ...usedState, [key.name]: value });

        //This state is used so we know which field should be focused after the page is rerendered
        setNewConfigurationField({ ...newConfigurationField, "newField": key.name });
    }

    //This is for text/number fields that are modified
    const handleBlur = (event) => {
        let usedState = props.source === 'defaults' ? defaultsState : props.source === 'strings' ? stringsState : parametersState;
        let usedSetState = props.source === 'defaults' ? setDefaultsState : props.source === 'strings' ? setStringsState : setParametersState;
        usedSetState({ ...usedState, [event.target.name]: event.target.value });


        let found = false;
        //Now we need to update the value within the object
        Object.keys(props.record.configurationvalues).map((key) => {
            let configValueObj = props.record.configurationvalues[key];

            if (configValueObj.configurationdefinition && configValueObj.configurationdefinition !== undefined && configValueObj.configurationdefinition.type === props.source && configValueObj.configurationdefinition.name === event.target.name) {
                if (configValueObj.configurationdefinition.datatype === 'STRING') {
                    configValueObj.stringvalue = event.target.value;
                } else {
                    configValueObj.numbervalue = event.target.value;
                }

                found = true;
            }
            return found;
        })

        //This means it's a new value and we need to add to the props.record.configurationvalues array
        if (!found) {
            newField(event.target.name, event);
        }
    };

    //This is for boolean values
    const handleChange = (event) => {
        let usedState = props.source === 'defaults' ? defaultsState : props.source === 'strings' ? stringsState : parametersState;
        let usedSetState = props.source === 'defaults' ? setDefaultsState : props.source === 'strings' ? setStringsState : setParametersState;

        if (event.target.type === 'checkbox') {
            usedSetState(prevState => ({ ...prevState, [event.target.name]: event.target.checked }));
        } else {
            usedSetState({ ...usedState, [event.target.name]: event.target.value });
        }

        let found = false;
        //Now we need to update the value within the object
        Object.keys(props.record.configurationvalues).map((key) => {
            let configValueObj = props.record.configurationvalues[key];

            if (configValueObj.configurationdefinition && configValueObj.configurationdefinition.type === props.source && configValueObj.configurationdefinition.name === event.target.name) {
                if (configValueObj.configurationdefinition.datatype === 'BOOLEAN') {
                    configValueObj.booleanvalue = event.target.checked;
                } else if (configValueObj.configurationdefinition.datatype === 'NUMBER') {
                    configValueObj.numbervalue = event.target.value;
                } else {
                    configValueObj.stringvalue = event.target.value;
                }

                found = true;
            }
            return found;
        })

        //This means it's a new value and we need to add to the props.record.configurationvalues array
        if (!found) {
            newField(event.target.name, event);
        }
    };

    return (
        <FormControl component="fieldset">
            <FormGroup {...input} id="parameterGroup" aria-label="position">
                <React.Fragment>
                    {Object.keys(props.record.configurationvalues).map((key) => {
                        let configValueObj = props.record.configurationvalues[key];
                        let usedState = props.source === 'defaults' ? defaultsState : props.source === 'strings' ? stringsState : parametersState;

                        if (configValueObj.configurationdefinition !== undefined) {
                            let numberType = configValueObj.configurationdefinition.datatype === 'NUMBER' ? 'number' : 'string';
                            if (configValueObj.configurationdefinition.type === props.source) {
                                if (configValueObj.configurationdefinition.datatype === 'BOOLEAN') {
                                    return (
                                        <FormControlLabel
                                            control={<Switch
                                                checked={parametersState[configValueObj.configurationdefinition.name] || false}
                                                onChange={handleChange}
                                                color={"primary"}
                                                focused={newConfigurationField.newField && newConfigurationField.newField === configValueObj.configurationdefinition.name}
                                                name={configValueObj.configurationdefinition.name}
                                                id={configValueObj.configurationdefinition.name}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label={configValueObj.configurationdefinition.name}
                                        />

                                    )
                                } else {
                                    return (
                                        <TextField
                                            InputProps={{ className: classes.root }}
                                            id={configValueObj.configurationdefinition.name}
                                            name={configValueObj.configurationdefinition.name}
                                            variant={"filled"}
                                            label={configValueObj.configurationdefinition.name}
                                            onBlur={handleBlur}
                                            focused={newConfigurationField.newField && newConfigurationField.newField === configValueObj.configurationdefinition.name}
                                            onChange={handleChange}
                                            style={{ margin: 8 }}
                                            value={usedState[configValueObj.configurationdefinition.name]}
                                            type={numberType}
                                            margin="normal"
                                        />
                                    )
                                }
                            }
                        }
                        return null;
                    })}

                    {availableMaps.map((availableFields) => {
                        return (
                            <div>
                                {availableFields.map((configDefObj) => {
                                    let numberType = configDefObj.datatype === 'NUMBER' ? 'number' : 'string';
                                    let availableStateUsed = props.source === 'strings' ? availableStringsState ? props.source === 'defaults' : availableDefaultsState : availableParametersState;
                                    let stateUsed = props.source === 'strings' ? stringsState ? props.source === 'defaults' : defaultsState : parametersState;
                                    return (
                                        <div>
                                            {(configDefObj.type === props.source && configDefObj.datatype === 'BOOLEAN') &&
                                                <IconButton onClick={() => { handleNewField(configDefObj); }} aria-label="add">
                                                    <AddIcon fontSize="small" />
                                                </IconButton>
                                            }
                                            {(configDefObj.type === props.source && (configDefObj.datatype === 'STRING'
                                                || configDefObj.datatype === 'NUMBER')) &&
                                                <IconButton onClick={() => { handleNewField(configDefObj); }} style={{ paddingTop: "25px" }} aria-label="add">
                                                    <AddIcon fontSize="small" />
                                                </IconButton>
                                            }
                                            {(configDefObj.type === props.source && configDefObj.datatype === 'BOOLEAN') &&
                                                <FormControlLabel
                                                    control={<Switch
                                                        checked={stateUsed[configDefObj.name] || false}
                                                        disabled={availableStateUsed[configDefObj.name] === undefined ? true : availableStateUsed[configDefObj.name]}
                                                        onChange={handleChange}
                                                        color={"primary"}
                                                        name={configDefObj.name}
                                                        id={configDefObj.name}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />}
                                                    label={configDefObj.name}
                                                />
                                            }
                                            {(configDefObj.type === props.source && (configDefObj.datatype === 'STRING' || configDefObj.datatype === 'NUMBER')) &&
                                                <TextField
                                                    InputProps={{ className: classes.root }}
                                                    id={configDefObj.name}
                                                    name={configDefObj.name}
                                                    disabled={availableStateUsed[configDefObj.name] === undefined ? true : availableStateUsed[configDefObj.name]}
                                                    variant={"filled"}
                                                    label={configDefObj.name}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    style={{ margin: 8 }}
                                                    value={stateUsed[configDefObj.name]}
                                                    type={numberType}
                                                    margin="normal"
                                                />
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        )
                    })}
                </React.Fragment>
            </FormGroup>
        </FormControl>
    )
}

const validateName = [required(), regex(/^(?!Name Required$).+/, 'Please select a unique name')];

class ConfigurationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            record: {
                configurationvalues: []
            }
        };
    }
    //This call is to get configuration definitions that aren't being used by this configuration.
    componentDidMount() {
        return customDataProvider.getConfigurationDefinition("")
            .then(response => {
                let parameters = [];
                let defaults = [];
                let strings = [];
                _.forEach(response.data, function (v) {
                    if (v.type === 'strings') { strings.push(v); }
                    if (v.type === 'defaults') { defaults.push(v); }
                    if (v.type === 'parameters') { parameters.push(v); }
                });
                this.setState({ "defaults": defaults })
                this.setState({ "strings": strings })
                this.setState({ "parameters": parameters })
            });
    }

    render() {
        const { onCancel, hospital, classes, data, ...props } = this.props;
        return (
            <Create record={this.state.record.configurationvalues} title="Create Configuration" {...props}>
                <TabbedForm id="editform" toolbar={<EntityCreateToolbar />} >
                    <FormTab label="Summary">
                        <TextInput id="name" source="name" validate={validateName} />
                        <SelectInput source="type" validate={required()} choices={[
                            { id: 'clinician', name: 'Clinician' },
                            { id: 'patient', name: 'Patient' }
                        ]}
                        />
                        <TextInput source="configurationcode" validate={required()} />
                    </FormTab>
                    <FormTab label="Parameters">
                        <CustomCreateField type="checkbox" definitions={this.state} source="parameters" {...this.props} />
                    </FormTab>
                    <FormTab label="Defaults">
                        <CustomCreateField definitions={this.state} source="defaults" {...this.props} />
                    </FormTab>
                    <FormTab label="Strings">
                        <CustomCreateField definitions={this.state} source="strings" {...this.props} />
                    </FormTab>
                </TabbedForm>
            </Create>
        );
    }
}

export default ConfigurationCreate;