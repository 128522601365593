import React from 'react';
import {
	BooleanInput,
	Edit,
	required,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useRecordContext,
	useRedirect
} from 'react-admin';
import BackButton from '../ui/BackButton';
import DeleteGatewayButton from './DeleteGatewayButton';
import SaveIcon from "@mui/icons-material/Save";

const EntityEditToolbar = ({ isClinician, isSysadmin, ...props }) => {
	const redirect = useRedirect();
	const record = useRecordContext();

	return (
		<Toolbar {...props} >
			<SaveButton
				id="save_button"
				alwaysEnable
				variant="contained"
				icon={<SaveIcon style={{ height: '20px', width: '20px' }} />}
			/>
			<BackButton
				goBack={() => redirect(`show`, 'gateways', record.id)}
			/>
			<span style={{ flex: "1" }} />
			{localStorage.getItem('isSysAdmin') === 'true' &&
				<DeleteGatewayButton id="delete_button" resource={"gateway"} />
			}
			<span style={{ flex: "1" }} />
		</Toolbar>
	)
};

const GatewayEdit = ({ ...rest }) => {
	return (
		<Edit style={{ margin: '0px' }} redirect="show" title="gateways.edit" {...rest} actions={false}>
			<SimpleForm id="editform" toolbar={<EntityEditToolbar />}  >
				<TextInput id="code" name="code" source="gatewaycode" validate={required()} label="gateways.id" />
				<br />
				<TextInput id="name" name="name" source="name" label="gateways.name" />
				<br />
				<TextInput id="location" name="location" source="location" label="gateways.location" />
				<br />
				<BooleanInput options={{}} id="active" name="active" source="active" defaultValue label="user.create.active" />
			</SimpleForm>
		</Edit>
	);
}

export default GatewayEdit;