import React, { useRef, useEffect, useState, useContext } from "react";
import { connect } from 'react-redux';
import { init } from "echarts";
import { useRecordContext, useTranslate } from "react-admin";
import customDataProvider from "../dataProvider/customDataProvider";
import { Context } from "../dataProvider/context";

const PatchDiagnostics = () => {
    const { resize, setResize } = useContext(Context);
    const translate = useTranslate();
    const record = useRecordContext();
    const patchName = record.patchName;
    const chartRef = useRef(null);
    const timerRef = useRef(null);
    const componentMounted = useRef(true);
    let option;
    const [myChart, setMyChart] = useState({});
    const timeIndexValues = useRef([]);
    const [, setLoaded] = useState(false);

    const refreshData = async () => {
        clearTimeout(timerRef.current);
        timeIndexValues.current = [];

        await customDataProvider.getPatchPerformance(patchName)
            .then(performance => {
                for (let i = 0; i < performance.length; i++) {
                    let performanceRecord = performance[i];
                    let localArray = [];
                    let patchDate = new Date(performanceRecord.convertedTimeIndex);
                    let formattedDate = [patchDate.getMonth()+1,
                            patchDate.getDate(),
                            patchDate.getFullYear()].join('/')+' '+
                        [patchDate.getHours(),
                            patchDate.getMinutes(),
                            patchDate.getSeconds()].join(':');
                    localArray.push(formattedDate);
                    localArray.push(performanceRecord.vbatt);
                    localArray.push(performanceRecord.vsensor);
                    localArray.push(performanceRecord.errorCount);

                    timeIndexValues.current.push(localArray);
                }

                setLoaded(true);
                if (componentMounted.current) {
                    timerRef.current = setTimeout(() => {
                        refreshData();
                    }, 60 * 1000);
                }
            });
    };

    useEffect(() => {
        // Initialize chart
        if (chartRef.current !== null) {
            refreshData();
            setMyChart(init(chartRef.current, "light"));

            return () => {
                clearTimeout(timerRef.current);
                componentMounted.current = false;
            }
        }
    }, []); // eslint-disable-line

    option = {
        title: {
            text: translate('diagnostic.title')
        },
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: [translate('diagnostic.voltb'), translate('diagnostic.volts'), translate('diagnostic.error')]
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        toolbox: {
            feature: {

            }
        },
        dataset: {
            source: timeIndexValues.current,
            dimensions: ['timestamp', 'vbatt', 'vsensor', 'errors']
        },
        xAxis: {
            type: 'category'
        },
        yAxis: { },
        series: [
            {
                name: translate('diagnostic.voltb'),
                type: 'line',
                encode: {
                    x: 'timestamp',
                    y: 'vbatt'
                }
            },
            {
                name: translate('diagnostic.error'),
                type: 'line',
                encode: {
                    x: 'timestamp',
                    y: 'errors'
                }
            },
            {
                name: translate('diagnostic.volts'),
                type: 'line',
                encode: {
                    x: 'timestamp',
                    y: 'vsensor'
                }
            }
        ]
    };

    if (option && typeof option === 'object' && Object.keys(myChart).length > 0) {
        myChart.setOption(option);
    }

    if (Object.keys(myChart).length > 0) {
        window.addEventListener('resize', myChart.resize);
        if (resize) {
            setTimeout(() => {
                myChart.resize();
                setResize(false);
            }, 250);
        }
    }

    return (
        <div ref={chartRef} style={{ marginBottom: "15px", maxWidth: '100%', height: "400px" }} />
    );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatchDiagnostics);
