import React from 'react';
import { Paper, Grid, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import CurrentTemperatureState from '../patches/CurrentTemperatureState';
import TimeRemaining from '../patches/TimeRemaining';
import AlertTempField from "./AlertTempField"
import "./styles/GridList.css"
import { Notifications } from "@mui/icons-material"
import { useTranslate, useCreatePath } from "react-admin";
import { TrendingUp } from "@mui/icons-material";

const alertStyle = {
    boxSizing: 'border-box',
    height: '162px',
    width: '162px',
    margin: '4px',
    borderRadius: '4px',
    backgroundColor: '#FCEC03',
    boxShadow: '0px 0px 0px 2px #FCEC03'
}

export const PatientTile = ({ normalStyle, record, ...props }) => {
    const { displayAlertTemps, displayNRDTimer, config } = props;
    const alert = JSON.parse(localStorage.getItem('alertList' || props.alert));
    const translate = useTranslate();
    const createPath = useCreatePath();
    const href = !window.location.href.match(/show/);

    const earlyChecker = (time) => {
        if (time && time > new Date().getTime()) return true;
        return false;
    };

    const alertStatus = (id) => {
        const status = alert?.find(({ patientId }) => patientId === id);
        if (!!status) return alertStyle;
        return normalStyle;
    };

    return (
        <Grid
            id="patientGrid"
            style={alert ? alertStatus(record?.patientId || record?.id) : normalStyle}
            item
        >
            <MuiLink
                component={Link}
                to={href && createPath({
                    resource: 'PatientSummary',
                    id: record?.id,
                    type: 'show'
                })}
                underline="none"
            >
                <Paper
                    id="patientPaper"
                    className="patientPaper"
                    style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}
                >
                    <Grid
                        id="patientGridItem"
                        className="gridItem"
                        item
                        xs
                        container
                        direction="column"
                        align-content="flex-start"
                        spacing={0}
                        sx={{ borderRadius: '5px' }}
                    >
                        <Grid
                            id="patientGridSubItem"
                            item
                            xs
                            style={{
                                paddingLeft: '2px',
                                display: 'block',
                                width: '100%',
                                backgroundColor: '#CCC',
                                borderTopLeftRadius: '4px',
                                borderTopRightRadius: '4px'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Typography
                                    id="patientCode"
                                    variant="h5"
                                    align="left"
                                    noWrap
                                >
                                    {config.parameters.showPatientId2First ? (record?.patientName || <br />) : record?.patientCode}
                                </Typography>

                                {!!record?.patchStatus?.earlyIndicatorExpTime && earlyChecker(record?.patchStatus?.earlyIndicatorExpTime) &&
                                    <TrendingUp sx={{
                                        backgroundColor: '#fff',
                                        color: '#F49C66',
                                        borderRadius: '20px',
                                        mr: '2px',
                                        mt: '2px'
                                    }} />
                                }
                            </div>
                            <Typography
                                id="patientName"
                                variant="subtitle1"
                                align="left"
                                noWrap
                            >
                                {config.parameters.showPatientId2First ? record?.patientCode : (record?.patientName || <br />)}
                            </Typography>
                        </Grid>
                        <Grid
                            id="patientTempGrid"
                            item
                            style={{ flex: '0 0 60px', width: '100%' }}
                        >
                            <CurrentTemperatureState
                                label={translate('resources.misc.status')}
                                record={record}
                                temperatureActual={record?.patchStatus?.temperatureActual}
                                style={{ verticalAlign: 'middle' }}
                                displayNRDTimer={displayNRDTimer}
                                isGrid={true}
                            />
                        </Grid>

                        <Grid
                            id="patientTimeGrid"
                            item
                            style={{
                                display: 'flex',
                                flexGrow: '1',
                                position: 'relative',
                                flexWrap: 'nowrap',
                                bottom: '-4px',
                                left: '3px',
                            }}
                        >
                            <TimeRemaining label={translate('resources.patch.timeremaining')} record={record} />
                            <span
                                id="patientTimeSpan"
                                style={{
                                    position: 'absolute',
                                    right: '3px',
                                    bottom: '8px',
                                    padding: '4px',
                                    marginTop: '3px',
                                    justifyContent: 'right',
                                    display: `${displayAlertTemps ? 'visible' : 'none'}`
                                }}
                            >
                                <Notifications id="patientNotification" style={{ height: '24px', width: '24px' }} viewBox="-12 -14 35 35" />
                                <AlertTempField source="alertTemp" record={record} sourceFormat="internal" />
                            </span>
                        </Grid>
                    </Grid>
                </Paper>
            </MuiLink>
        </Grid>
    )
};