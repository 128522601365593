export const CHANGE_HOSPITAL = 'CHANGE_HOSPITAL';
export const CHANGE_CONFIG = 'CHANGE_CONFIG';

export const changeHospital = hospital => ({
  type: CHANGE_HOSPITAL,
  payload: hospital
});

export const changeConfig = config => ({
  type: CHANGE_CONFIG,
  payload: config
});
