import React from 'react';
import { useRecordContext } from 'react-admin';
import { connect } from 'react-redux';
import { numConvertFromI, stringConvertFromC } from '../tempscale/tempConvert';

const AlertTempField = (props) => {
  const record = useRecordContext();
  const tempFormat = props.temp;
  const curTemp = props.sourceFormat === 'internal' ?
    numConvertFromI((props.record || record)[props.source]) :
    (props.record || record)[props.source];

  return <span id="alertTemp">{stringConvertFromC(curTemp, tempFormat)}</span>;
};

AlertTempField.defaultProps = {
  addLabel: true,
};

const mapStateToProps = state => ({ temp: state.temp });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(AlertTempField);
