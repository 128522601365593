import germanMessages from 'ra-language-german';

export default {
    null: 'Dieser Punkt kommt in der Übersetzung nicht vor {NULL}',
    simple: {
        action: {
            close: 'Schließen',
            resetViews: 'Ansichten zurücksetzen',
        },
        'create-post': 'Neuer Beitrag',
    },
    ...germanMessages,
    misc: {
        temperatureoral: 'Entsprechende orale Temperatur',
        temperatureaxillary: 'Temperatur in der Achselhöhle',
    },
    resources: {
        auth: {
            passwordreset: 'Anweisungen zum Zurücksetzen des Passworts wurden gesendet an %{email}',
            emailrequired: 'E-Mail-Adresse erforderlich',
            invalid: 'Ungültiger Benutzername oder Passwort'
        },
        misc: {
            ascending: 'Aufsteigend',
            date: 'Datum',
            datetime: 'Datum/Uhrzeit',
            descending: 'Absteigend',
            detail: 'Angabe',
            export: 'Exportieren',
            id: 'ID',
            lastupdated: 'Letzte Aktualisierung',
            loadingdata: 'Daten werden geladen...',
            notviewinginwebview: 'Anzeige nicht in Webansicht',
            optional: 'Optional',
            ok: 'OK',
            processingdata: 'Daten werden verarbeitet...',
            status: 'Status',
            sortby: 'Sortieren nach',
            summary: 'Zusammenfassung',
            default: 'Standardeinstellung',
            error: 'Fehler beim Abruf der Daten',
            nodata: 'Keine Daten vorhanden',
            allowed: 'Erlaubte Zeichen: a-z A-Z 0-9 . -'
        },
        hospital: {
            highTempThreshold: 'Obere Temperaturschwelle (°%{scale})',
            lowTempThreshold: 'Untere Temperaturschwelle (°%{scale})',
            adjustfororal: 'Auf orale Temperatur einstellen',
            hospitalalerttemphelper: 'Muss unter oberer Temperaturschwelle liegen',
            label: 'Einheit',
            hospitalid: 'ID Einheit',
            group: 'Gruppe',
            parentLabel: 'ID Elterneinheit',
            add: 'Einheit hinzufügen',
            name: 'Name der Einheit',
            activepats: 'Aktive Patienten',
            completepats: 'Abgeschlossene Patienten',
            unstartedpats: 'Nicht begonnene Patienten',
            unstarted: 'Nicht begonnen',
            active: 'Aktiv',
            activate: 'Aktivieren',
            list: 'Einheiten',
            create: 'Einheit erstellen',
            show: {
                edit: 'Einheit bearbeiten',
                hierarchy: 'Rangordnung Einheit',
                config: 'Konfigurierung',
                integrations: 'Integrierungen',
                hospitalconfigure: 'Konfigurierungen des Arztes',
                patientconfigure: 'Konfigurierungen des Patienten',
                summary: 'Zusammenfassung',
                stats: 'Statistik',
            }
        },
        patient: {
            activehospitalonly: 'nur aktive Einheit',
            activepatientsonly: 'Aktive Patienten',
            addpatient: 'Patienten hinzufügen',
            alerttemp: 'Warntemperatur',
            dailytmax: 'Tageshöchsttemperatur',
            duplicateId: 'Patienten-ID muss eindeutig sein',
            editpatient: 'Patient bearbeiten',
            exportcsv: 'Alle Patientendaten exportieren (CSV)',
            kitid: 'Kit-ID',
            kitpassword: 'Kit-Passwort',
            kitpasswordrequired: 'Kit-Passwort erforderlich',
            kitidrequired: 'Kit-ID erforderlich',
            label: 'Patient',
            passwordhelp: 'Für ambulante Anwendung erforderlich',
            patientid2label: 'Patienten-ID 2',
            patientid2required: 'Patienten-ID 2 ist erforderlich',
            patientid2helperrequired: '* erforderlich',
            patientid2helperoptional: 'Optional',
            patientalerttemplabel: 'Warntemperatur (°%{scale})',
            patientalerttemphelper: 'Gültiger Bereich ist %{min} - %{max}',
            patientidlabel: 'Patienten-ID',
            patientidrequired: 'Patienten-ID ist erforderlich',
            patientlist: 'Patienten',
            patientpassword: 'Patienten-Passwort',
            showcredentials: 'Anmeldedaten',
            credentialstitle: 'Patienten-Anmeldedaten',
            showinactivepatients: 'inaktive Patienten anzeigen',
            temperature: 'Temperatur',
            transfer: 'Übertragen',
            transferkit: 'Kit übertragen',
            transferpatient: 'Patientenkit übertragen an %{hospitalCode}',
            transfersubmit: 'SENDEN',
            transfersourcehospital: 'Gruppen-ID',
            passwordincorrect: 'Passwort ist falsch',
            started: 'Begonnen'
        },
        patch: {
            addpatch: 'Pflaster hinzufügen',
            multiple: 'Mehrere Pflaster hinzufügen',
            completed: 'Abgeschlossen',
            current: 'Letzter',
            deletepatch: 'Pflaster löschen?',
            high: 'Hoch',
            label: 'Pflaster',
            labelplural: 'Pflaster',
            nopatchesadded: 'Keine Pflaster hinzugefügt',
            norecentdata: 'Keine aktuellen Daten',
            nodata: 'Keine Daten',
            nodatafor: 'Keine Daten für',
            patchactive: 'Pflaster aktiv',
            patchid: 'Pflaster-ID',
            replacepatch: 'Pflaster ersetzen',
            scanpatch: 'Pflaster scannen',
            starttime: 'Startzeit',
            timeremaining: 'Verbleibende Zeit',
            unusedpatch: 'Unbenutztes Pflaster',
            graph: 'Kurve',
            summary: 'Zusammenfassung',
            history: 'Verlauf',
            export: 'Exportieren',
            exportcsv: 'Einzelne Pflaster-Daten exportieren (CSV)',
            NPA: 'Keine Pflaster zugewiesen',
            NOS: 'Pflaster nicht begonnen',
            THI: 'Hoch',
            TLO: 'Niedrig',
            invalid: 'Ungültige Pflaster-ID',
            assigned: 'Pflaster wurde bereits einem Patienten zugewiesen',
            scan: 'Keine Scandaten zur Anzeige vorhanden',
            isrequired: 'Pflaster-ID ist erforderlich',
            patchisadded: 'Pflaster wurde hinzugefügt'
        },
        password: {
            containnumber: 'Passwort muss eine Ziffer enthalten',
            lowercaseletter: 'Passwort muss mindestens 1 Kleinbuchstaben enthalten',
            uppercaseletter: 'Passwort muss mindestens 1 Großbuchstaben enthalten',
            requiredlength: 'Passwort entspricht nicht der erforderlichen %{length} Zeichenlänge',
            specialcharacter: 'Passwort muss mindestens 1 Sonderzeichen enthalten'
        },
    },
    user: {
        roles: {
            tenantadmin: 'Administrator der Einheit',
            clinician: 'Arzt',
            sysadmin: 'Hauptadministrator'
        },
        detail: 'Benutzer',
        create: {
            add: 'Benutzer hinzufügen',
            admin: 'Administrator für Einheit',
            clinician: 'Arzt für Einheit',
            fname: 'Vorname',
            lname: 'Nachname',
            active: 'Aktiver Status',
            email: 'E-Mail',
            role: 'Benutzer muss mindestens eine Funktion haben'
        },
        list: {
            name: 'Name',
            role: 'Funktion',
            label: 'Benutzer',
            search: 'Suche',
        },
        form: {
            summary: 'Zusammenfassung',
            security: 'Sicherheit',
        },
        edit: {
            label: 'Benutzer bearbeiten',
            title: 'Benutzer „%{title}“',
        },
        action: {
            save_and_add: 'Speichern und hinzufügen',
            save_and_show: 'Speichern und anzeigen',
        },
    },
    login: {
        title: {
            cell360: 'Cell Therapy 360 Webanwendung',
            temptraq: 'TempTraq Clinician Webanwendung'
        },
        forgot: {
            link: 'Anmeldedaten vergessen',
            note: 'Hinweis: Nach Erhalt einer E-Mail unter der eingegebenen Adresse folgen Sie dem Link in der E-Mail, um das Passwort zurückzusetzen.',
            submit: 'Senden',
            snack: 'TODO!!! Anweisungen zum Zurücksetzen des Passworts wurden gesendet an'
        },
        client: 'Kunde',
        error: 'Ungültiger Benutzername oder Passwort',
        email: 'E-Mail'
    },
    navigation: {
        analytics: 'Analytik',
        patient: 'Patienten',
        gateway: 'Gateways',
        gatewayid: 'Gateway-ID',
        hospital: 'Einheiten',
        user: 'Benutzer',
        config: 'Konfigurierungen',
        settings: 'Einstellungen',
        network: 'Server nicht erreichbar. Netzwerkverbindung prüfen.'
    },
    settings: {
        server: 'Server',
        udi: 'UDI',
        label: 'Einstellungen',
        current: 'Aktueller Benutzer',
        change: 'Passwort ändern',
        diagnostics: "Diagnostik",
        scale: 'Temperaturskala auswählen',
        ui: 'Optionen der Benutzeroberfläche',
        alert: 'Warntemperaturen anzeigen',
        timer: 'Timer „Keine aktuellen Daten“ anzeigen',
        legal: 'Rechtshinweis',
        privacy: 'Datenschutzerklärung',
        terms: 'Geschäftsbedingungen',
        example: 'Beispiel',
        language: 'Sprachoptionen',
        enabled: 'Aktiviert'
    },
    earlyindicator: {
        title: 'Frühindikator'
    },
    alert: {
        soundon: 'Ton an',
        soundoff: 'Ton aus',
        notetitle: 'Auf dieser Website soll Ton wiedergegeben werden.',
        notedescr: 'Benutzer-Feedback ist erforderlich, um die automatische Wiedergabe zu erlauben.',
        generate: 'Willkürliche Benachrichtigung generieren',
        nonotification: 'Keine Benachrichtigung',
        clearall: 'Alle entfernen',
        clear: 'Entfernen',
        headerTitle: 'Warnmeldungen',
        title: {
            default: 'a',
            alert: 'Warnmeldung Temperaturabweichung, %{patient}',
            err: 'Pflaster ersetzen, %{patient}',
            nrd: 'Keine aktuellen Daten, %{patient}',
            afin: 'Pflaster aufgebraucht, %{patient}',
            early: 'Warnmeldung Frühindikator, %{patient}'
        },
        description: {
            default: 'Benachrichtigung erhalten bei %{time} Temperatur für Patienten %{code} und Pflaster %{patchId} ist auf Warnstufe',
            alert: 'Benachrichtigung erhalten bei %{time} Temperatur für Patienten %{patient} ist auf Warnstufe.',
            err: 'Warnmeldung TempTraq Pflaster. Pflaster für Patienten ersetzen %{patient}',
            nrd: 'Keine Temperaturdaten empfangen seit %{time} für Patienten %{patient}. Patient ist möglicherweise zu weit vom Empfangsgerät entfernt oder es gibt Probleme mit der Netzwerkverbindung.',
            afin: 'Pflaster %{patch} ist fast aufgebraucht. Legen Sie ein neues Pflaster auf, um die Überwachung fortzusetzen.',
            early: 'Anhand der Temperaturtendenz hat TempTraq ein erhöhtes Risiko erkannt, dass die Temperatur des Patienten innerhalb der nächsten Stunde %{alertTemp} erreicht.  Überwachen Sie die Temperatur des Patienten engmaschiger und treffen Sie geeignete Vorbereitungen für eine tatsächliche Warntemperatur.'
        }
    },
    acknowledge: {
        title: '',
        terms: 'Sie müssen die Geschäftsbedingungen akzeptieren, um dieses Werkzeug zu nutzen',
        accept: 'Ich akzeptiere',
        acknowledge: 'Bestätigen'
    },
    changepass: {
        currentpass: 'Aktuelles Passwort',
        newrequired: 'Neues Passwort erforderlich',
        currentrequired: 'Aktuelles Passwort erforderlich',
        currentincorrect: 'Aktuelles Passwort ist falsch',
        meetlength: 'Passwort entspricht nicht der erforderlichen %{length} Zeichenlänge',
        uppercase: 'Passwort muss mindestens 1 Großbuchstaben enthalten',
        lowercase: 'Passwort muss mindestens 1 Kleinbuchstaben enthalten',
        number: 'Passwort muss eine Ziffer enthalten',
        special: 'Passwort muss mindestens 1 Sonderbuchstaben enthalten',
        retype: 'Neues Passwort noch einmal eingeben',
        mustmatch: 'Die Passwörter müssen übereinstimmen.',
        change: 'Passwort ändern',
        changerequired: 'Passwort muss geändert werden',
        pass: 'Neues Passwort',
        retypepass: 'Neues Passwort noch einmal eingeben',
        enter: 'Neues Passwort eingeben',
        note1: 'Hinweis: Sie müssen sich',
        note2: 'mit dem neuen Passwort noch einmal anmelden'
    },
    dashboard: {
        active: 'Aktiv',
        total: 'Summe',
        hospitals: 'Einheiten',
        patients: 'Patienten',
        patches: 'Pflaster',
        server: 'Server-Status',
        run: 'Läuft',
        pause: 'Pausiert',
        seems: 'Anscheinend heruntergefahren',
        down: 'Heruntergefahren',
        not: 'Noch nicht geprüft',
        go: 'UptimeRobot',
        tooltip: 'Automatisch aktualisieren: %{refresh} Minuten',
        refresh: 'Intervall für automatische Aktualisierung',
        min: 'min',
        other: 'Weniger als %{num}',
        group: 'Gruppeneinheiten mit weniger als'
    },
    gateways: {
        label: 'Gateway',
        delete: 'Gateway löschen?',
        edit: 'Gateway bearbeiten',
        name: 'Name des Gateways',
        location: 'Standort des Gateways',
        id: 'Gateway-ID',
        active: 'Nur aktive Einheiten',
        add: 'Gateway hinzufügen',
        list: 'Gateways',
        inactive: 'Inaktiv',
        emptytitle: 'Noch kein Gateway.',
        emptydesc: 'Der aktuell aktiven Einheit wurde noch kein Gateway zugewiesen.'
    },
    integration: {
        endpoint: 'Endpunkt',
        pipeline: 'Pipeline',
        name: 'Name',
        edit: 'Integration bearbeiten',
        add: 'Integration hinzufügen',
        list: 'Liste der Integrationen',
        delete: 'Integration löschen?',
        detail: 'Angaben zur Integration'
    },
    diagnostic: {
        title: 'Pflastereigenschaften',
        voltb: 'Voltbatterie',
        volts: 'Voltsensor',
        error: 'Fehler',
    },
    config: {
        type: 'Art',
        inherit: 'Übernommene Konfiguration',
        value: 'Wert'
    }
};
