import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { propTypes, reduxForm, Field } from 'redux-form';
import compose from 'recompose/compose';
import { AUTH_LOGOUT, useRedirect, useTranslate, Notification, Title } from 'react-admin';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CircularProgress from '@mui/material/CircularProgress';
import { createStyles, makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import authProvider from '../dataProvider/authProvider';
import { Checkbox } from '@mui/material';
import customDataProvider from "../dataProvider/customDataProvider";
import { getActiveHospitalId } from "../hospitals/ActiveHospital";


const styles = makeStyles(theme =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: '#182F46'
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            backgroundColor: theme.palette.secondary.main,
        },
        hint: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.grey[500],
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        actions: {
            padding: '0 1em 1em 1em',
        },
    })
);

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    helperText,
    fullWidth,
    ...props
}) => {
    return (
        <Checkbox
            {...inputProps}
            {...props}
        />
    )
};

const Acknowledgement = ({ isLoading, handleSubmit }) => {
    const classes = styles();
    const translate = useTranslate();
    const redirect = useRedirect();
    const config = JSON.parse(localStorage.getItem("config"));
    const ackTitle = config.strings.termsTitle;
    const ackText = config.strings.termsContent;
    const ackVersion = config.strings.termsVersion;

    useEffect(() => {
        if (ackText && document.getElementById('ackText')) {
            document.getElementById('ackText').innerHTML = ackText.replace(/\\/g, '');
        }
    }, [ackText]);

    const sendAck = ack => {
        if (!document.getElementById("ackok").checked) {
            alert(translate('acknowledge.terms'));
        } else {
            const ackBody = { "termsAckVersion": ackVersion };
            return customDataProvider.acknowledgeTerms(ackBody, getActiveHospitalId()).then(() => {
                localStorage.setItem('termsAck', Date.now());
                redirect('/');
                return Promise.resolve();
            })
                .catch(() => {
                    authProvider(AUTH_LOGOUT);
                });
        }
    };

    const cancelAck = () => {
        authProvider(AUTH_LOGOUT);
        redirect('/');
        return false;
    };

    if (ackText && ackText.length > 0) {
        return (
            <div className={classes.main}>
                <Title id="Title-ack" title="acknowledge.title"/>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <CheckIcon />
                        </Avatar>
                    </div>
                    <form onSubmit={handleSubmit(sendAck)}>
                        <div id="ackTitle" className={classes.hint}>{ackTitle}<br /> </div>
                        <div className={classes.form}>
                            <div id="ackText" style={{ maxWidth: '800px' }} />
                            <div className={classes.input} style={{ alignContent: "right" }}>
                                <label>
                                    <Field
                                        id="ackok"
                                        name="ackok"
                                        component={renderInput}
                                        value={false}
                                    />
                                    {translate('acknowledge.accept')}
                                </label>
                            </div>
                        </div>
                        <CardActions className={classes.actions} style={{ marginTop: '10px' }}>
                            <   Button
                                id="cancel_button"
                                variant="contained"
                                type="cancel"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                                onClick={() => cancelAck()}
                            >
                                {translate('ra.action.cancel')}
                            </Button>
                            <Button
                                id="submit_button"
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                {translate('acknowledge.acknowledge')}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                <Notification />
            </div>
        );
    } else {
        return (
            <div>{translate('ra.page.loading')}...</div>
        );
    }
}

Acknowledgement.propTypes = {
    ...propTypes,
};

const mapStateToProps = state => ({ isLoading: state.isLoading > 0, config: state.config });
export default compose(
    reduxForm({ form: 'acknowledge', }),
    connect(mapStateToProps, {})
)(Acknowledgement);