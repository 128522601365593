import { authorization } from "./authProvider";
import serverConfig from '../custom/serverConfig';
import {
    CREATE,
    DELETE,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    resolveBrowserLocale,
    UPDATE
} from "react-admin";
import { parse, stringify } from 'query-string';
import _ from 'lodash';
import {getActiveGroupCode, getActiveHospitalId} from '../hospitals/ActiveHospital';
import { curSortByVal, curSortDirVal } from "../custom/settings/displayOptions";

export default (setContext) => {
    const locale = JSON.parse(localStorage.getItem('RaStore.locale')) || resolveBrowserLocale();
    let apiUrl;

    const httpClient = (apiUrl, options) => {
        options.headers = new Headers();
        options.headers.set('Accept', 'application/json');
        options.headers.set('Content-Type', "application/json");
        options.headers.set('Authorization', authorization());
        options.headers.set('Accept-Language', locale);

        return fetchUtils.fetchJson(apiUrl, options);
    }

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        let originalResource = resource;
        apiUrl = serverConfig.endpoint + '/rest/v1';

        if (resource === 'HospitalSummary' || resource === 'tenantassignments') {
            resource = 'tenants';
        } else if (resource === 'tenantintegrations') {
            resource = 'integrations';
        } else if (resource === 'tenantconfigurations') {
            resource = 'configurations';
        } else if (resource === 'PatientSummary') {
            if (type === 'UPDATE') {
                params.data.patientid2 = params.data.patientName;
                params.data.alerttemp = params.data.alertTemp;
            } else if (type === 'CREATE') {
                params.data.patientcode = params.data.patientCode;
                params.data.alerttemp = params.data.alertTemp;
                params.data.password = params.data.patientPassword;
                params.data.patientid2 = params.data.patientName;
                params.data.tenantId = getActiveHospitalId();
            }
            resource = 'patients';
        } else if (resource === 'PatchSummary') {
            if (type === 'GET_MANY_REFERENCE') {
                //This is for retrieving patches for a given patient. We're doing it this way to keep backwards compatibility for Kinvey,
                //which refers to PatchSummary
                let hrefString = decodeURIComponent(window.location.href);
                let patientId = hrefString.substring(hrefString.indexOf('/PatientSummary/') + '/PatientSummary/'.length, hrefString.indexOf('/show'));
                apiUrl = `${serverConfig.endpoint}/rest/v1/patients/${patientId}/assignments`;
                resource = 'patientassignments';
            } else if (params.data && params.data.patient_id) {
                //This is for creating a patch
                apiUrl = `${serverConfig.endpoint}/rest/v1/patients/${params.data.patient_id}/patch/${params.data.patchID}`;
                resource = 'patientassignments';
            } else {
                resource = 'patches';
            }
        } else if (resource === 'patientassignments') {
            if (type === 'GET_LIST') {
                resource = 'patches';
            } else {
                //This is for retrieving patches for a specific patient
                apiUrl = `${serverConfig.endpoint}/rest/v1/patients/${params.id}/assignments`;
            }
        } else if (resource === 'configurations' && type === 'UPDATE') {
            //We're using the useInput hook, which for some reason, possibly a bug, does not update data but DOES properly update previousData.
            //We want the previousData value
            params.data = params.previousData;
        }
        const { url, options } = convertDataRequestToHTTP(
            apiUrl,
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response => {
            setContext({ lastUpdated: new Date().getTime() })
            return convertHTTPResponse(response, type, resource, params, originalResource)
        });
    }
};

const convertDataRequestToHTTP = (apiUrl, type, resource, params) => {
    let url = '';
    let nameConvertMap = new Map();
    nameConvertMap.set('hospitalCode', 'shortname');
    nameConvertMap.set('hospitalName', 'name');
    nameConvertMap.set('patientName', 'patientid2');

    //var sortParam = {};
    const options = {};
    switch (type) {

        case GET_LIST: {
            const { page, perPage } = params.pagination;
            var { field, order } = params.sort;

            if (resource === 'tenants') {
                params.filter.activetenantid = getActiveHospitalId();
            }

            if (resource !== 'tenants' && resource !== 'configurations') {
                if (getActiveHospitalId()) { params.filter.activetenantid = parseInt(getActiveHospitalId()); }
            }

            if (resource === 'configurations') {
                params.filter.TYPE = "clinician";
                field = 'configurationcode';
                order = 'ASC';
            }

            if (resource === 'patients') {
                field = curSortByVal();
                order = curSortDirVal();
            }

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
            };

            //Patients have no pagination
            if (resource === 'patients') {
                query.range = JSON.stringify([0, 0]);
            }

            function replaceKeys(values, key) {
                query.filter = query.filter.replace(key, values);
                query.sort = query.sort.replace(key, values);
            }

            nameConvertMap.forEach(replaceKeys);

            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            if (resource === 'tenants') {
                params.filter.activetenantid = getActiveHospitalId();
            }

            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };

            if (nameConvertMap.get(JSON.stringify(field, order).replace(/"/g, ""))) { query.sort = nameConvertMap.get(JSON.stringify(field, order).replace(/"/g, "")); }

            if (resource === 'patientassignments') {
                url = apiUrl;
            } else {
                url = `${apiUrl}/${resource}?${stringify(query)}`;
            }

            break;
        }
        case GET_ONE:
            const href = window.location.href;
            const [, queryString] = href.split("?");
            const urlParams = parse(queryString);

            if (urlParams && urlParams.patchId && params.id === '0' && resource === 'patches') {
                url = `${serverConfig.endpoint}/rest/v1/patients/${urlParams.patientId}/assignments/${urlParams.patchId}`;
            } else {
                url = `${apiUrl}/${resource}/${params.id}`;
            }

            break;
        case GET_MANY: {
            if (resource === 'tenants') {
                if (!getActiveHospitalId()) {
                    params.filter.activetenantid = getActiveHospitalId();
                }
            }

            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case DELETE:
            if (resource === 'patientassignments' || resource === 'patches') {
                url = `${serverConfig.endpoint}/rest/v1/patients/${params.id}/patch/${params.previousData.patchName}`;
            } else {
                url = `${apiUrl}/${resource}/${params.id}`;
            }
            options.method = 'DELETE';
            options.body = JSON.stringify(params.data);

            break;
        case UPDATE:
            if (resource === 'tenants') {
                params.data.name = params.data.hospitalName;
                params.data.tenantcode = params.data.shortName;

                let integrationObjects = [];
                //ReferenceArrayInput uses IDs, but the endpoint expects objects, so we need to convert
                for (let v of params.data.assignedIntegrations) {
                    let integrationObject = {};
                    integrationObject.id = v;
                    integrationObjects.push(integrationObject);
                }

                if (params.data.id.toString() === getActiveHospitalId()) {
                    localStorage.setItem("adjustForOral", params.data.adjustForOral);
                    localStorage.setItem("highTempThreshold", params.data.highTempThreshold);
                    localStorage.setItem("lowTempThreshold", params.data.lowTempThreshold);
                }

                params.data.assignedIntegrations = integrationObjects;
            }

            if (resource === 'users') {
                //Create a new array of roles from the tenantAdmin and clinician arrays but we want to include sysadmin roles that already were there.
                let newRoles = [];

                for (let role of params.data.roles) {
                    if (role.role === 'SYSADMIN') {
                        newRoles.push(role);
                    }
                }
                if (params.data.tenantadminids) {
                    for (let id of params.data.tenantadminids) {
                        let role = { accountId: params.data.accountId, role: "TENANT_ADMIN", tenantId: id };
                        newRoles.push(role);
                    }
                }

                if (params.data.clinicianids) {
                    for (let id of params.data.clinicianids) {
                        let role = { accountId: params.data.accountId, role: "CLINICIAN", tenantId: id };
                        newRoles.push(role);
                    }
                }

                params.data.roles = newRoles;
            }

            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);

            break;
        case CREATE:
            if (resource === 'patientassignments') {
                url = apiUrl;
            } else {
                url = `${apiUrl}/${resource}`;
            }
            if (resource === 'tenants') {
                //New tenants will always be a child of the active hospital
                params.data.parentId = getActiveHospitalId();
                params.data.groupCode = getActiveGroupCode();
                params.data.shortName = params.data.tenantcode;
                params.data.name = params.data.hospitalName;
                params.data.tenantcode = params.data.shortName;

                let integrationObjects = [];
                //ReferenceArrayInput uses IDs, but the endpoint expects objects, so we need to convert
                if (params.data.assignedIntegrations) {
                    for (let v of params.data.assignedIntegrations) {
                        let integrationObject = {};
                        integrationObject.id = v;
                        integrationObjects.push(integrationObject);
                    }

                    params.data.assignedIntegrations = integrationObjects;
                }
            }

            if (resource === 'gateways') {
                params.data.tenantId = getActiveHospitalId();
            }

            if (resource === 'users') {
                //Create a new array of roles from the tenantAdmin and clinician arrays but we want to include sysadmin roles that already were there.
                let newRoles = [];

                if (params.data.tenantadminids) {
                    for (let id of params.data.tenantadminids) {
                        let role = { accountId: params.data.accountId, role: "TENANT_ADMIN", tenantId: id };
                        newRoles.push(role);
                    }
                }

                if (params.data.clinicianids) {
                    for (let id of params.data.clinicianids) {
                        let role = { accountId: params.data.accountId, role: "CLINICIAN", tenantId: id };
                        newRoles.push(role);
                    }
                }

                params.data.roles = newRoles;
            }

            options.method = 'POST';
            options.body = JSON.stringify(params.data);

            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
};

const convertHTTPResponse = (response, type, resource, params) => {
    var { json } = response;  // { headers }

    if (params && params.sort) {
        if (resource === 'patients' && curSortByVal() === 'patchStatus') {
            json = _.orderBy(json, function (e) {
                return ['NPA', 'NOS', 'FIN', 'ERR', 'NRD', 'TLO', 'TOK', 'THI'].indexOf(e.patchStatus.statusCode) + '_' + e.patchStatus.temperatureActual + '_' + e.patchStatus.startTime
            }, curSortDirVal().toLowerCase());
        } else if (params.sort.field !== 'id' && params.sort.field !== 'tenantId') {
            try {
                json = _.orderBy(json, [element => element[params.sort.field] && element[params.sort.field].toLowerCase()], params.sort.order.toLowerCase());
            } catch (e) {
                // this is a number and we should ignore
            }
        }
    }
    if (json) {
        if (Array.isArray(json)) {
            if (resource === 'tenants') {
                let i;
                for (i = json.length - 1; i >= 0; i -= 1) {
                    json[i].hospitalCode = json[i].shortName;
                    json[i].hospitalName = json[i].name;
                    json[i].tenantcode = json[i].shortName;

                    //if (originalResource === 'tenantassignments' && json[i].name.endsWith("_PARENT")) {
                    if (json[i].name && json[i].name.endsWith("_PARENT")) {
                        json.splice(i, 1);
                    }
                }

            } else if (resource === 'patients') {
                _.forEach(json, function (v) {
                    v.patientCode = v.patientcode;
                    if (v.patientid2 != null) {
                        v.patientName = v.patientid2;
                    }
                    v.alertTemp = v.alerttemp;
                    // v.tenantEntity = v.tenantAssignment;
                    v.tenantEntity = {};
                    v.tenantEntity.tenantcode = v.tenantId
                });
            } else if (resource === 'patientassignments') {
                _.forEach(json, function (v) {
                    //v.patientId = v.patientId;
                    v.patchID = v.patchId;
                    v.startTime = v.starttime;
                    v.modelNum = v.model;
                });
            } else if (resource === 'patches') {
                if (type === 'GET_ONE') {
                    let history = [];
                    json = json[0];

                    if (json) {
                        if (json.historyEntities) {
                            for (let v of json.historyEntities) {
                                history.push(v.temperature);
                            }
                        }

                        json.id = 0;
                        json.patchName = json.patchId;
                        json.startTime = 0;
                        json.history = history;
                        json.modelNum = json.model;
                    }
                } else {
                    _.forEach(json, function (v) {
                        let history = [];

                        if (v.historyEntities) {
                            for (let x of v.historyEntities) {
                                v.push(x.temperature);
                            }
                        }
                        v.patchID = v.patchId;
                        v.startTime = v.starttime;
                        v.history = history;
                        v.modelNum = v.model;
                    });
                }
            } else {
                _.forEach(json, function (v) { delete v._acl; delete v._kmd });
            }
        } else {
            if (resource === 'tenants') {
                json.hospitalCode = json.shortName;
                json.hospitalName = json.name;
                json.tenantcode = json.shortName;
                //We need two arrays: One to display integration names in hospitalShow
                json.integrationDetails = json.assignedIntegrations;
                let integrationIds = [];
                //And another array for just the IDs because of ReferenceArrayInput in hospitalEdit, which expects just an array of IDs
                for (let v of json.assignedIntegrations) {
                    integrationIds.push(v.id);
                }

                json.assignedIntegrations = integrationIds;
            } else if (resource === 'patients') {
                json.hospitalCode = json.tenantShortName;
                json.patientCode = json.patientcode;
                if (json.patientid2 != null) {
                    json.patientName = json.patientid2;
                }
                json.patientPassword = json.password;
                json.alertTemp = json.alerttemp;
            } else if (resource === 'patientassignments') {
                //json.patientId = json.patientId;
                json.patchID = json.patchId;
                json.patchName = json.patchId;
                json.startTime = json.starttime;
                json.modelNum = json.model;
                if (json.patchSummaryEntity) {
                    json.modelNum = json.patchSummaryEntity.model;
                    json.startTime = json.patchSummaryEntity.starttime;
                    json.patchStatus = json.patchSummaryEntity.patchStatus;
                }
            } else if (resource === 'patches') {
                let history = [];
                if (json.historyEntities) {
                    for (let v of json.historyEntities) {
                        history.push(v.temperature);
                    }
                }

                json.patchID = json.patchId;
                json.startTime = json.starttime;
                json.history = history;
                json.modelNum = json.model;
            } else if (resource === 'users') {
                //Go through the roles and convert to ID arrays so the ReferenceArrayInput can display them
                if (json.roles) {
                    let tenantadminids = [];
                    let clinicianids = [];

                    for (let v of json.roles) {
                        if (v.role === 'TENANT_ADMIN') {
                            tenantadminids.push(v.tenantId);
                        } else if (v.role === 'CLINICIAN') {
                            clinicianids.push(v.tenantId);
                        }
                    }
                    json.tenantadminids = tenantadminids;
                    json.clinicianids = clinicianids;
                }
            } else {
                delete json._acl;
                delete json._kmd;
            }
        }
    }

    switch (type) {
        case GET_MANY:
        case GET_LIST:

            return {
                data: json,
                total: parseInt(response.headers.get('content-range').split('/').pop(), 10),
            };
        case GET_MANY_REFERENCE:
            return {
                data: json,
                total: parseInt(response.headers.get('content-range').split('/').pop(), 10),
            };
        case UPDATE:
        case DELETE:
        case GET_ONE:
            return {
                data: { ...json, id: json && json.id },
            }
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        default:
            return { data: json };
    }
};