import React, {useEffect, useRef, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import {useRecordContext, useTranslate} from "react-admin";
import customDataProvider from "../dataProvider/customDataProvider";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export const ConfigValueField = props => {
    const translate = useTranslate();
    const classes = useStyles();
    const hospital = useRecordContext();
    const componentMounted = useRef(true);
    const data = useRef({});
    const [isLoading, setIsLoading] = useState(true);
    const application = props.application

    useEffect(() => {
        setIsLoading(true);
        customDataProvider.getHospitalConfig(hospital.id, application)
            .then(config => {
                if (componentMounted.current) {
                    data.current = config.data;
                }

                setIsLoading(false);
            })
        return () => {
            componentMounted.current = false;
            setIsLoading(false);
        }
    }, [componentMounted, hospital, application]);

    if (isLoading) {
        return null;
    } else {
        let configMap = data.current ? data.current.configurationvalues :
            props.record.configurationvalues;

        return (
            <TableContainer component={Paper}>
                <Table id="configuration_table" className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate('user.list.name')}</TableCell>
                            {!props.source &&
                                <TableCell align="right">{translate('config.type')}</TableCell>
                            }
                            {!props.source &&
                                <TableCell align="right">{translate('config.inherit')}</TableCell>
                            }
                            <TableCell align="right">{translate('config.value')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(configMap).map((key) => {
                            let configValueObj = configMap[key];
                            let configValue = configValueObj.configurationDefinition.datatype === 'BOOLEAN' ? configValueObj.booleanvalue.toString() :
                                configValueObj.configurationDefinition.datatype === 'STRING' ? configValueObj.stringvalue : configValueObj.numbervalue;

                            if (configValueObj.configurationDefinition.type === props.source || !props.source) {
                                return (
                                    <TableRow key={configValueObj.configurationDefinition.name}>
                                        <TableCell
                                            align="left">{configValueObj.configurationDefinition.name}</TableCell>
                                        {!props.source &&
                                            <TableCell
                                                align="right">{configValueObj.configurationDefinition.type}</TableCell>
                                        }
                                        {!props.source &&
                                            <TableCell
                                                align="right">{configValueObj.inheritedConfigurationName}</TableCell>
                                        }
                                        <TableCell align="right">{configValue}</TableCell>
                                    </TableRow>
                                )
                            }
                            return null;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}