import ImportExportIcon from '@mui/icons-material/ImportExport';
import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import customDataProvider from "../dataProvider/customDataProvider";
import { downloadFile } from '../util/download';
import { Button } from '@mui/material';

const PatchHistoryExport = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const record = useRecordContext();
  const translate = useTranslate();
  const dataAvailable = true;
  const patchName = record.patchName;
  const patientCode = record.patientCode ? record.patientCode : props.patientID;
  const hospitalCode = record.hospitalCode;
  const tempScale = props.temp;
  const id = record.id;

  const getExport = () => {
    const exportQuery = JSON.stringify({
      "hospitalCode": hospitalCode,
      "patientCode": patientCode,
      "id": id,
      "patchID": patchName,
      "scale": tempScale
    });
    setIsLoading(true);
    customDataProvider.csvExportHistory(exportQuery)
      .then(body => {
        if (body && body.csvStringData) {
          if (window.nativeInterface) {
          } else {
            downloadFile(patientCode + "-" + patchName + ".csv", decodeURI(body.csvStringData));
          }
        } else if (body) {
          if (window.nativeInterface) {
          } else {
            downloadFile(patientCode + "-" + patchName + ".csv", body);
          }
        }
        setIsLoading(false);
        return Promise.resolve();
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        window.alert(translate('resources.misc.error'));
      });
  };

  return (
    <div>
      <br />
      {dataAvailable ?
        <div>
          <Button
            id="export_button"
            sx={{ p: 1, height: 'fit-content' }}
            disabled={isLoading}
            variant="contained"
            onClick={() => getExport()}
            color="primary">
            {!isLoading ?
              (<>
                <ImportExportIcon style={{ height: '20px', width: '20px' }} />
                {translate('resources.patch.exportcsv')}
              </>)
              : <>
                {translate('resources.misc.processingdata')}
              </>}
          </Button>
        </div> : translate('resources.patch.scan')}
    </div>
  );
}

const mapStateToProps = state => ({ temp: state.temp, hospital: state.hospital });
const mapDispatchToProps = {}; //to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(PatchHistoryExport);
