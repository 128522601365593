import React from 'react';
import {
    Button,
    EditButton,
    Show,
    Tab,
    TabbedShowLayout
} from 'react-admin';
import CardActions from '@mui/material/CardActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { cardActionStyle } from "../custom/themes";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import TextField from '@mui/material/TextField';
import {ConfigValueField} from "./ConfigValueField";
import EditIcon from '@mui/icons-material/Edit';

const Title = ({ record }) => {
    return <span>Configuration Detail: {record ? record.name + ' - ' + record.type : ''}</span>;
};

const ConfigurationActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <Button
            id="backToConfigurations"
            variant="blank"
            component={Link}
            to={`/configurations`}
            label="Configuration List"
        >
            <ArrowBackIcon style={{ height: '20px', width: '20px' }} />
        </Button>
        <span style={{flex:1}} />
        <EditButton
            style={{marginRight: '1em'}}
            variant="outlined"
            label="Edit Configuration"
            icon={<EditIcon style={{ height: '20px', width: '20px' }} />}
            basePath={basePath}
            record={data}
            id="editConfiguration"/>

    </CardActions>
);

const SummaryField = props => {
    let parsedObj = props.record[props.source];
    return (
        <FormControl fullWidth component="fieldset">
            <FormGroup id="stringGroup" aria-label="position">
                <TextField
                    id="standard-full-width"
                    name={props.source}
                    label={props.source}
                    style={{ margin: 8 }}
                    value={parsedObj}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                    margin="normal"
                />
            </FormGroup>
        </FormControl>
    )
}



const ConfigurationShow = props => (
    <Show {...props}  actions={<ConfigurationActions/>} title={<Title  {...props} />}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <SummaryField source="name" />
                <SummaryField label="Configuration Code" source="configurationcode" />
                <SummaryField source="type" />
            </Tab>
            <Tab label="Parameters">
                <ConfigValueField source='parameters' />
            </Tab>
            <Tab label="Defaults">
                <ConfigValueField source='defaults' />
            </Tab>
            <Tab label="Strings">
                <ConfigValueField source='strings' />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default (ConfigurationShow);