import * as React from 'react';
import { useState } from 'react';
import { useLogin, useLogout, useNotify, Notification } from 'react-admin';
import versionData from "../version.json";
import Avatar from "@mui/material/Avatar";
import LockIcon from "@mui/icons-material/Lock";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import customDataProvider from "../dataProvider/customDataProvider";
import { createStyles, makeStyles } from '@mui/styles';
import { LanguageMenu } from '../i18n/languageMenu';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const version = versionData.MAJOR + '.' + versionData.MINOR + '.' + versionData.PATCH + '.' + versionData.BUILD;

const styles = makeStyles(theme =>
    createStyles({
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: '#182F46'
        },
        card: {
            minWidth: 300,
            marginTop: '6em',
        },
        avatar: {
            margin: '1em',
            display: 'flex',
            justifyContent: 'center',
        },
        link: {
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.grey[500]
        },
        icon: {
            backgroundColor: theme.palette.secondary.main,
        },
        title: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.grey[500],
        },
        hint: {
            maxWidth: 300,
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
            color: theme.palette.grey[500],
        },
        form: {
            padding: '0 1em 1em 1em',
        },
        list: {
            padding: '0 1em 1em 1em',
        },
        input: {
            marginTop: '1em',
        },
        actions: {
            padding: '0 1em 1em 1em',
        },
        lang: {
            color: '#fff',
            margin: 20
        }
    })
);

const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND.trim().toLowerCase() : "temptraq";

const LoginForm = ({ classes, setShowLogin, notify, translate }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const isLoading = false;
    const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND.trim().toLowerCase() : "temptraq";
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setupForgotPasswordScreen = (event) => {
        event.preventDefault();
        setShowLogin(false);
    }

    const handleSubmit = e => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify('resources.auth.invalid', { type: 'error' })
        );
    };
    return (
        <div className={classes.main}>
            <Card id="login_card" className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar id="login_lock_icon" className={classes.icon}>
                        <LockIcon style={{ height: '24px', width: '24px' }} />
                    </Avatar>
                </div>
                <form onSubmit={handleSubmit} id="login_form" className={classes.form}>
                    {
                        brand === 'ct360' &&
                        <div id="login_ct360_div" className={classes.title}>{translate('login.title.cell360')}</div>
                    }
                    {
                        brand === 'temptraq' &&
                        <div id="login_temptraq_div" className={classes.title}>{translate('login.title.temptraq')}</div>
                    }
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <TextField
                                autoFocus
                                style={{ width: "100%" }}
                                name="username"
                                id="login_username"
                                label={translate('ra.auth.username')}
                                disabled={isLoading}
                                onChange={e => setUsername(e.target.value)}
                            />

                        </div>
                        <div className={classes.input}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <InputLabel id="login_password_label" htmlFor="standard-adornment-password">
                                    {translate('ra.auth.password')}
                                </InputLabel>
                                <Input
                                    id="login_password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    disabled={isLoading}
                                    onChange={e => setPassword(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </div>
                        <div className={classes.hint}>
                            <Link id="forgot_credentials" onClick={(event) => setupForgotPasswordScreen(event)} to="#">
                                {translate('login.forgot.link')}
                            </Link>
                        </div>
                    </div>
                    <CardActions id="login_actions" className={classes.actions}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                            className={classes.button}
                            id="login_submit"
                            fullWidth
                        >
                            {isLoading && (
                                <CircularProgress id="login_progress" size={25} thickness={2} />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </CardActions>
                </form>
                <div id="language_menu" style={{ display: 'flex', justifyContent: "center" }}>
                    <LanguageMenu />
                </div>
            </Card>
            <div className={classes.lang}>
                <span id="login_client_info">{translate('login.client')}: {version} / GCP</span>
                <Notification />
            </div>
        </div>
    );
}

const ForgotPasswordForm = ({ classes, setShowLogin, notify, translate }) => {
    const isLoading = false;
    const logout = useLogout();
    const [email, setEmail] = useState('');

    const setupLoginScreen = (event) => {
        event.preventDefault();
        setShowLogin(true);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (email === null || email.trim().length === 0) {
            notify('resources.auth.emailrequired', { type: 'error' });
        } else {

            return customDataProvider.passwordReset(email.trim()).then(() => {
                notify('resources.auth.passwordreset', { type: 'success', messageArgs: { email: email } });
                setShowLogin(true);
                return Promise.resolve();
            })
                .catch(() => {
                    logout();
                });
        }
    };

    return (
        <div className={classes.main}>
            <Card id="login_card" className={classes.card}>
                <div className={classes.avatar}>
                    <Avatar id="login_lock_icon" className={classes.icon}>
                        <LockIcon style={{ height: '24px', width: '24px' }} />
                    </Avatar>
                </div>
                <form onSubmit={handleSubmit} id="login_form" className={classes.form}>
                    {
                        brand === 'ct360' &&
                        <div id="login_ct360_div" className={classes.title}>{translate('login.title.cell360')}</div>
                    }
                    {
                        brand === 'temptraq' &&
                        <div id="login_temptraq_div" className={classes.title}>{translate('login.title.temptraq')}</div>
                    }
                    <div className={classes.form}>
                        <div className={classes.input}>
                            <TextField
                                style={{ width: "100%" }}
                                autoFocus
                                name="email"
                                id="email"
                                label={translate('login.email')}
                                disabled={isLoading}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <CardActions className={classes.actions} style={{ marginTop: '10px' }}>
                        <Button
                            id="cancel_button"
                            variant="contained"
                            type="button"
                            color="primary"
                            disabled={isLoading}
                            className={classes.button}
                            fullWidth
                            onClick={(event) => setupLoginScreen(event)}
                        >
                            {translate('ra.action.cancel')}
                        </Button>
                        <Button
                            id="submit_button"
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isLoading}
                            className={classes.button}
                            fullWidth
                        >
                            {translate('login.forgot.submit')}
                        </Button>
                    </CardActions>
                    <div id="reset_note" className={classes.hint} style={{ margin: '5px', marginTop: '0px', color: '#FF8888' }}>
                        {translate('login.forgot.note')}
                    </div>
                </form>
                <div id="language_menu" style={{ display: 'flex', justifyContent: "center" }}>
                    <LanguageMenu />
                </div>
            </Card>
            <div className={classes.lang}>
                <span id="login_client_info">{translate('login.client')}: {version} / GCP</span>
                <Notification />
            </div>
        </div>
    );
}

const TempTraqLogin = () => {
    const [showLogin, setShowLogin] = useState(true);
    const notify = useNotify();
    const translate = useTranslate();
    const classes = styles();

    if (showLogin) {
        return <LoginForm classes={classes} setShowLogin={setShowLogin} notify={notify} translate={translate} />;
    } else {
        return <ForgotPasswordForm classes={classes} setShowLogin={setShowLogin} notify={notify} translate={translate} />;
    }

};

export default TempTraqLogin;