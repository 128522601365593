import { changeHospital } from '../../hospitals/hospitalActions'
import {
    setActiveHospitalId,
    setActiveHospitalHierarchy,
    setActiveHospitalShortName
} from '../../hospitals/ActiveHospital';
import customDataProvider from "../../dataProvider/customDataProvider";

const GraphClick = (thisHospital) => {
    localStorage.setItem('isAdmin', 'false');
    localStorage.setItem('isSysAdmin', 'false');
    localStorage.setItem('isClinician', 'false');

    customDataProvider.getUserInfo()
        .then(userInfo => {
            if (userInfo.response && userInfo.response.roles) {
                userInfo.response.roles.forEach((role) => {
                    if (role.tenantId === thisHospital.id ||
                        thisHospital.hierarchy.indexOf('/' + role.tenantId + '/') > 0 ||
                        role.tenantId === 1) {
                        if (role.role === "TENANT_ADMIN") {
                            localStorage.setItem("isAdmin", "true");
                        }
                        if (role.role === "SYSADMIN") {
                            localStorage.setItem("isSysAdmin", "true");
                        }
                        if (role.role === "CLINICIAN") {
                            localStorage.setItem("isClinician", "true");
                        }
                    }
                });
            }
            changeHospital(thisHospital);

            setActiveHospitalId(thisHospital.id);
            setActiveHospitalShortName(thisHospital.hospitalCode);
            setActiveHospitalHierarchy(thisHospital.fullHierarchy);

            localStorage.setItem("adjustForOral", thisHospital.adjustForOral);
            localStorage.setItem("highTempThreshold", thisHospital.highTempThreshold);
            localStorage.setItem("lowTempThreshold", thisHospital.lowTempThreshold);

            //Only redirect them if they have permissions to view patients
            if (localStorage.getItem("isSysAdmin") === "true" || localStorage.getItem("isClinician") === "true") {
                window.location.replace('/');
            } else {
                window.location.reload();
            }
        })
};

export default GraphClick;