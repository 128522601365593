import React from 'react';
import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';

const Responsive = ({xsmall, small, medium, large, xlarge}) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.up('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isMedium = useMediaQuery(theme => theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isXLarge = useMediaQuery(theme => theme.breakpoints.up('xl'));

    const sizes = [isXLarge, isLarge, isMedium, isSmall, isXSmall];
    const elements = [xlarge, large, medium, small, xsmall];

    // Select the largest element that is the same size or smaller as the screen size
    for (var i = 0; i < sizes.length; i++) {
        if(!sizes[i]) {
            continue;
        }
        for (var j = i; j < elements.length; j++) {
            if(elements[j] === null) {
                return null;
            }
            if(elements[j] !== undefined) {
                return <div>{elements[j]}</div>;
            }
        }
    }
    return null;
}

Responsive.propTypes = {
    xsmall: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    small: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    medium: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    large: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    xlarge: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
};

export default Responsive;