import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import ActionDelete from '@mui/icons-material/Delete';
import classnames from 'classnames';
import { useDelete, Button, Confirm, useTranslate, useRecordContext, useRedirect } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    resource,
    saving,
    selectedIds,
    type,
    ...rest
}) => rest;

const styles = makeStyles(theme =>
    createStyles({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    })
);

const DeleteHospitalButton = (props) => {
    const { className, icon, onClick, resource, ...rest } = props;
    const translate = useTranslate();
    const record = useRecordContext();
    const classes = styles();
    const redirect = useRedirect();
    const [isOpen, setIsOpen] = useState(false);
    const [deleteOne] = useDelete(
        'HospitalSummary',
        { id: record.id, previousData: record },
        {
            onSuccess: (data) => {
                redirect('list', 'HospitalSummary');
            }
        }
    );

    const handleClick = e => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const handleDialogClose = e => {
        e.stopPropagation();
        setIsOpen(false);
    };

    const handleDelete = e => {
        e.stopPropagation();
        deleteOne();
    };

    return (
        <Fragment>
            <Button
                onClick={handleClick}
                id="deleteButton"
                label="ra.action.delete"
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                {...sanitizeRestProps(rest)}
            >
                {icon}
            </Button>
            <Confirm
                id="deleteHospitalConfirm"
                isOpen={isOpen}
                title={translate('ra.action.delete') + ' ' + translate('resources.hospital.label') + `?`}
                content="ra.message.delete_content"
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

DeleteHospitalButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    resource: PropTypes.string.isRequired,
    translate: PropTypes.func,
    icon: PropTypes.element,
};

DeleteHospitalButton.defaultProps = {
    icon: <ActionDelete style={{ height: '20px', width: '20px' }} />,
};

export default DeleteHospitalButton;