import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { AppBar, UserMenu, useTranslate } from 'react-admin';
import { connect } from 'react-redux';
import Responsive from '../ui/Responsive';

const styles = makeStyles(() =>
    createStyles({
        title: {
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        banner: {
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    })
);


const MyUserMenu = props => (
    <UserMenu {...props} icon={<AccountCircleIcon style={{ height: '24px', width: '24px' }} />}>

    </UserMenu>
);

const ClinicianAppBar = (props) => {
    const { hospital, alerts: { notify, setAlertOpen } } = props;
    const classes = styles();
    const translate = useTranslate();
    const brand = process.env.REACT_APP_BRAND ? process.env.REACT_APP_BRAND.trim().toLowerCase() : "temptraq";
    const headerColor = brand === 'ct360' ? '#144B8E' : '#29657A';

    return (
        <div style={{ zIndex: 9999 }}>
            <AppBar {...props} style={{ position: "fixed", backgroundColor: `${headerColor}`, top: '-24px' }} userMenu={<MyUserMenu id="user_menu" />} >
                <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    minWidth: '0px',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '30px'
                }}>
                    <div style={{ display: 'flex' }}>
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                        >
                            {hospital && hospital.hospitalName}</Typography>
                        {/* CT360 */}
                        <Responsive
                            medium={
                                <div style={{ marginTop: '2px' }}>
                                    {brand === 'ct360' &&
                                        <img
                                            id="ct360_logo"
                                            src="/ct360_logo_light.png"
                                            height="35px"
                                            align="center-vertical"
                                            alt="Cell Therapy 360 Clinician"
                                        />}
                                    {brand === 'temptraq' &&
                                        <img
                                            id="temptraq_logo"
                                            src="/temptraq_logo_light.png"
                                            height="25px"
                                            align="center-vertical"
                                            alt="TempTraq Clinician"
                                        />}
                                </div>
                            }
                        />
                    </div>
                    <div style={{ display: "flex" }}>
                        <Typography
                            id="react-admin-title"
                            variant="subtitle1"
                            color="inherit"
                            className={classes.title}
                        />
                        <Responsive
                            medium={
                                <Typography
                                    id="temp_equivalence"
                                    variant="subtitle1"
                                    noWrap
                                    style={{ float: "right", color: "#4BC4E3", display: "inline" }}>
                                    {localStorage.getItem("adjustForOral") === 'true' ? translate('misc.temperatureoral') : translate('misc.temperatureaxillary')}
                                </Typography>
                            }
                        />
                    </div>
                </div>
                <Responsive
                    xsmall={
                        <IconButton
                            id="notification_bell"
                            style={{ height: '48px', width: '48px', padding: '12px' }}
                            onClick={() => setAlertOpen(true)}
                            color="inherit"
                        >
                            <Badge badgeContent={notify.length} color="error">
                                <NotificationsIcon style={{ height: '24px', width: '24px' }} />
                            </Badge>
                        </IconButton>
                    }
                    medium={null}
                />
            </AppBar>
            <div style={{ height: '50px', display: "flex" }} />
        </div>
    )
}

const mapStateToProps = state => ({ hospital: state.hospital, config: state.config });
const mapDispatchToProps = {}; // to prevent dispatch error due to lack of dispatches
export default connect(mapStateToProps, mapDispatchToProps)(ClinicianAppBar);
