import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

export const LoadingTitle = () => (
    <TableRow>
        <TableCell colSpan={2}>
            <Skeleton variant="text" sx={{ fontSize: '18px', mx: 'auto' }} width={150} />
        </TableCell>
    </TableRow>
);

const Loading = () => (
    <TableRow>
        <TableCell>
            <Skeleton variant="text" sx={{ fontSize: '18px' }} width={150} />
        </TableCell>
        <TableCell>
            <Skeleton variant="circular" height={33} width={33} />
        </TableCell>
    </TableRow>
);

export default Loading;