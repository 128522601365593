import React from 'react';
import { useTranslate, useRecordContext } from 'react-admin';
import { dateFormat } from './dateFormat';
import { stringConvertFromC, numConvertFromInternalToOralC, numConvertFromInternalToAxillaryC } from '../tempscale/tempConvert';
import { ReplacePatchList, PatchCompletedList } from "./List"
import "./styles/temperatureFormat.css"

const PatchStatus = ({ adjustForOral, tempFormat, statusText }) => {
  const translate = useTranslate();
  const record = useRecordContext();

  if (!record?.patchStatus?.statusCode) {
    return <span></span>
  }

  const statusCode = record.patchStatus.statusCode;
  const showLiveTemps = false;

  // List view components
  if (statusCode === 'ERR') {
    return <ReplacePatchList />
  } else if (statusCode === 'FIN') {
    return <PatchCompletedList endTime={record.patchStatus.endTime} />
  } else if (statusCode === 'NOS') {
    return <span id="status_NOS">{translate('resources.patch.unusedpatch')}</span>;
  } else if (statusCode === 'NPA') {
    return <span id="status_NPA">{translate('resources.patch.nopatchesadded')}</span>
  } else if (statusCode === 'NRD') {
    return <span id="status_NRD">{translate('resources.patch.norecentdata')}</span>;
  } else if (!showLiveTemps && ['TLO', 'THI', 'TOK'].includes(statusCode)) {
    return <span id="status_TLO_THI_TOK">{translate('resources.patch.patchactive')}</span>
  } else if (statusCode === 'TLO') {
    return <span id="status_TLO">{translate('resources.patch.TLO')}</span>;
  } else if (statusCode === 'THI') {
    return <span id="status_THI">{translate('resources.patch.THI')}</span>;
  } else if (statusCode === 'TOK') {
    const currentTemp = adjustForOral ? numConvertFromInternalToOralC(record.currentTemp) : numConvertFromInternalToAxillaryC(record.currentTemp);
    return <span id="status_TOK">{stringConvertFromC(currentTemp, tempFormat)} @ {dateFormat(record.patchStatus.lastUpdated)}</span>
  } else {
    return <span>{statusText}</span>;
  }
};

export default PatchStatus;