import englishMessages from 'ra-language-english';

export default {
    null: 'This item does not exist in translation {NULL}',
    simple: {
        action: {
            close: 'Close',
            resetViews: 'Reset views',
        },
        'create-post': 'New post',
    },
    ...englishMessages,
    misc: {
        temperatureoral: 'Oral Equivalent Temperature',
        temperatureaxillary: 'Axillary Temperature',
    },
    resources: {
        auth: {
            passwordreset: 'Password reset instructions have been sent to %{email}',
            emailrequired: 'Email Address is Required',
            invalid: 'Invalid username or password'
        },
        misc: {
            ascending: 'Ascending',
            date: 'Date',
            datetime: 'Date/Time',
            descending: 'Descending',
            detail: 'Detail',
            export: 'Export',
            id: 'ID',
            lastupdated: 'Last Updated',
            loadingdata: 'Loading Data...',
            notviewinginwebview: 'Not viewing in webview',
            optional: 'Optional',
            ok: 'OK',
            processingdata: 'Processing Data...',
            status: 'Status',
            sortby: 'Sort by',
            summary: 'Summary',
            default: 'Default',
            error: 'Error fetching data',
            nodata: 'No Data Available',
            allowed: 'Allowed characters: a-z A-Z 0-9 . -'
        },
        hospital: {
            highTempThreshold: 'High Temperature Threshold (°%{scale})' ,
            lowTempThreshold: 'Low Temperature Threshold (°%{scale})',
            adjustfororal: 'Adjust for Oral',
            hospitalalerttemphelper: 'Must be less than High Temperature Threshold',
            label: 'Unit',
            hospitalid: 'Unit ID',
            group: 'Group',
            parentLabel: 'Parent Unit ID',
            add: 'Add Unit',
            name: 'Unit Name',
            activepats: 'Active Patients',
            completepats: 'Completed Patients',
            unstartedpats: 'Unstarted Patients',
            unstarted: 'Unstarted',
            active: 'Active',
            activate: 'Activate',
            list: 'Units',
            create: 'Create Unit',
            show: {
                edit: 'Edit Unit',
                hierarchy: 'Unit Hierarchy',
                config: 'Configuration',
                integrations: 'Integrations',
                hospitalconfigure: 'Clinician Configurations',
                patientconfigure: 'Patient Configurations',
                summary: 'Summary',
                stats: 'Statistics',
            }
        },
        patient: {
            activehospitalonly: 'active unit only',
            activepatientsonly: 'Active Patients',
            addpatient: 'Add Patient',
            alerttemp: 'Alert Temperature',
            dailytmax: 'Daily TMax',
            duplicateId: 'Patient ID must be unique',
            editpatient: 'Edit Patient',
            exportcsv: 'Export All Patient Data (CSV)',
            kitid: 'Kit ID',
            kitpassword: 'Kit Password',
            kitpasswordrequired: 'Kit Password is Required',
            kitidrequired: 'Kit ID is Required',
            label: 'Patient',
            passwordhelp: 'Required for Outpatient Use',
            patientid2label: 'Patient ID 2',
            patientid2required: 'Patient ID 2 is required',
            patientid2helperrequired: '* Required',
            patientid2helperoptional: 'Optional',
            patientalerttemplabel: 'Alert Temperature (°%{scale})',
            patientalerttemphelper: 'Valid range is %{min} - %{max}',
            patientidlabel: 'Patient ID',
            patientidrequired: 'Patient ID is required',
            patientlist: 'Patients',
            patientpassword: 'Patient Password',
            showcredentials: 'Credentials',
            credentialstitle: 'Patient Credentials',
            showinactivepatients: 'show inactive patients',
            temperature: 'Temperature',
            transfer: 'Transfer',
            transferkit: 'Transfer Kit',
            transferpatient: 'Transfer patient kit to %{hospitalCode}',
            transfersubmit: 'SUBMIT',
            transfersourcehospital: 'Group ID',
            passwordincorrect: 'Password is Incorrect',
            started: 'Started'
        },
        patch: {
            addpatch: 'Add Patch',
            multiple: 'Add multiple patches',
            completed: 'Completed',
            current: 'Latest',
            deletepatch: 'Delete Patch?',
            high: 'High',
            label: 'Patch',
            labelplural: 'Patches',
            nopatchesadded: 'No Patches Added',
            norecentdata: 'No Recent Data',
            nodata: 'No Data',
            nodatafor: 'No Data for',
            patchactive: 'Patch Active',
            patchid: 'Patch ID',
            replacepatch: 'Replace Patch',
            scanpatch: 'Scan Patch',
            starttime: 'Start Time',
            timeremaining: 'Time Remaining',
            unusedpatch: 'Unused Patch',
            graph: 'Graph',
            summary: 'Summary',
            history: 'History',
            export: 'Export',
            exportcsv: 'Export Single Patch Data (CSV)',
            NPA: 'No patches assigned',
            NOS: 'Patch not started',
            THI: 'Hi',
            TLO: 'Lo',
            invalid: 'Invalid Patch ID',
            assigned: 'Patch is already assigned to a patient',
            scan: 'No scan data available for viewing',
            isrequired: 'Patch ID is Required',
            patchisadded: 'Patch has been added'
        },
        password: {
            containnumber: 'Password must contain a number',
            lowercaseletter: 'Password must contain at least 1 lowercase letter',
            uppercaseletter: 'Password must contain at least 1 uppercase letter',
            requiredlength: 'Password does not meet the required length of %{length} characters',
            specialcharacter: 'Password must contain at least 1 special character'
        },
    },
    user: {
        roles: {
            tenantadmin: 'Unit Administrator',
            clinician: 'Clinician',
            sysadmin: 'Global Administrator'
        },
        detail: 'User',
        create: {
            add: 'Add User',
            admin: 'Administrator for Unit',
            clinician: 'Clinician for Unit',
            fname: 'First Name',
            lname: 'Last Name',
            active: 'Active Status',
            email: 'Email',
            role: 'User must have at least one role'
        },
        list: {
            name: 'Name',
            role: 'Role',
            label: 'Users',
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            security: 'Security',
        },
        edit: {
            label: 'Edit User',
            title: 'User "%{title}"',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    login: {
        title: {
            cell360: 'Cell Therapy 360 Web Application',
            temptraq: 'TempTraq Clinician Web Application'
        },
        forgot: {
            link: 'Forgot Credentials',
            note: 'Note: Once you receive an email at the entered address, please follow the link in the email to reset your password.',
            submit: 'Submit',
            snack: 'TODO!!! Password reset instructions have been sent to'
        },
        client: 'Client',
        error: 'Invalid username or password',
        email: 'Email'
    },
    navigation: {
        analytics: 'Analytics',
        patient: 'Patients',
        gateway: 'Gateways',
        gatewayid: 'Gateway ID',
        hospital: 'Units',
        user: 'Users',
        config: 'Configurations',
        settings: 'Settings',
        network: 'Unable to Contact Server. Check Network Connectivity.'
    },
    settings: {
        server: 'Server',
        udi: 'UDI',
        label: 'Settings',
        current: 'Current User',
        change: 'Change Password',
        diagnostics: "Diagnostics",
        scale: 'Choose your temperature scale',
        ui: 'UI Options',
        alert: 'Show alert temperatures',
        timer: 'Show No Recent Data Timer',
        legal: 'Legal',
        privacy: 'Privacy Policy',
        terms: 'Terms of Use',
        example: 'Example',
        language: 'Language Options',
        enabled: 'Enabled'
    },
    earlyindicator:{
        title: 'Early Indicator'
    },
    alert: {
        soundon: 'Sound On',
        soundoff: 'Sound Off',
        notetitle: 'This site would like to play sound.',
        notedescr: 'User feedback is required to allow autoplay.',
        generate: 'Generate random notification',
        nonotification: 'No Notification',
        clearall: 'Clear All',
        clear: 'Clear',
        headerTitle: 'Alerts',
        title: {
            default: 'a',
            alert: 'Temperature Alert, %{patient}',
            err: 'Replace Patch, %{patient}',
            nrd: 'No Recent Data, %{patient}',
            afin: 'Patch Finishing, %{patient}',
            early: 'Early Indicator Alert, %{patient}'
        },
        description: {
            default: 'Notification received at %{time}. Temperature for patient %{code} and patch %{patch} is at the alert level',
            alert: 'Notification received at %{time}. Temperature for patient %{patient} and patch %{patch} is at the alert level.',
            err: 'TempTraq Patch Alert for patch %{patch}. Replace patch for patient %{patient}.',
            nrd: 'No temperature data received since %{time} for patient %{patient} using patch %{patch}. Patient may be too far from the receiving device, or there may be network connectivity issues.',
            afin: 'Patch %{patch} is nearly finished. Apply a new patch to continue monitoring.',
            early: 'Based on temperature trends, TempTraq has detected an increased risk of the patient\'s temperature reaching %{alertTemp} within the next hour.  You may want to monitor the patient\'s temperature more closely and consider appropriate preparations for an actual temperature alert.'
        }
    },
    acknowledge: {
        title: 'Acknowledgement',
        terms: 'You must accept the terms to use this tool',
        accept: 'I Accept',
        acknowledge: 'Acknowledge'
    },
    changepass: {
        currentpass: 'Current Password',
        newrequired: 'New Password is Required',
        currentrequired: 'Current Password is Required',
        currentincorrect: 'Current Password is Incorrect',
        meetlength: 'Password does not meet the required length of %{length} characters',
        uppercase: 'Password must contain at least 1 uppercase letter',
        lowercase: 'Password must contain at least 1 lowercase letter',
        number: 'Password must contain a number',
        special: 'Password must contain at least 1 special letter',
        retype: 'Please Retype New Password',
        mustmatch: 'Passwords must match!',
        change: 'Change Password',
        changerequired: 'Change Password Required',
        pass: 'New Password',
        retypepass: 'Retype New Password',
        enter: 'Enter new password',
        note1: 'Note: you will need to log in',
        note2: 'again with your new password'
    },
    dashboard: {
        active: 'Active',
        total: 'Total',
        hospitals: 'Units',
        patients: 'Patients',
        patches: 'Patches',
        server: 'Server Status',
        run: 'Running',
        pause: 'Paused',
        seems: 'Seems Down',
        down: 'Down',
        not: 'Not Checked Yet',
        go: 'UptimeRobot',
        tooltip: 'Auto refresh: %{refresh} minutes',
        refresh: 'Auto Refresh Interval',
        min: 'min',
        other: 'Fewer than %{num}',
        group: 'Group units with fewer than'
    },
    gateways: {
        label: 'Gateway',
        delete: 'Delete Gateway?',
        edit: 'Edit Gateway',
        name: 'Gateway Name',
        location: 'Gateway Location',
        id: 'Gateway ID',
        active: 'Active Units Only',
        add: 'Add Gateway',
        list: 'Gateways',
        inactive: 'Inactive',
        emptytitle: 'No Gateway yet.',
        emptydesc: 'The currently active unit does not have a gateway assigned.'
    },
    integration: {
        endpoint: 'Endpoint',
        pipeline: 'Pipeline',
        name: 'Name',
        edit: 'Edit Integration',
        add: 'Add Integration',
        list: 'Integration List',
        delete: 'Delete Integration?',
        detail: 'Integration Detail'
    },
    diagnostic: {
        title: 'Patch Performance',
        voltb: 'Volt Battery',
        volts: 'Volt Sensor',
        error: 'Errors',
    },
    config: {
        type: 'Type',
        inherit: 'Inherited Configuration',
        value: 'Value'
    }
};
