import { UserList } from './usersList';
import UserEdit from './usersEdit';
import UserCreate from './usersCreate';
import UserShow from './usersShow';

export default {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow
};