import React from 'react';
import { Link } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from "@mui/icons-material/Add";
import { Button } from 'react-admin';
import { Fab } from '@mui/material';
import Responsive from '../ui/Responsive';

const AddPatchButton = ({ record }) => {
  return (
    record ?
      <Responsive
        xsmall={
          <Fab
            id="addPatch"
            component={Link}
            to={`/Patchsummary/create?patient_id=${record.id}&patientCode=${record.patientCode}&patientName=${record.patientName || ''}`}
            color="primary"
            style={{
              right: '20px',
              bottom: '60px',
              left: 'auto',
              position: 'fixed',
              zIndex: 1000
            }}
          >
            <AddIcon />
          </Fab>
        }
        medium={
          <Button
            id="addPatch"
            style={{ fontSize: '.8125rem' }}
            variant="contained"
            component={Link}
            to={`/Patchsummary/create?patient_id=${record.id}&patientCode=${record.patientCode}&patientName=${record.patientName || ''}`}
            label="resources.patch.addpatch"
          >
            <AddBoxIcon style={{ height: '20px', width: '20px' }} />
          </Button>
        }
      />
      : <span />
  );
}

export default AddPatchButton;
