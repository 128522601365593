import { BooleanInput, NumberInput } from "react-admin";
import { curTempSetting } from "../custom/settings/displayOptions";
import { numConvertFromC, numConvertFromI, numConvertToC } from "../tempscale/tempConvert";
import React from "react";

const validateThresholds = (values) => {
    const errors = {};

    if (values.highTempThreshold <= values.lowTempThreshold) {
        errors.lowTempThreshold = 'resources.hospital.hospitalalerttemphelper';
    }

    if (!values.hospitalName || values.hospitalName === null) {
        errors.hospitalName = 'ra.validation.required'
    }

    if (values.tenantcode && !values.tenantcode.match(/^([a-z]|\d|-|\.)+$/i)) {
        errors.tenantcode = 'resources.misc.allowed'
    }

    if (!values.tenantcode || values.tenantcode === null) {
        errors.tenantcode = 'ra.validation.required'
    }

    return errors;
}

const adjustForOral = () => {
    return (
        <BooleanInput id="oral" defaultValue={true} label="resources.hospital.adjustfororal" source="adjustForOral" name="adjustForOral" />
    );
}

const highTempThreshold = (translate) => {

    return (
        <NumberInput
            id="highTempThreshold"
            options={{ maximumFractionDigits: 1 }}
            label={translate('resources.hospital.highTempThreshold', { scale: curTempSetting() })}
            defaultValue={3800}
            source="highTempThreshold"
            format={v => isNaN(v) ? v : numConvertFromC(numConvertFromI(v), curTempSetting()).toString()}
            parse={v => isNaN(v) ? v : numConvertToC(v, curTempSetting()) * 100}
            onChange={validateThresholds}
            name="highTempThreshold"
        />
    );
}

const lowTempThreshold = (translate) => {

    return (
        <NumberInput
            id="lowTempThreshold"
            options={{ maximumFractionDigits: 1 }}
            label={translate('resources.hospital.lowTempThreshold', {scale: curTempSetting()})}
            defaultValue={3500}
            source={'lowTempThreshold'}
            format={v => isNaN(v) ? v : numConvertFromC(numConvertFromI(v), curTempSetting()).toString()}
            parse={v => isNaN(v) ? v : numConvertToC(v, curTempSetting()) * 100}
            onChange={validateThresholds}
            helperText={translate('resources.hospital.hospitalalerttemphelper')}
            name="lowTempThreshold"
        />
    );
}

export { lowTempThreshold, highTempThreshold, adjustForOral, validateThresholds };