import * as React from 'react';
import PropTypes from 'prop-types';

export const Context = React.createContext({ context: {}, setContext: () => { } });

function ContextProvider({ children }) {
  const [context, setContext] = React.useState({ lastUpdated: null });
  const [resize, setResize] = React.useState(false);

  const value = React.useMemo(
    () => ({
      context, setContext,
      resize, setResize
    }),
    [context, resize],
  );
  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ContextProvider;