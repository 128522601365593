export const downloadFile = async(filename, data) => {
  if(window.NativeInterface) {
    window.NativeInterface.saveFile(filename, data);
  } else {
    const blob = new Blob([data],{type:'application/csv'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}