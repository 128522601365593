import React from "react";
import {
	Button,
	Datagrid,
	Filter,
	FunctionField,
	List,
	Pagination,
	SimpleList,
	TextField,
	TextInput,
	TopToolbar,
	useTranslate
} from 'react-admin';
import { Box, Fab, Typography } from "@mui/material";
import { Link } from 'react-router-dom';
import ComputerIcon from '@mui/icons-material/Computer';
import AddIcon from '@mui/icons-material/Add';
import Responsive from "../ui/Responsive";
import { dateFormat } from "../patches/dateFormat";

const KeywordFilter = (props) => {
	return (
		<Filter style={{ marginBottom: "15px" }}  {...props}>
			<TextInput resettable label="ra.action.search" source="q" alwaysOn />
		</Filter>
	);
};

const CustomPagination = (props) => <Pagination id="paginate" rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const Empty = () => {
	const translate = useTranslate();

	return (
		<Box textAlign="center" width="100%" m={1}>
			<ComputerIcon id="emptyIcon" sx={{ width: '216px', height: '216px', opacity: 0.5 }} />
			<Typography variant="h4" paragraph sx={{ opacity: 0.5 }}>
				{translate('gateways.emptytitle')}
			</Typography>
			<Typography variant="body1" sx={{ opacity: 0.5, mb: 4 }}>
				{translate('gateways.emptydesc')}
			</Typography>
			{localStorage.getItem('isSysAdmin') === 'true' &&
				<Button id="addGatewayButton" component={Link} to="/gateways/create" variant="contained" label="gateways.add">
					<AddIcon style={{ height: '20px', width: '20px' }} />
				</Button>
			}
		</Box>
	)
};


const EntityListActions = () => (
	<Responsive
		xsmall={
			<>
				{localStorage.getItem('isSysAdmin') === 'true' &&
					<Fab
						component={Link}
						to="/gateways/create"
						color="primary"
						style={{
							right: '20px',
							bottom: '60px',
							left: 'auto',
							position: 'fixed',
							zIndex: 1000
						}}
					>
						<AddIcon />
					</Fab>
				}
			</>
		}
		medium={
			<TopToolbar>
				{localStorage.getItem('isSysAdmin') === 'true' &&
					<Button id="addGatewayButton" style={{ marginTop: '0px', marginLeft: '10px' }} component={Link} to="/gateways/create" variant="contained" label="gateways.add">
						<AddIcon style={{ height: '20px', width: '20px' }} />
					</Button>
				}
			</TopToolbar>
		}
	/>
);

export const GatewayList = (props) => (
	<List {...props}
		style={{ paddingLeft: '10px', paddingRight: '10px', minHeight: '0px', borderRadius: '5px', backgroundColor: '#FFFFFF' }}
		pagination={<CustomPagination />} perPage={25}
		bulkActionButtons={false}
		sort={{ field: "name", order: "ASC" }}
		filters={<KeywordFilter />}
		actions={<EntityListActions />}
		empty={<Empty />}
	>
		<Responsive
			xsmall={
				<SimpleList
					linkType="show"
					primaryText={record => record?.name}
					secondaryText={record => record?.gatewaycode}
					tertiaryText={record => record?.tenantcode}
				/>
			}
			medium={
				<Datagrid bulkActionButtons={false} rowClick='show' {...props} >
					<TextField id="code" source="gatewaycode" label="gateways.id" />
					<TextField id="name" source="name" label="gateways.name" />
					<FunctionField
						id="lastUpdated"
						render={record => dateFormat(record.lastupdated)}
						label="resources.misc.lastupdated"

					/>
					<TextField id="location" source="location" label="gateways.location" />
				</Datagrid>
			}
		/>
	</List>
);