import React from 'react';
import { Link } from 'react-router-dom';
import { Button, useTranslate, useRecordContext } from 'react-admin';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const ScanPatchButton = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    const handleClick = ({ hospitalCode, patientCode }) => {
        if (window.NativeInterface) {
            window.NativeInterface.showPatchScanner(hospitalCode, patientCode);
        } else {
            alert(translate('resources.misc.notviewinginwebview'));
        }
    };

    return (
        window.NativeInterface && record ?
            <Button
                id="scanPatch"
                style={{ fontSize: '.8125rem' }}
                variant="contained"
                component={Link}
                onClick={() => handleClick(record)}
                to={`/PatientSummary/${record.id}/show/1`}
                label="resources.patch.scanpatch"
            >
                <AddAPhotoIcon style={{ height: '20px', width: '20px' }} />
            </Button>
            : <span />
    );
}

export default ScanPatchButton;