import React from 'react';
import {
  ReplacePatchGrid,
  TemperatureGrid,
  PatchCompletedGrid,
  TempOutOfRangeGrid,
  PatchNotStarteGrid,
  NoRecentDataGrid
} from './Grid'
import "./styles/temperatureFormat.css"

export const temperatureFormat = (result, lowTemp, highTemp, alertTemp, tempFormat, showNRDTimer, isGrid, adjustForOral) => {

  if (!result || !result.statusCode) {
    return <span></span>
  }

  const { statusCode } = result;

  // Grid (monitor) view components
  if (statusCode === 'NPA') {
    return <PatchNotStarteGrid statusCode={statusCode} />
  } else if (statusCode === 'ERR') {
    if (isGrid && result.endTime < new Date().getTime()) {
      return <PatchCompletedGrid endTime={result.endTime} />
    } else {
      return <ReplacePatchGrid />
    }
  } else if (statusCode === 'TOK') {
    return <TemperatureGrid
      result={result}
      lowTemp={lowTemp}
      highTemp={highTemp}
      alertTemp={alertTemp}
      tempFormat={tempFormat}
      adjustForOral={adjustForOral}
    />
  } else if (statusCode === 'FIN') {
    return <PatchCompletedGrid endTime={result.endTime} />
  } else if (statusCode === 'THI') {
    return <TempOutOfRangeGrid statusCode={statusCode} />
  } else if (statusCode === 'TLO') {
    return <TempOutOfRangeGrid statusCode={statusCode} />
  } else if (statusCode === 'NRD') {
    return <NoRecentDataGrid showNRDTimer={showNRDTimer} lastUpdated={result.lastUpdated} />
  } else {
    return <PatchNotStarteGrid statusCode={statusCode} />
  }
}