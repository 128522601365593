export const getActiveHospitalId = () => {
    let hospitalId = sessionStorage.getItem('hospitalId');
    if(!hospitalId) {
        hospitalId = localStorage.getItem('hospitalId');
    }
    if(hospitalId === "undefined") {
        hospitalId = undefined;
    }
    return hospitalId;
}

export const setActiveHospitalId = (val) => {
    if(val === undefined || val === "undefined") {
        localStorage.removeItem('hospitalId');
    } else {
        localStorage.setItem('hospitalId', val);
    }
}

export const getActiveHospitalShortName = () => {
    let hospitalShortName = sessionStorage.getItem('hospitalShortName');
    if(!hospitalShortName) {
        hospitalShortName = localStorage.getItem('hospitalShortName');
    }
    if(hospitalShortName === "undefined") {
        hospitalShortName = undefined;
    }
    return hospitalShortName;
}

export const setActiveHospitalShortName = (val) => {
    if(val === undefined || val === "undefined") {
        localStorage.removeItem('hospitalShortName');
    } else {
        localStorage.setItem('hospitalShortName', val);
    }
}

export const getActiveHospitalHierarchy = () => {
    var hospitalHierarchy = sessionStorage.getItem('hospitalHierarchy');
    if(!hospitalHierarchy) {
        hospitalHierarchy = localStorage.getItem('hospitalHierarchy');
    }
    return hospitalHierarchy;
}

export const setActiveGroupCode = (val) => {
    if(val === undefined || val === "undefined") {
        localStorage.removeItem('hospitalGroupCode');
    } else {
        localStorage.setItem('hospitalGroupCode', val);
    }
}

export const getActiveGroupCode = () => {
    var groupCode = sessionStorage.getItem('hospitalGroupCode');
    if(!groupCode) {
        groupCode = localStorage.getItem('hospitalGroupCode');
    }
    return groupCode;
}


export const setActiveHospitalHierarchy = (val) => localStorage.setItem('hospitalHierarchy', val);
