import React from 'react';
import { useTranslate } from 'react-admin';
import { stringConvertFromC, numConvertFromI, numConvertFromC } from '../tempscale/tempConvert';
import { dateFormat } from './dateFormat';
import { msToHS } from '../util/timeFunctions';

export const ReplacePatchGrid = () => {
    const translate = useTranslate();
    return (
        <div className="tempContainer err">
            <span className={`statusWithBackground grid`} id="replace">
                {translate('resources.patch.replacepatch')}
            </span>
        </div>
    )
}

export const PatchCompletedGrid = ({ endTime }) => {
    const translate = useTranslate();
    const [date, time, amPM] = dateFormat(endTime).split(/\s/)

    return (
        <div className="tempContainer patchCompleted">
            <span className="statusWithBackground grid">
                <div style={{ flex: '0 0 12px' }}>{translate('resources.patch.completed')}</div>
                <div style={{ flex: '0 0 12px' }}>{date}</div>
                <div style={{ flex: '0 0 12px' }}>{`${time} ${amPM}`}</div>
            </span>
        </div>
    )
}

export const PatchNotStarteGrid = ({ statusCode }) => {
    const translate = useTranslate();
    return <div className="tempContainer nos">
        <span className="statusWithBackground grid">
            {translate(`resources.patch.${statusCode}`)}
        </span>
    </div>
}
// Not being used?

// export const HiGrid = ({ statusCode }) => {
//     const translate = useTranslate();
//     return <div className="tempContainer temphi">
//         <span className="statusWithBackground temperature grid" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
//             {translate(`resources.patch.${statusCode}`)}
//         </span>
//     </div>
// }

// export const LoGrid = ({ statusCode }) => {
//     const translate = useTranslate();
//     return <div className="tempContainer templo">
//         <span className="statusWithBackground temperature grid" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
//             {translate(`resources.patch.${statusCode}`)}
//         </span>
//     </div>
// }

export const TempOutOfRangeGrid = ({ statusCode }) => {
    const translate = useTranslate();
    return <div className="tempContainer tempOutOfRange">
        <span className="statusWithBackground temperature grid">
            {translate(`resources.patch.${statusCode}`)}
        </span>
    </div>
}

export const TemperatureGrid = ({ result, lowTemp, highTemp, alertTemp, tempFormat, adjustForOral }) => {
    const alertTempConverted = numConvertFromC(numConvertFromI(alertTemp), tempFormat);
    const lowTempConverted = numConvertFromC(numConvertFromI(lowTemp), tempFormat);
    const highTempConverted = numConvertFromC(numConvertFromI(highTemp), tempFormat);
    const currentTempC = numConvertFromI(adjustForOral ? result.temperatureOral : result.temperatureActual);
    const currentTempConverted = numConvertFromC(numConvertFromI(adjustForOral ? result.temperatureOral : result.temperatureActual), tempFormat);

    const colorPicker = () => {
        if (currentTempConverted <= lowTempConverted) return '#4BC4E3'; // low
        if (currentTempConverted > lowTempConverted && currentTempConverted < highTempConverted) return '#71C6A8'; // normal
        if (currentTempConverted >= highTempConverted && currentTempConverted < alertTempConverted) return '#F49C66'; // fever
        if (currentTempConverted >= alertTempConverted) return '#F26666';  // alert
        return '#FFFFFF';
    };

    return <div className="tempContainer tok" style={{ backgroundColor: colorPicker() }}>
        <span className={`temperature grid`}>
            {stringConvertFromC(currentTempC, tempFormat)}
            </span>
    </div>
}

export const NoRecentDataGrid = ({ lastUpdated, showNRDTimer }) => {
    const translate = useTranslate();
    const unicodeNBSP = '\u00a0';
    if (showNRDTimer) {
        const diff = msToHS(new Date().getTime() - lastUpdated);
        return <div className="nrd tempContainer">
            <span className="nrd statusWithBackground grid">
                {translate('resources.patch.nodatafor')}<br />{`${diff.h}h${unicodeNBSP + diff.m}m`}
            </span>
        </div>
    } else {
        return <div className="nrd tempContainer">
            <span className="nrd statusWithBackground grid">
                {translate('resources.patch.norecentdata')}
            </span>
        </div>
    }
}